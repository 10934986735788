import { useUserStore } from "@/store/userStore";
import { UserType } from "~/enums";

export default defineNuxtRouteMiddleware((to) => {
    const userStore = useUserStore();

    if(userStore.getUserType === UserType.Manager || userStore.getUserType === UserType.Admin) {
        setPageLayout("mobile");
    } else {
        setPageLayout("non-header-footer");
    }

});
