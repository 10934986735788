import { default as errorF6oBbZ0L6aMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/error.vue?macro=true";
import { default as indexNYDAqpajiDMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/index.vue?macro=true";
import { default as error_45pageUjUk3Hwbh7Meta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/error-page.vue?macro=true";
import { default as none_45contents_45pageOQPV9MsGFSMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/none-contents-page.vue?macro=true";
import { default as privacy_45policyhpC4fVQzNkMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/privacy-policy.vue?macro=true";
import { default as usage_45policyx8RORsjM7RMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/usage-policy.vue?macro=true";
import { default as changePassDf6wJhK32GMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/changePass.vue?macro=true";
import { default as _10_questionH9xtUGyj4uMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/company/10_question.vue?macro=true";
import { default as _1_serviceOverviewZJP0yjEK7PMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/company/1_serviceOverview.vue?macro=true";
import { default as _2_statusVskrga9SdFMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/company/2_status.vue?macro=true";
import { default as _3_equipSelectTE20q7pdnNMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/company/3_equipSelect.vue?macro=true";
import { default as _4_instru4OUGfc0pqOMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/company/4_instru.vue?macro=true";
import { default as _5_equipInfoT9TtlOBa3AMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/company/5_equipInfo.vue?macro=true";
import { default as _6_eventijv9b8gIrUMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/company/6_event.vue?macro=true";
import { default as _7_powerGenerationH5SRWXj7WuMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/company/7_powerGeneration.vue?macro=true";
import { default as _8_installInfouSRjOgB6PjMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/company/8_installInfo.vue?macro=true";
import { default as _9_ownerInfo7LdHAkAc26Meta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/company/9_ownerInfo.vue?macro=true";
import { default as indexzE7YNoI6HkMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/index.vue?macro=true";
import { default as _1_serviceOverviewoZNxvtYbPBMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/owner/1_serviceOverview.vue?macro=true";
import { default as _2_statustvinzM1leCMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/owner/2_status.vue?macro=true";
import { default as _3_monitoringnyooX8Om3XMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/owner/3_monitoring.vue?macro=true";
import { default as _4_equipInfopryehk123zMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/owner/4_equipInfo.vue?macro=true";
import { default as _5_powerGenerationlcvlJ8MquFMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/owner/5_powerGeneration.vue?macro=true";
import { default as _6_reportI60fgmCCxMMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/owner/6_report.vue?macro=true";
import { default as _7_eventaubNDUOb2KMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/owner/7_event.vue?macro=true";
import { default as _8_ownerInfoT8wBpf9pD2Meta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/owner/8_ownerInfo.vue?macro=true";
import { default as _9_question80BO2QZSATMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/owner/9_question.vue?macro=true";
import { default as main9DN86f9igeMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/main.vue?macro=true";
import { default as openSourceGWvHOCPLSGMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/openSource.vue?macro=true";
import { default as personTermsPLYNnsoVwgMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/personTerms.vue?macro=true";
import { default as question1vPH7fziWfMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/question.vue?macro=true";
import { default as useTermsbxYd5iOmUCMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/useTerms.vue?macro=true";
import { default as cse5z2Bsurr9Meta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs.vue?macro=true";
import { default as equipListRupnPr9MUJMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/equipList.vue?macro=true";
import { default as equipMapeXAwZXNEDdMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/equipMap.vue?macro=true";
import { default as alarmETCtEzL0aRfJrMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/etc/alarm/alarmETC.vue?macro=true";
import { default as alarmOBS5iUAJZlivgMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/etc/alarm/alarmOBS.vue?macro=true";
import { default as alarmRTUKw4L5YxAaRMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/etc/alarm/alarmRTU.vue?macro=true";
import { default as allAc6aP2VsB0Meta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/etc/alarm/all.vue?macro=true";
import { default as alarmJ7PYCuZfMeMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/etc/alarm.vue?macro=true";
import { default as _91noticeid_93FnP3D9uKp2Meta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/etc/notice/[noticeid].vue?macro=true";
import { default as indextzW66rky5bMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/etc/notice/index.vue?macro=true";
import { default as noticesCWvnZKrFXMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/etc/notice.vue?macro=true";
import { default as etcEJJomv7I6uMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/etc.vue?macro=true";
import { default as indexRoOQzcqgKOMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/index.vue?macro=true";
import { default as contact_45changeppbADGLuQYMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/info/contact-change.vue?macro=true";
import { default as mainnxv0SENwl3Meta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/info/main.vue?macro=true";
import { default as pw_45changevC1fQhtpt9Meta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/info/pw-change.vue?macro=true";
import { default as user_45delete42gAtRrJumMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/info/user-delete.vue?macro=true";
import { default as infotgepm374WeMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/info.vue?macro=true";
import { default as settingk3rT8Drn0iMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/setting.vue?macro=true";
import { default as siteListpbiFgJxcg9Meta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/siteList.vue?macro=true";
import { default as FailoverEditaJqB634ilaMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/FailoverEdit.vue?macro=true";
import { default as index3QJVLjdRiSMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/ess/InstallInfo/index.vue?macro=true";
import { default as InstallInfontUZnNkTOHMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/ess/InstallInfo.vue?macro=true";
import { default as chartbrmRTm4dBJMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/ess/InstruInfo/chart.vue?macro=true";
import { default as nowp2MIFC61MuMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/ess/InstruInfo/now.vue?macro=true";
import { default as InstruInfo50nVkwalyuMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/ess/InstruInfo.vue?macro=true";
import { default as index2JqFZg06U5Meta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/ess/index.vue?macro=true";
import { default as essQWHayBK3MTMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/ess.vue?macro=true";
import { default as indexpKjAQXnCPxMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/fuelcell/InstallInfo/index.vue?macro=true";
import { default as InstallInfoVQyRY0T96dMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/fuelcell/InstallInfo.vue?macro=true";
import { default as chartgW86VjSYzkMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/fuelcell/InstruInfo/chart.vue?macro=true";
import { default as nowlj02KGNvm7Meta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/fuelcell/InstruInfo/now.vue?macro=true";
import { default as InstruInfoClcYRAHiEfMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/fuelcell/InstruInfo.vue?macro=true";
import { default as indexulPS1auxSNMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/fuelcell/index.vue?macro=true";
import { default as fuelcellx8bR0XYcrWMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/fuelcell.vue?macro=true";
import { default as installnZccD5xpNiMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/geo/InstallInfo/install.vue?macro=true";
import { default as managerlbklbkL7vvMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/geo/InstallInfo/manager.vue?macro=true";
import { default as InstallInfo0sIhYgMkT3Meta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/geo/InstallInfo.vue?macro=true";
import { default as chart2GWeLYWArwMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/geo/InstruInfo/chart.vue?macro=true";
import { default as now2l80GYafIMMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/geo/InstruInfo/now.vue?macro=true";
import { default as InstruInfoqfTRUEHpvWMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/geo/InstruInfo.vue?macro=true";
import { default as rtuIjtpbBaQvpMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/geo/SpecInfo/rtu.vue?macro=true";
import { default as specFjsKIvqBBjMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/geo/SpecInfo/spec.vue?macro=true";
import { default as SpecInfoJiLp0eM2JDMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/geo/SpecInfo.vue?macro=true";
import { default as geo0xQq7CjsLaMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/geo.vue?macro=true";
import { default as installZKM8jK2tmJMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/pv/InstallInfo/install.vue?macro=true";
import { default as managerlzEnxTJdqIMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/pv/InstallInfo/manager.vue?macro=true";
import { default as InstallInfo42ifbpfhFhMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/pv/InstallInfo.vue?macro=true";
import { default as chartswhDqEUsoTMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/pv/InstruInfo/chart.vue?macro=true";
import { default as nowdynzmltYElMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/pv/InstruInfo/now.vue?macro=true";
import { default as InstruInfo5NwaJPxOzCMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/pv/InstruInfo.vue?macro=true";
import { default as rtuG7DfEU2jpuMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/pv/SpecInfo/rtu.vue?macro=true";
import { default as specTgmDe9lvnAMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/pv/SpecInfo/spec.vue?macro=true";
import { default as SpecInfoSM1qTJwziCMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/pv/SpecInfo.vue?macro=true";
import { default as pvqDKLTBEfFPMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/pv.vue?macro=true";
import { default as installrogZbCDwsfMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/solarheat/InstallInfo/install.vue?macro=true";
import { default as managerpyuWEmswtLMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/solarheat/InstallInfo/manager.vue?macro=true";
import { default as InstallInfoVoN9gKkqkqMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/solarheat/InstallInfo.vue?macro=true";
import { default as chartAUz20IfNROMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/solarheat/InstruInfo/chart.vue?macro=true";
import { default as nowOoXWdBAd7uMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/solarheat/InstruInfo/now.vue?macro=true";
import { default as InstruInfobJ0e6HWwyqMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/solarheat/InstruInfo.vue?macro=true";
import { default as rtugzc66NVf4VMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/solarheat/SpecInfo/rtu.vue?macro=true";
import { default as specOt0Id4oJGGMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/solarheat/SpecInfo/spec.vue?macro=true";
import { default as SpecInfoAa3kYpRI2tMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/solarheat/SpecInfo.vue?macro=true";
import { default as solarheat9ZC2Pjja3fMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/solarheat.vue?macro=true";
import { default as indexcIkcWgxhdaMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/wf/InstallInfo/index.vue?macro=true";
import { default as InstallInfoTup2RAyUzZMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/wf/InstallInfo.vue?macro=true";
import { default as chartDsrEvcmo0CMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/wf/InstruInfo/chart.vue?macro=true";
import { default as nowkLqMaw3rrBMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/wf/InstruInfo/now.vue?macro=true";
import { default as InstruInfoECHuJBSufIMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/wf/InstruInfo.vue?macro=true";
import { default as indexaBHivRNNSTMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/wf/index.vue?macro=true";
import { default as wf6kmGMGnh7bMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/wf.vue?macro=true";
import { default as indexvrn6b2JyJ9Meta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/ess/InstallInfo/index.vue?macro=true";
import { default as InstallInfoF12lNaQ79JMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/ess/InstallInfo.vue?macro=true";
import { default as chartSirgPEr2OaMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/ess/InstruInfo/chart.vue?macro=true";
import { default as nowZ3QGMUNXugMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/ess/InstruInfo/now.vue?macro=true";
import { default as InstruInfoZ4ktRYnEFlMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/ess/InstruInfo.vue?macro=true";
import { default as indexaflpIikXICMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/ess/index.vue?macro=true";
import { default as essMUkT8UcbgMMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/ess.vue?macro=true";
import { default as indexNfMzLryHftMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/fuelcell/InstallInfo/index.vue?macro=true";
import { default as InstallInfo9Zz5Hg3p28Meta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/fuelcell/InstallInfo.vue?macro=true";
import { default as chartb7B0L59thrMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/fuelcell/InstruInfo/chart.vue?macro=true";
import { default as nown4raUzlZRhMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/fuelcell/InstruInfo/now.vue?macro=true";
import { default as InstruInfo3IkpJrL79hMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/fuelcell/InstruInfo.vue?macro=true";
import { default as indexRNXYD6muYuMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/fuelcell/index.vue?macro=true";
import { default as fuelcell2mGFLqpKeMMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/fuelcell.vue?macro=true";
import { default as install1u7PnJQnFDMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/geo/InstallInfo/install.vue?macro=true";
import { default as manager4GeiguZlZsMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/geo/InstallInfo/manager.vue?macro=true";
import { default as InstallInfoQQq9rqLFPjMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/geo/InstallInfo.vue?macro=true";
import { default as chartpClEYdicHEMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/geo/InstruInfo/chart.vue?macro=true";
import { default as nowQUMev6Oj93Meta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/geo/InstruInfo/now.vue?macro=true";
import { default as InstruInfoynS5fcVoCnMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/geo/InstruInfo.vue?macro=true";
import { default as index1u2k6NqXpIMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/geo/SpecInfo/index.vue?macro=true";
import { default as rtuBkB18acIEqMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/geo/SpecInfo/rtu.vue?macro=true";
import { default as specaW7rxTO8vhMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/geo/SpecInfo/spec.vue?macro=true";
import { default as geoACVLKdl9j8Meta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/geo.vue?macro=true";
import { default as installXAsmaSH0m5Meta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/pv/InstallInfo/install.vue?macro=true";
import { default as manager29pwgYDiRcMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/pv/InstallInfo/manager.vue?macro=true";
import { default as InstallInfoO3E87qNrxsMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/pv/InstallInfo.vue?macro=true";
import { default as chartqn8N4nQAHAMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/pv/InstruInfo/chart.vue?macro=true";
import { default as now8rYEKgt9KvMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/pv/InstruInfo/now.vue?macro=true";
import { default as InstruInfo2HGUOsJKBoMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/pv/InstruInfo.vue?macro=true";
import { default as indexjWiIRMCSiEMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/pv/SpecInfo/index.vue?macro=true";
import { default as rtuXt4TQxsKd8Meta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/pv/SpecInfo/rtu.vue?macro=true";
import { default as specatMk57ehHCMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/pv/SpecInfo/spec.vue?macro=true";
import { default as pvXq8cF0IdzXMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/pv.vue?macro=true";
import { default as installGDGaXhjxKlMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/solarheat/InstallInfo/install.vue?macro=true";
import { default as managerK95WQd1JzEMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/solarheat/InstallInfo/manager.vue?macro=true";
import { default as InstallInfoAJqUWVuZjrMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/solarheat/InstallInfo.vue?macro=true";
import { default as chartVHxh0kHC9TMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/solarheat/InstruInfo/chart.vue?macro=true";
import { default as indexVaNSDjdpkyMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/solarheat/InstruInfo/index.vue?macro=true";
import { default as now7zZaxTVzK0Meta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/solarheat/InstruInfo/now.vue?macro=true";
import { default as InstruInfoS8Lpr4IaxzMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/solarheat/InstruInfo.vue?macro=true";
import { default as indexfhhBWKoKWtMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/solarheat/SpecInfo/index.vue?macro=true";
import { default as rtuQ9ctz6P1nCMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/solarheat/SpecInfo/rtu.vue?macro=true";
import { default as specJPatVHHEAEMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/solarheat/SpecInfo/spec.vue?macro=true";
import { default as solarheatsvc5xawMYeMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/solarheat.vue?macro=true";
import { default as indexfqE5OcGNJpMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/wf/InstallInfo/index.vue?macro=true";
import { default as InstallInfoLlRnvqh3m4Meta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/wf/InstallInfo.vue?macro=true";
import { default as chartC1ko7vz2DAMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/wf/InstruInfo/chart.vue?macro=true";
import { default as nowWfqNyBAxSzMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/wf/InstruInfo/now.vue?macro=true";
import { default as InstruInfos2PWv3AVIfMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/wf/InstruInfo.vue?macro=true";
import { default as indexSf8IbHBRkrMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/wf/index.vue?macro=true";
import { default as wftUfD7kTlkTMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/wf.vue?macro=true";
import { default as equipInfop6unFGIDNNMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo.vue?macro=true";
import { default as ALLu7dO1uM5v5Meta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/event/ALL.vue?macro=true";
import { default as FailovermqO2SRw4dyMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/event/Failover.vue?macro=true";
import { default as FailureVzEaMndvqNMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/event/Failure.vue?macro=true";
import { default as allqeXI7UQ8ORMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/event/all.vue?macro=true";
import { default as eventHistoryxsRxLQJqz1Meta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/event/eventHistory.vue?macro=true";
import { default as rtuEventuXS5bYUlGHMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/event/rtuEvent.vue?macro=true";
import { default as eventZYjB9y1OEmMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/event.vue?macro=true";
import { default as ess0K6WGsTYKZMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/monitoring/ess.vue?macro=true";
import { default as fuelcelloOHDWlYY6gMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/monitoring/fuelcell.vue?macro=true";
import { default as geoejr1bKYQH7Meta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/monitoring/geo.vue?macro=true";
import { default as pvTzCnAIS4I0Meta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/monitoring/pv.vue?macro=true";
import { default as solarheatXrW8ptSfE4Meta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/monitoring/solarheat.vue?macro=true";
import { default as wfdkk38g8aSjMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/monitoring/wf.vue?macro=true";
import { default as monitoringfJCRblZggHMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/monitoring.vue?macro=true";
import { default as monitoring_testoGA2T3GNvYMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/monitoring_test.vue?macro=true";
import { default as InstallInfo5xG53toQbSMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/more/InstallInfo.vue?macro=true";
import { default as contractsvXwHDmgr4wMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/more/contracts.vue?macro=true";
import { default as geo2nSiiTUXbtMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/more/indicators/geo.vue?macro=true";
import { default as pvQT23VTmyMEMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/more/indicators/pv.vue?macro=true";
import { default as solarheatAX58rgC79DMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/more/indicators/solarheat.vue?macro=true";
import { default as indicatorsSPVo85TqfzMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/more/indicators.vue?macro=true";
import { default as menumtR0gclQYSMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/more/menu.vue?macro=true";
import { default as _91noticeid_93neDtEf4A78Meta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/more/menuToNotice/[noticeid].vue?macro=true";
import { default as ownerInfoyFG3X710fPMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/more/ownerInfo.vue?macro=true";
import { default as predictedPowerEiERIVUP5GMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/more/predictedPower.vue?macro=true";
import { default as siteInfoZc1XpsD3DmMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/more/siteInfo.vue?macro=true";
import { default as moredoa7Key6HsMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/more.vue?macro=true";
import { default as essZTjpqvVx2MMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/powerGeneration/ess.vue?macro=true";
import { default as fuelcelliyX0DZ26xxMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/powerGeneration/fuelcell.vue?macro=true";
import { default as geol9M1EpTfzgMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/powerGeneration/geo.vue?macro=true";
import { default as pv4ps1S1K6mkMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/powerGeneration/pv.vue?macro=true";
import { default as solarheat3xgUUARzfKMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/powerGeneration/solarheat.vue?macro=true";
import { default as wfC0Mnzg2dnQMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/powerGeneration/wf.vue?macro=true";
import { default as powerGenerationVxuRMDPJvpMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/powerGeneration.vue?macro=true";
import { default as geomP23KGLbYQMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/report/geo.vue?macro=true";
import { default as pvJ28ijdDTmKMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/report/pv.vue?macro=true";
import { default as solarheatRPNGrvuEkAMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/report/solarheat.vue?macro=true";
import { default as reportzogMv1NICkMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/report.vue?macro=true";
import { default as pvN0AV3rSZ9wMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/reportPDF/pv.vue?macro=true";
import { default as reportPDFR9VThMOrXfMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/reportPDF.vue?macro=true";
import { default as statuszQjnNSqO4CMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/status.vue?macro=true";
import { default as viewszZfu2bhX8UMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views.vue?macro=true";
import { default as _91id_930CYvOJWwJoMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id].vue?macro=true";
import { default as doneFindidNnVkseXuqtMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/finduser/doneFindid.vue?macro=true";
import { default as doneFindpassxMSYYgDZGpMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/finduser/doneFindpass.vue?macro=true";
import { default as findido1LYxyggmVMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/finduser/findid.vue?macro=true";
import { default as findpass2nLiUc4Hu2Meta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/finduser/findpass.vue?macro=true";
import { default as finduserhBjxOet1EtMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/finduser.vue?macro=true";
import { default as introJ3StANugcBMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/intro.vue?macro=true";
import { default as loginTfF3kkeMmhMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/login.vue?macro=true";
import { default as logoutpztEXOFJn5Meta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/logout.vue?macro=true";
import { default as personTerms3r9yzuxHatMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/signin/personTerms.vue?macro=true";
import { default as termZIt646mqMNMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/signin/term.vue?macro=true";
import { default as useTermsYaTd44uiMNMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/signin/useTerms.vue?macro=true";
import { default as signinHAY08cfYvTMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/signin.vue?macro=true";
import { default as user4XOLFTNwokMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user.vue?macro=true";
import { default as mobile74LE9JJGs8Meta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile.vue?macro=true";
import { default as swagger_45uiUCIRVgJzccMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/swagger-ui.vue?macro=true";
import { default as loginEK1JofeAwpMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/test/login.vue?macro=true";
import { default as mainGHbG9m7iZSMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/test/main.vue?macro=true";
import { default as compare_45ownerE2rO3qTO0MMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/analysis/compare-owner.vue?macro=true";
import { default as compare_45regionUe0GVX5Q5qMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/analysis/compare-region.vue?macro=true";
import { default as geoUzP8nGW6tRMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/analysis/component/geo.vue?macro=true";
import { default as ownerSearchModalftWhLBhOqiMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/analysis/component/ownerSearchModal.vue?macro=true";
import { default as pvtidVfGG70eMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/analysis/component/pv.vue?macro=true";
import { default as solarheatU6IkJisJuDMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/analysis/component/solarheat.vue?macro=true";
import { default as ownerC4qED7z5e1Meta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/analysis/owner.vue?macro=true";
import { default as analysispyx5FCq05AMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/analysis.vue?macro=true";
import { default as Energy_45ListTJ5oReIGnYMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/component/Dashboard/Energy-List.vue?macro=true";
import { default as card_45baseS3wYldrMFzMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/component/Dashboard/card-base.vue?macro=true";
import { default as energy_45cardaUApJJTye8Meta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/component/Dashboard/energy-card.vue?macro=true";
import { default as energy_45card2ZAmZwTmR4KMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/component/Dashboard/energy-card2.vue?macro=true";
import { default as info_45cardHtp38x1spFMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/component/Dashboard/info-card.vue?macro=true";
import { default as link_45rate_45card1npbTYAXJtMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/component/Dashboard/link-rate-card.vue?macro=true";
import { default as FileUploadnYtfPvAKmLMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/component/FileUpload.vue?macro=true";
import { default as indexFLWSA9jgKnMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/component/InstallInfo/index.vue?macro=true";
import { default as pvQ2eyayCf1zMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/component/InstallInfo/pv.vue?macro=true";
import { default as solarheatPevRGrbwboMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/component/InstallInfo/solarheat.vue?macro=true";
import { default as pvxsxNYUfgsBMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/component/InstruInfo/pv.vue?macro=true";
import { default as indexkBLy8Z3aAyMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/component/ManagerInfo/index.vue?macro=true";
import { default as pvVBuWwjmrzdMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/component/ManagerInfo/pv.vue?macro=true";
import { default as solarheat74xR5uqxr5Meta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/component/ManagerInfo/solarheat.vue?macro=true";
import { default as pvWIrh97Ho8mMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/component/OwnerInfo/ManagerInfo/pv.vue?macro=true";
import { default as solarheatFx9PfYY4YjMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/component/OwnerInfo/ManagerInfo/solarheat.vue?macro=true";
import { default as indexgkVwl5mqO8Meta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/component/OwnerInfo/index.vue?macro=true";
import { default as pvugamY0UH3pMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/component/OwnerInfo/pv.vue?macro=true";
import { default as solarEzI9YuHyVBMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/component/OwnerInfo/solar.vue?macro=true";
import { default as indexq1ok5taoGAMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/component/RtuInfo/index.vue?macro=true";
import { default as common_45code_45dropdowntAWZMr3vbvMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/component/SearchBox/common-code-dropdown.vue?macro=true";
import { default as date_45set_45reportokBlWx4z03Meta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/component/SearchBox/date-set-report.vue?macro=true";
import { default as date_45seta4tEWmhFlLMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/component/SearchBox/date-set.vue?macro=true";
import { default as region_45set5jrlp9WUGAMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/component/SearchBox/region-set.vue?macro=true";
import { default as geoXRhNsFrZVdMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/component/SpecInfo/geo.vue?macro=true";
import { default as pvqcn5AnwY7uMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/component/SpecInfo/pv.vue?macro=true";
import { default as solarheatXPIyCcpwbuMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/component/SpecInfo/solarheat.vue?macro=true";
import { default as Table_45Boxn81wZsmUp5Meta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/component/Table-Box.vue?macro=true";
import { default as api_45search_45boxxbdu49rYhIMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/component/api-search-box.vue?macro=true";
import { default as data_45tableXtrIPji56qMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/component/data-table.vue?macro=true";
import { default as event_45search_45gridQuZchCPwH3Meta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/component/event-search-grid.vue?macro=true";
import { default as info_45box27XKGS77zaMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/component/info-box.vue?macro=true";
import { default as region_45selectGT6EUeATq8Meta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/component/region-select.vue?macro=true";
import { default as search_45boxKUl2NqVYzDMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/component/search-box.vue?macro=true";
import { default as table_45paging4y5eBgT6xjMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/component/table-paging.vue?macro=true";
import { default as toc_45templatebqWBrTriN3Meta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/component/template/toc-template.vue?macro=true";
import { default as test_45gridJM40URK4dZMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/component/test-grid.vue?macro=true";
import { default as _91id_93kPjFdKgRk6Meta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/customer/faq/[id].vue?macro=true";
import { default as index3gXpltYiTQMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/customer/faq/index.vue?macro=true";
import { default as faq_companycgQ4iWjSuUMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/customer/faq_company.vue?macro=true";
import { default as login_45termsuSRaNE4xm2Meta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/customer/login-terms.vue?macro=true";
import { default as _91id_93ZgbbN0MoxHMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/customer/notice/[id].vue?macro=true";
import { default as index9sPWysx83IMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/customer/notice/index.vue?macro=true";
import { default as notice0sqjukChiVMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/customer/notice.vue?macro=true";
import { default as _91id_93P4XUIHeVTzMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/customer/qna/[id].vue?macro=true";
import { default as add2KWukzaSYvMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/customer/qna/add.vue?macro=true";
import { default as indexD85eXSk3qMMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/customer/qna/index.vue?macro=true";
import { default as qnav6o9jNTD3PMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/customer/qna.vue?macro=true";
import { default as _91id_93eNsl2DDX75Meta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/customer/reference/[id].vue?macro=true";
import { default as indexQ1Mn8mSYSZMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/customer/reference/index.vue?macro=true";
import { default as referenceuCTHV0lX7VMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/customer/reference.vue?macro=true";
import { default as terms1ApUsgJHnH9Meta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/customer/terms-list/terms1.vue?macro=true";
import { default as terms2hFkWDTjevFMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/customer/terms-list/terms2.vue?macro=true";
import { default as termsmpk4t5ULc5Meta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/customer/terms.vue?macro=true";
import { default as customerNc6HK5MkuqMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/customer.vue?macro=true";
import { default as geo_45prod_45card4YqIpmG6cwMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/component/geo-prod-card.vue?macro=true";
import { default as geo_45prod_45chart7FCnY1dKWzMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/component/geo-prod-chart.vue?macro=true";
import { default as geo_45usage_45card67cJdWY87sMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/component/geo-usage-card.vue?macro=true";
import { default as geo_45usage_45chartMdGELiITFzMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/component/geo-usage-chart.vue?macro=true";
import { default as raw_45data_45chartZpK0HEtEjiMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/component/raw-data-chart.vue?macro=true";
import { default as raw_45data_45info3pdhy5CaSYMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/component/raw-data-info.vue?macro=true";
import { default as solarheat_45prod_45cardQXDRbEqMmLMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/component/solarheat-prod-card.vue?macro=true";
import { default as solarheat_45prod_45chartfhcEuZKbziMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/component/solarheat-prod-chart.vue?macro=true";
import { default as solarheat_45usage_45cardRpIjR1hU4nMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/component/solarheat-usage-card.vue?macro=true";
import { default as solarheat_45usage_45chartlEdqBWsNk0Meta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/component/solarheat-usage-chart.vue?macro=true";
import { default as geo_45prodc310CUXyBsMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/geo-prod.vue?macro=true";
import { default as geo_45usagefYTrQlbnZFMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/geo-usage.vue?macro=true";
import { default as geot7UQHilOfJMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/geo.vue?macro=true";
import { default as pvS85OmOqewVMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/pv.vue?macro=true";
import { default as solarheatxQCn0EyljuMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/solarheat.vue?macro=true";
import { default as _91cid_931wcvWIk7MzMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail/[cid].vue?macro=true";
import { default as equip_45detailU46tacGSaVMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail.vue?macro=true";
import { default as equip_45listdOIQTyxcQSMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-list.vue?macro=true";
import { default as equip_45mapzFlfxpX6aDMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-map.vue?macro=true";
import { default as measureJULiUdqVLPMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/measure.vue?macro=true";
import { default as monitoring_45mapdCM4jr0TP6Meta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/monitoring-map.vue?macro=true";
import { default as indexg22aay85lNMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/power-generate/index.vue?macro=true";
import { default as power_45generateT8uX0cX3XIMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/power-generate.vue?macro=true";
import { default as rtucC9OgG89B9Meta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/rtu.vue?macro=true";
import { default as geoGSAEVGMM22Meta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/site/[newsitename]/geo.vue?macro=true";
import { default as pv1MP5yclD3OMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/site/[newsitename]/pv.vue?macro=true";
import { default as solarheataKnfYSWkMLMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/site/[newsitename]/solarheat.vue?macro=true";
import { default as geoZVRwjNKxgTMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/site/[sitename]/geo.vue?macro=true";
import { default as pvKID1FHAQUkMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/site/[sitename]/pv.vue?macro=true";
import { default as solarheatJPK0XzFlURMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/site/[sitename]/solarheat.vue?macro=true";
import { default as _91sitename_93egawk5KImzMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/site/[sitename].vue?macro=true";
import { default as indexr4IphczNqqMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/site/index.vue?macro=true";
import { default as _91newsitename_9329dALcljhRMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/site/test/[newsitename].vue?macro=true";
import { default as siteNneTtkKg0gMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/site.vue?macro=true";
import { default as equippd98XlPz2nMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/equip.vue?macro=true";
import { default as equipinforpoqbWtsM2Meta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/equipinfo.vue?macro=true";
import { default as errorv9W1yVzr9aMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/error.vue?macro=true";
import { default as _91eventId_93RubYbOah3sMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/event/event-list/[eventId].vue?macro=true";
import { default as event_45listfNEWQsKuKiMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/event/event-list.vue?macro=true";
import { default as indexiigBbyUuDtMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/event/index.vue?macro=true";
import { default as eventwyDbiyZGddMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/event.vue?macro=true";
import { default as installTVxg0KIgevMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/install.vue?macro=true";
import { default as add3aOdhTINfKMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/inventory/equip-company/add.vue?macro=true";
import { default as productFBGhtG2FHfMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/inventory/equip-company/product.vue?macro=true";
import { default as inventorygS72155vDkMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/inventory.vue?macro=true";
import { default as maintainencem6pgxd3n8GMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/maintainence.vue?macro=true";
import { default as addmuUS6i4ILXMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/management/company/add.vue?macro=true";
import { default as indexHKhjDS0QVXMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/management/company/index.vue?macro=true";
import { default as companyaBd1Eis0PHMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/management/company.vue?macro=true";
import { default as equip_45model9HCBmkEqH5Meta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/management/equip-model.vue?macro=true";
import { default as find_45ownerB66O6myJGcMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/management/equip/add/find-owner.vue?macro=true";
import { default as geo_45specqLBj9YpOD1Meta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/management/equip/add/geo-spec.vue?macro=true";
import { default as pv_45specd0PakAiuopMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/management/equip/add/pv-spec.vue?macro=true";
import { default as solarheat_45specmUSpsiMNLrMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/management/equip/add/solarheat-spec.vue?macro=true";
import { default as addlUu343V9bfMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/management/equip/add.vue?macro=true";
import { default as index7z7LdLxvheMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/management/equip/index.vue?macro=true";
import { default as equipT64gXBbfs3Meta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/management/equip.vue?macro=true";
import { default as management48vTg2MZF9Meta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/management/management.vue?macro=true";
import { default as indexAX67MnzGHeMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/management/manager/index.vue?macro=true";
import { default as noticeBL5YNxdAJiMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/management/notice.vue?macro=true";
import { default as addyUVHsYFNXtMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/management/owner/add.vue?macro=true";
import { default as indexrHQOf6ixlvMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/management/owner/index.vue?macro=true";
import { default as ownergIJjGbP7LfMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/management/owner.vue?macro=true";
import { default as index8gtNMxVykXMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/management/permit/index.vue?macro=true";
import { default as permitEGHCNrC6ujMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/management/permit.vue?macro=true";
import { default as operatorSV0XXSnny5Meta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/member/operator.vue?macro=true";
import { default as geo1ReY4lYPcHMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/member/owner-member/[id]/geo.vue?macro=true";
import { default as pvjY4CSHdbrOMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/member/owner-member/[id]/pv.vue?macro=true";
import { default as solarheat4MCrGrAJEDMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/member/owner-member/[id]/solarheat.vue?macro=true";
import { default as _91id_934XGCRKg7feMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/member/owner-member/[id].vue?macro=true";
import { default as owner_45regist8rkn7DjQ5MMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/member/owner-member/owner-regist.vue?macro=true";
import { default as owner_45searchT9dYU2XbCdMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/member/owner-member/owner-search.vue?macro=true";
import { default as ownera8QF0KAWVgMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/member/owner.vue?macro=true";
import { default as systemn1gikzi5tsMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/member/system.vue?macro=true";
import { default as memberPiriU4Vhe2Meta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/member.vue?macro=true";
import { default as alarm_45sending9gsP8lMG9MMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/message/alarm-sending.vue?macro=true";
import { default as email_45sendingTcWv243yQwMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/message/email-sending.vue?macro=true";
import { default as messageELFUtpr7ZaMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/message.vue?macro=true";
import { default as comprehensive_45slide7DHq2Iw6ogMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/monitoring/comprehensive-slide.vue?macro=true";
import { default as dashboard8qU9va8nWUMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/monitoring/dashboard.vue?macro=true";
import { default as equip_dashboardeCCKAhtnEUMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/monitoring/equip_dashboard.vue?macro=true";
import { default as newDashboardwbt1mhmm99Meta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/monitoring/newDashboard.vue?macro=true";
import { default as prod_45slideVm54n1zpzpMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/monitoring/prod-slide.vue?macro=true";
import { default as temp_newDashboardESC7nbcR7GMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/monitoring/temp_newDashboard.vue?macro=true";
import { default as monitoringRvOj8YlrpiMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/monitoring.vue?macro=true";
import { default as not_45foundc4WeloivM0Meta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/not-found.vue?macro=true";
import { default as operate5TVEL1hqeFMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/operate.vue?macro=true";
import { default as ownersQtn4fGzx9cMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/report/analyze-efficient/owners.vue?macro=true";
import { default as prjkindsRf9wHscSYNMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/report/analyze-efficient/prjkinds.vue?macro=true";
import { default as regions9jioqnJfoQMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/report/analyze-efficient/regions.vue?macro=true";
import { default as analyze_45efficientZKXUx9BSITMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/report/analyze-efficient.vue?macro=true";
import { default as ownersdMqylg684UMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/report/analyze-product/owners.vue?macro=true";
import { default as prjkindszqkRhsHf5MMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/report/analyze-product/prjkinds.vue?macro=true";
import { default as regions38Z1HgfGtHMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/report/analyze-product/regions.vue?macro=true";
import { default as analyze_45productGlYk0iH5TfMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/report/analyze-product.vue?macro=true";
import { default as env_45factorncrQQJccaNMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/report/env-factor.vue?macro=true";
import { default as eventeBcdWV6M2GMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/report/event.vue?macro=true";
import { default as predictfXjiFldz5rMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/report/predict.vue?macro=true";
import { default as productGmwYtv4XDkMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/report/product.vue?macro=true";
import { default as reportSKTl32MCPMMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/report.vue?macro=true";
import { default as app_45versionBYPM0LLN1NMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/service-info/app-version.vue?macro=true";
import { default as service_45infoWqCXN6Xn9fMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/service-info.vue?macro=true";
import { default as testqlfduMbTkrMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/test.vue?macro=true";
import { default as manager_45infoCRL7zn8AK0Meta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/user-service/manager-info.vue?macro=true";
import { default as confirm_45modalz4ZtBBtY2yMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/user-service/modal/confirm-modal.vue?macro=true";
import { default as manager_45add_45modalraYDYFBRGvMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/user-service/modal/manager-add-modal.vue?macro=true";
import { default as manager_45modify_45modalP8PlVSRNTpMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/user-service/modal/manager-modify-modal.vue?macro=true";
import { default as set_45manager_45all_45modalgB0d8nie55Meta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/user-service/modal/set-manager-all-modal.vue?macro=true";
import { default as set_45manager_45select_45modalEE9qs7x821Meta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/user-service/modal/set-manager-select-modal.vue?macro=true";
import { default as set_45managerSyXQ8eDt2CMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/user-service/set-manager.vue?macro=true";
import { default as user_45infood7Jm3dji4Meta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/user-service/user-info.vue?macro=true";
import { default as user_45settingK3jtBCW6SIMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/user-service/user-setting.vue?macro=true";
import { default as user_45serviceCPeDBNDzffMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/user-service.vue?macro=true";
import { default as find_45idMr42Dm1TNPMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/user/find-id.vue?macro=true";
import { default as indexvrT6WCkp2oMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/user/index.vue?macro=true";
import { default as loginCieuFY7gJ0Meta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/user/login.vue?macro=true";
import { default as tocqXcM9mTbhxMeta } from "/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc.vue?macro=true";
export default [
  {
    name: errorF6oBbZ0L6aMeta?.name ?? "error",
    path: errorF6oBbZ0L6aMeta?.path ?? "/error",
    meta: errorF6oBbZ0L6aMeta || {},
    alias: errorF6oBbZ0L6aMeta?.alias || [],
    redirect: errorF6oBbZ0L6aMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/error.vue").then(m => m.default || m)
  },
  {
    name: indexNYDAqpajiDMeta?.name ?? "index",
    path: indexNYDAqpajiDMeta?.path ?? "/",
    meta: indexNYDAqpajiDMeta || {},
    alias: indexNYDAqpajiDMeta?.alias || [],
    redirect: indexNYDAqpajiDMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: mobile74LE9JJGs8Meta?.name ?? "mobile",
    path: mobile74LE9JJGs8Meta?.path ?? "/mobile",
    children: [
  {
    name: error_45pageUjUk3Hwbh7Meta?.name ?? "mobile-error-page",
    path: error_45pageUjUk3Hwbh7Meta?.path ?? "error-page",
    meta: error_45pageUjUk3Hwbh7Meta || {},
    alias: error_45pageUjUk3Hwbh7Meta?.alias || [],
    redirect: error_45pageUjUk3Hwbh7Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/error-page.vue").then(m => m.default || m)
  },
  {
    name: none_45contents_45pageOQPV9MsGFSMeta?.name ?? "mobile-none-contents-page",
    path: none_45contents_45pageOQPV9MsGFSMeta?.path ?? "none-contents-page",
    meta: none_45contents_45pageOQPV9MsGFSMeta || {},
    alias: none_45contents_45pageOQPV9MsGFSMeta?.alias || [],
    redirect: none_45contents_45pageOQPV9MsGFSMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/none-contents-page.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyhpC4fVQzNkMeta?.name ?? "mobile-privacy-policy",
    path: privacy_45policyhpC4fVQzNkMeta?.path ?? "privacy-policy",
    meta: privacy_45policyhpC4fVQzNkMeta || {},
    alias: privacy_45policyhpC4fVQzNkMeta?.alias || [],
    redirect: privacy_45policyhpC4fVQzNkMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: usage_45policyx8RORsjM7RMeta?.name ?? "mobile-usage-policy",
    path: usage_45policyx8RORsjM7RMeta?.path ?? "usage-policy",
    meta: usage_45policyx8RORsjM7RMeta || {},
    alias: usage_45policyx8RORsjM7RMeta?.alias || [],
    redirect: usage_45policyx8RORsjM7RMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/usage-policy.vue").then(m => m.default || m)
  },
  {
    name: user4XOLFTNwokMeta?.name ?? "mobile-user",
    path: user4XOLFTNwokMeta?.path ?? "user",
    children: [
  {
    path: _91id_930CYvOJWwJoMeta?.path ?? ":id",
    children: [
  {
    name: changePassDf6wJhK32GMeta?.name ?? "mobile-user-id-changePass",
    path: changePassDf6wJhK32GMeta?.path ?? "changePass",
    meta: changePassDf6wJhK32GMeta || {},
    alias: changePassDf6wJhK32GMeta?.alias || [],
    redirect: changePassDf6wJhK32GMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/changePass.vue").then(m => m.default || m)
  },
  {
    name: cse5z2Bsurr9Meta?.name ?? "mobile-user-id-cs",
    path: cse5z2Bsurr9Meta?.path ?? "cs",
    children: [
  {
    name: _10_questionH9xtUGyj4uMeta?.name ?? "mobile-user-id-cs-guide-company-10_question",
    path: _10_questionH9xtUGyj4uMeta?.path ?? "guide/company/10_question",
    meta: _10_questionH9xtUGyj4uMeta || {},
    alias: _10_questionH9xtUGyj4uMeta?.alias || [],
    redirect: _10_questionH9xtUGyj4uMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/company/10_question.vue").then(m => m.default || m)
  },
  {
    name: _1_serviceOverviewZJP0yjEK7PMeta?.name ?? "mobile-user-id-cs-guide-company-1_serviceOverview",
    path: _1_serviceOverviewZJP0yjEK7PMeta?.path ?? "guide/company/1_serviceOverview",
    meta: _1_serviceOverviewZJP0yjEK7PMeta || {},
    alias: _1_serviceOverviewZJP0yjEK7PMeta?.alias || [],
    redirect: _1_serviceOverviewZJP0yjEK7PMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/company/1_serviceOverview.vue").then(m => m.default || m)
  },
  {
    name: _2_statusVskrga9SdFMeta?.name ?? "mobile-user-id-cs-guide-company-2_status",
    path: _2_statusVskrga9SdFMeta?.path ?? "guide/company/2_status",
    meta: _2_statusVskrga9SdFMeta || {},
    alias: _2_statusVskrga9SdFMeta?.alias || [],
    redirect: _2_statusVskrga9SdFMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/company/2_status.vue").then(m => m.default || m)
  },
  {
    name: _3_equipSelectTE20q7pdnNMeta?.name ?? "mobile-user-id-cs-guide-company-3_equipSelect",
    path: _3_equipSelectTE20q7pdnNMeta?.path ?? "guide/company/3_equipSelect",
    meta: _3_equipSelectTE20q7pdnNMeta || {},
    alias: _3_equipSelectTE20q7pdnNMeta?.alias || [],
    redirect: _3_equipSelectTE20q7pdnNMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/company/3_equipSelect.vue").then(m => m.default || m)
  },
  {
    name: _4_instru4OUGfc0pqOMeta?.name ?? "mobile-user-id-cs-guide-company-4_instru",
    path: _4_instru4OUGfc0pqOMeta?.path ?? "guide/company/4_instru",
    meta: _4_instru4OUGfc0pqOMeta || {},
    alias: _4_instru4OUGfc0pqOMeta?.alias || [],
    redirect: _4_instru4OUGfc0pqOMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/company/4_instru.vue").then(m => m.default || m)
  },
  {
    name: _5_equipInfoT9TtlOBa3AMeta?.name ?? "mobile-user-id-cs-guide-company-5_equipInfo",
    path: _5_equipInfoT9TtlOBa3AMeta?.path ?? "guide/company/5_equipInfo",
    meta: _5_equipInfoT9TtlOBa3AMeta || {},
    alias: _5_equipInfoT9TtlOBa3AMeta?.alias || [],
    redirect: _5_equipInfoT9TtlOBa3AMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/company/5_equipInfo.vue").then(m => m.default || m)
  },
  {
    name: _6_eventijv9b8gIrUMeta?.name ?? "mobile-user-id-cs-guide-company-6_event",
    path: _6_eventijv9b8gIrUMeta?.path ?? "guide/company/6_event",
    meta: _6_eventijv9b8gIrUMeta || {},
    alias: _6_eventijv9b8gIrUMeta?.alias || [],
    redirect: _6_eventijv9b8gIrUMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/company/6_event.vue").then(m => m.default || m)
  },
  {
    name: _7_powerGenerationH5SRWXj7WuMeta?.name ?? "mobile-user-id-cs-guide-company-7_powerGeneration",
    path: _7_powerGenerationH5SRWXj7WuMeta?.path ?? "guide/company/7_powerGeneration",
    meta: _7_powerGenerationH5SRWXj7WuMeta || {},
    alias: _7_powerGenerationH5SRWXj7WuMeta?.alias || [],
    redirect: _7_powerGenerationH5SRWXj7WuMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/company/7_powerGeneration.vue").then(m => m.default || m)
  },
  {
    name: _8_installInfouSRjOgB6PjMeta?.name ?? "mobile-user-id-cs-guide-company-8_installInfo",
    path: _8_installInfouSRjOgB6PjMeta?.path ?? "guide/company/8_installInfo",
    meta: _8_installInfouSRjOgB6PjMeta || {},
    alias: _8_installInfouSRjOgB6PjMeta?.alias || [],
    redirect: _8_installInfouSRjOgB6PjMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/company/8_installInfo.vue").then(m => m.default || m)
  },
  {
    name: _9_ownerInfo7LdHAkAc26Meta?.name ?? "mobile-user-id-cs-guide-company-9_ownerInfo",
    path: _9_ownerInfo7LdHAkAc26Meta?.path ?? "guide/company/9_ownerInfo",
    meta: _9_ownerInfo7LdHAkAc26Meta || {},
    alias: _9_ownerInfo7LdHAkAc26Meta?.alias || [],
    redirect: _9_ownerInfo7LdHAkAc26Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/company/9_ownerInfo.vue").then(m => m.default || m)
  },
  {
    name: indexzE7YNoI6HkMeta?.name ?? "mobile-user-id-cs-guide",
    path: indexzE7YNoI6HkMeta?.path ?? "guide",
    meta: indexzE7YNoI6HkMeta || {},
    alias: indexzE7YNoI6HkMeta?.alias || [],
    redirect: indexzE7YNoI6HkMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/index.vue").then(m => m.default || m)
  },
  {
    name: _1_serviceOverviewoZNxvtYbPBMeta?.name ?? "mobile-user-id-cs-guide-owner-1_serviceOverview",
    path: _1_serviceOverviewoZNxvtYbPBMeta?.path ?? "guide/owner/1_serviceOverview",
    meta: _1_serviceOverviewoZNxvtYbPBMeta || {},
    alias: _1_serviceOverviewoZNxvtYbPBMeta?.alias || [],
    redirect: _1_serviceOverviewoZNxvtYbPBMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/owner/1_serviceOverview.vue").then(m => m.default || m)
  },
  {
    name: _2_statustvinzM1leCMeta?.name ?? "mobile-user-id-cs-guide-owner-2_status",
    path: _2_statustvinzM1leCMeta?.path ?? "guide/owner/2_status",
    meta: _2_statustvinzM1leCMeta || {},
    alias: _2_statustvinzM1leCMeta?.alias || [],
    redirect: _2_statustvinzM1leCMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/owner/2_status.vue").then(m => m.default || m)
  },
  {
    name: _3_monitoringnyooX8Om3XMeta?.name ?? "mobile-user-id-cs-guide-owner-3_monitoring",
    path: _3_monitoringnyooX8Om3XMeta?.path ?? "guide/owner/3_monitoring",
    meta: _3_monitoringnyooX8Om3XMeta || {},
    alias: _3_monitoringnyooX8Om3XMeta?.alias || [],
    redirect: _3_monitoringnyooX8Om3XMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/owner/3_monitoring.vue").then(m => m.default || m)
  },
  {
    name: _4_equipInfopryehk123zMeta?.name ?? "mobile-user-id-cs-guide-owner-4_equipInfo",
    path: _4_equipInfopryehk123zMeta?.path ?? "guide/owner/4_equipInfo",
    meta: _4_equipInfopryehk123zMeta || {},
    alias: _4_equipInfopryehk123zMeta?.alias || [],
    redirect: _4_equipInfopryehk123zMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/owner/4_equipInfo.vue").then(m => m.default || m)
  },
  {
    name: _5_powerGenerationlcvlJ8MquFMeta?.name ?? "mobile-user-id-cs-guide-owner-5_powerGeneration",
    path: _5_powerGenerationlcvlJ8MquFMeta?.path ?? "guide/owner/5_powerGeneration",
    meta: _5_powerGenerationlcvlJ8MquFMeta || {},
    alias: _5_powerGenerationlcvlJ8MquFMeta?.alias || [],
    redirect: _5_powerGenerationlcvlJ8MquFMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/owner/5_powerGeneration.vue").then(m => m.default || m)
  },
  {
    name: _6_reportI60fgmCCxMMeta?.name ?? "mobile-user-id-cs-guide-owner-6_report",
    path: _6_reportI60fgmCCxMMeta?.path ?? "guide/owner/6_report",
    meta: _6_reportI60fgmCCxMMeta || {},
    alias: _6_reportI60fgmCCxMMeta?.alias || [],
    redirect: _6_reportI60fgmCCxMMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/owner/6_report.vue").then(m => m.default || m)
  },
  {
    name: _7_eventaubNDUOb2KMeta?.name ?? "mobile-user-id-cs-guide-owner-7_event",
    path: _7_eventaubNDUOb2KMeta?.path ?? "guide/owner/7_event",
    meta: _7_eventaubNDUOb2KMeta || {},
    alias: _7_eventaubNDUOb2KMeta?.alias || [],
    redirect: _7_eventaubNDUOb2KMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/owner/7_event.vue").then(m => m.default || m)
  },
  {
    name: _8_ownerInfoT8wBpf9pD2Meta?.name ?? "mobile-user-id-cs-guide-owner-8_ownerInfo",
    path: _8_ownerInfoT8wBpf9pD2Meta?.path ?? "guide/owner/8_ownerInfo",
    meta: _8_ownerInfoT8wBpf9pD2Meta || {},
    alias: _8_ownerInfoT8wBpf9pD2Meta?.alias || [],
    redirect: _8_ownerInfoT8wBpf9pD2Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/owner/8_ownerInfo.vue").then(m => m.default || m)
  },
  {
    name: _9_question80BO2QZSATMeta?.name ?? "mobile-user-id-cs-guide-owner-9_question",
    path: _9_question80BO2QZSATMeta?.path ?? "guide/owner/9_question",
    meta: _9_question80BO2QZSATMeta || {},
    alias: _9_question80BO2QZSATMeta?.alias || [],
    redirect: _9_question80BO2QZSATMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/owner/9_question.vue").then(m => m.default || m)
  },
  {
    name: main9DN86f9igeMeta?.name ?? "mobile-user-id-cs-main",
    path: main9DN86f9igeMeta?.path ?? "main",
    meta: main9DN86f9igeMeta || {},
    alias: main9DN86f9igeMeta?.alias || [],
    redirect: main9DN86f9igeMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/main.vue").then(m => m.default || m)
  },
  {
    name: openSourceGWvHOCPLSGMeta?.name ?? "mobile-user-id-cs-openSource",
    path: openSourceGWvHOCPLSGMeta?.path ?? "openSource",
    meta: openSourceGWvHOCPLSGMeta || {},
    alias: openSourceGWvHOCPLSGMeta?.alias || [],
    redirect: openSourceGWvHOCPLSGMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/openSource.vue").then(m => m.default || m)
  },
  {
    name: personTermsPLYNnsoVwgMeta?.name ?? "mobile-user-id-cs-personTerms",
    path: personTermsPLYNnsoVwgMeta?.path ?? "personTerms",
    meta: personTermsPLYNnsoVwgMeta || {},
    alias: personTermsPLYNnsoVwgMeta?.alias || [],
    redirect: personTermsPLYNnsoVwgMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/personTerms.vue").then(m => m.default || m)
  },
  {
    name: question1vPH7fziWfMeta?.name ?? "mobile-user-id-cs-question",
    path: question1vPH7fziWfMeta?.path ?? "question",
    meta: question1vPH7fziWfMeta || {},
    alias: question1vPH7fziWfMeta?.alias || [],
    redirect: question1vPH7fziWfMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/question.vue").then(m => m.default || m)
  },
  {
    name: useTermsbxYd5iOmUCMeta?.name ?? "mobile-user-id-cs-useTerms",
    path: useTermsbxYd5iOmUCMeta?.path ?? "useTerms",
    meta: useTermsbxYd5iOmUCMeta || {},
    alias: useTermsbxYd5iOmUCMeta?.alias || [],
    redirect: useTermsbxYd5iOmUCMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/useTerms.vue").then(m => m.default || m)
  }
],
    meta: cse5z2Bsurr9Meta || {},
    alias: cse5z2Bsurr9Meta?.alias || [],
    redirect: cse5z2Bsurr9Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs.vue").then(m => m.default || m)
  },
  {
    name: equipListRupnPr9MUJMeta?.name ?? "mobile-user-id-equipList",
    path: equipListRupnPr9MUJMeta?.path ?? "equipList",
    meta: equipListRupnPr9MUJMeta || {},
    alias: equipListRupnPr9MUJMeta?.alias || [],
    redirect: equipListRupnPr9MUJMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/equipList.vue").then(m => m.default || m)
  },
  {
    name: equipMapeXAwZXNEDdMeta?.name ?? "mobile-user-id-equipMap",
    path: equipMapeXAwZXNEDdMeta?.path ?? "equipMap",
    meta: equipMapeXAwZXNEDdMeta || {},
    alias: equipMapeXAwZXNEDdMeta?.alias || [],
    redirect: equipMapeXAwZXNEDdMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/equipMap.vue").then(m => m.default || m)
  },
  {
    name: etcEJJomv7I6uMeta?.name ?? "mobile-user-id-etc",
    path: etcEJJomv7I6uMeta?.path ?? "etc",
    children: [
  {
    name: alarmJ7PYCuZfMeMeta?.name ?? "mobile-user-id-etc-alarm",
    path: alarmJ7PYCuZfMeMeta?.path ?? "alarm",
    children: [
  {
    name: alarmETCtEzL0aRfJrMeta?.name ?? "mobile-user-id-etc-alarm-alarmETC",
    path: alarmETCtEzL0aRfJrMeta?.path ?? "alarmETC",
    meta: alarmETCtEzL0aRfJrMeta || {},
    alias: alarmETCtEzL0aRfJrMeta?.alias || [],
    redirect: alarmETCtEzL0aRfJrMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/etc/alarm/alarmETC.vue").then(m => m.default || m)
  },
  {
    name: alarmOBS5iUAJZlivgMeta?.name ?? "mobile-user-id-etc-alarm-alarmOBS",
    path: alarmOBS5iUAJZlivgMeta?.path ?? "alarmOBS",
    meta: alarmOBS5iUAJZlivgMeta || {},
    alias: alarmOBS5iUAJZlivgMeta?.alias || [],
    redirect: alarmOBS5iUAJZlivgMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/etc/alarm/alarmOBS.vue").then(m => m.default || m)
  },
  {
    name: alarmRTUKw4L5YxAaRMeta?.name ?? "mobile-user-id-etc-alarm-alarmRTU",
    path: alarmRTUKw4L5YxAaRMeta?.path ?? "alarmRTU",
    meta: alarmRTUKw4L5YxAaRMeta || {},
    alias: alarmRTUKw4L5YxAaRMeta?.alias || [],
    redirect: alarmRTUKw4L5YxAaRMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/etc/alarm/alarmRTU.vue").then(m => m.default || m)
  },
  {
    name: allAc6aP2VsB0Meta?.name ?? "mobile-user-id-etc-alarm-all",
    path: allAc6aP2VsB0Meta?.path ?? "all",
    meta: allAc6aP2VsB0Meta || {},
    alias: allAc6aP2VsB0Meta?.alias || [],
    redirect: allAc6aP2VsB0Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/etc/alarm/all.vue").then(m => m.default || m)
  }
],
    meta: alarmJ7PYCuZfMeMeta || {},
    alias: alarmJ7PYCuZfMeMeta?.alias || [],
    redirect: alarmJ7PYCuZfMeMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/etc/alarm.vue").then(m => m.default || m)
  },
  {
    path: noticesCWvnZKrFXMeta?.path ?? "notice",
    children: [
  {
    name: _91noticeid_93FnP3D9uKp2Meta?.name ?? "mobile-user-id-etc-notice-noticeid",
    path: _91noticeid_93FnP3D9uKp2Meta?.path ?? ":noticeid",
    meta: _91noticeid_93FnP3D9uKp2Meta || {},
    alias: _91noticeid_93FnP3D9uKp2Meta?.alias || [],
    redirect: _91noticeid_93FnP3D9uKp2Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/etc/notice/[noticeid].vue").then(m => m.default || m)
  },
  {
    name: indextzW66rky5bMeta?.name ?? "mobile-user-id-etc-notice",
    path: indextzW66rky5bMeta?.path ?? "",
    meta: indextzW66rky5bMeta || {},
    alias: indextzW66rky5bMeta?.alias || [],
    redirect: indextzW66rky5bMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/etc/notice/index.vue").then(m => m.default || m)
  }
],
    name: noticesCWvnZKrFXMeta?.name ?? undefined,
    meta: noticesCWvnZKrFXMeta || {},
    alias: noticesCWvnZKrFXMeta?.alias || [],
    redirect: noticesCWvnZKrFXMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/etc/notice.vue").then(m => m.default || m)
  }
],
    meta: etcEJJomv7I6uMeta || {},
    alias: etcEJJomv7I6uMeta?.alias || [],
    redirect: etcEJJomv7I6uMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/etc.vue").then(m => m.default || m)
  },
  {
    name: indexRoOQzcqgKOMeta?.name ?? "mobile-user-id",
    path: indexRoOQzcqgKOMeta?.path ?? "",
    meta: indexRoOQzcqgKOMeta || {},
    alias: indexRoOQzcqgKOMeta?.alias || [],
    redirect: indexRoOQzcqgKOMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: infotgepm374WeMeta?.name ?? "mobile-user-id-info",
    path: infotgepm374WeMeta?.path ?? "info",
    children: [
  {
    name: contact_45changeppbADGLuQYMeta?.name ?? "mobile-user-id-info-contact-change",
    path: contact_45changeppbADGLuQYMeta?.path ?? "contact-change",
    meta: contact_45changeppbADGLuQYMeta || {},
    alias: contact_45changeppbADGLuQYMeta?.alias || [],
    redirect: contact_45changeppbADGLuQYMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/info/contact-change.vue").then(m => m.default || m)
  },
  {
    name: mainnxv0SENwl3Meta?.name ?? "mobile-user-id-info-main",
    path: mainnxv0SENwl3Meta?.path ?? "main",
    meta: mainnxv0SENwl3Meta || {},
    alias: mainnxv0SENwl3Meta?.alias || [],
    redirect: mainnxv0SENwl3Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/info/main.vue").then(m => m.default || m)
  },
  {
    name: pw_45changevC1fQhtpt9Meta?.name ?? "mobile-user-id-info-pw-change",
    path: pw_45changevC1fQhtpt9Meta?.path ?? "pw-change",
    meta: pw_45changevC1fQhtpt9Meta || {},
    alias: pw_45changevC1fQhtpt9Meta?.alias || [],
    redirect: pw_45changevC1fQhtpt9Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/info/pw-change.vue").then(m => m.default || m)
  },
  {
    name: user_45delete42gAtRrJumMeta?.name ?? "mobile-user-id-info-user-delete",
    path: user_45delete42gAtRrJumMeta?.path ?? "user-delete",
    meta: user_45delete42gAtRrJumMeta || {},
    alias: user_45delete42gAtRrJumMeta?.alias || [],
    redirect: user_45delete42gAtRrJumMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/info/user-delete.vue").then(m => m.default || m)
  }
],
    meta: infotgepm374WeMeta || {},
    alias: infotgepm374WeMeta?.alias || [],
    redirect: infotgepm374WeMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/info.vue").then(m => m.default || m)
  },
  {
    name: settingk3rT8Drn0iMeta?.name ?? "mobile-user-id-setting",
    path: settingk3rT8Drn0iMeta?.path ?? "setting",
    meta: settingk3rT8Drn0iMeta || {},
    alias: settingk3rT8Drn0iMeta?.alias || [],
    redirect: settingk3rT8Drn0iMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/setting.vue").then(m => m.default || m)
  },
  {
    name: siteListpbiFgJxcg9Meta?.name ?? "mobile-user-id-siteList",
    path: siteListpbiFgJxcg9Meta?.path ?? "siteList",
    meta: siteListpbiFgJxcg9Meta || {},
    alias: siteListpbiFgJxcg9Meta?.alias || [],
    redirect: siteListpbiFgJxcg9Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/siteList.vue").then(m => m.default || m)
  },
  {
    name: viewszZfu2bhX8UMeta?.name ?? "mobile-user-id-views",
    path: viewszZfu2bhX8UMeta?.path ?? "views",
    children: [
  {
    name: FailoverEditaJqB634ilaMeta?.name ?? "mobile-user-id-views-FailoverEdit",
    path: FailoverEditaJqB634ilaMeta?.path ?? "FailoverEdit",
    meta: FailoverEditaJqB634ilaMeta || {},
    alias: FailoverEditaJqB634ilaMeta?.alias || [],
    redirect: FailoverEditaJqB634ilaMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/FailoverEdit.vue").then(m => m.default || m)
  },
  {
    name: equipInfop6unFGIDNNMeta?.name ?? "mobile-user-id-views-equipInfo",
    path: equipInfop6unFGIDNNMeta?.path ?? "equipInfo",
    children: [
  {
    path: essQWHayBK3MTMeta?.path ?? "company/ess",
    children: [
  {
    path: InstallInfontUZnNkTOHMeta?.path ?? "InstallInfo",
    children: [
  {
    name: index3QJVLjdRiSMeta?.name ?? "mobile-user-id-views-equipInfo-company-ess-InstallInfo",
    path: index3QJVLjdRiSMeta?.path ?? "",
    meta: index3QJVLjdRiSMeta || {},
    alias: index3QJVLjdRiSMeta?.alias || [],
    redirect: index3QJVLjdRiSMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/ess/InstallInfo/index.vue").then(m => m.default || m)
  }
],
    name: InstallInfontUZnNkTOHMeta?.name ?? undefined,
    meta: InstallInfontUZnNkTOHMeta || {},
    alias: InstallInfontUZnNkTOHMeta?.alias || [],
    redirect: InstallInfontUZnNkTOHMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/ess/InstallInfo.vue").then(m => m.default || m)
  },
  {
    name: InstruInfo50nVkwalyuMeta?.name ?? "mobile-user-id-views-equipInfo-company-ess-InstruInfo",
    path: InstruInfo50nVkwalyuMeta?.path ?? "InstruInfo",
    children: [
  {
    name: chartbrmRTm4dBJMeta?.name ?? "mobile-user-id-views-equipInfo-company-ess-InstruInfo-chart",
    path: chartbrmRTm4dBJMeta?.path ?? "chart",
    meta: chartbrmRTm4dBJMeta || {},
    alias: chartbrmRTm4dBJMeta?.alias || [],
    redirect: chartbrmRTm4dBJMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/ess/InstruInfo/chart.vue").then(m => m.default || m)
  },
  {
    name: nowp2MIFC61MuMeta?.name ?? "mobile-user-id-views-equipInfo-company-ess-InstruInfo-now",
    path: nowp2MIFC61MuMeta?.path ?? "now",
    meta: nowp2MIFC61MuMeta || {},
    alias: nowp2MIFC61MuMeta?.alias || [],
    redirect: nowp2MIFC61MuMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/ess/InstruInfo/now.vue").then(m => m.default || m)
  }
],
    meta: InstruInfo50nVkwalyuMeta || {},
    alias: InstruInfo50nVkwalyuMeta?.alias || [],
    redirect: InstruInfo50nVkwalyuMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/ess/InstruInfo.vue").then(m => m.default || m)
  },
  {
    name: index2JqFZg06U5Meta?.name ?? "mobile-user-id-views-equipInfo-company-ess",
    path: index2JqFZg06U5Meta?.path ?? "",
    meta: index2JqFZg06U5Meta || {},
    alias: index2JqFZg06U5Meta?.alias || [],
    redirect: index2JqFZg06U5Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/ess/index.vue").then(m => m.default || m)
  }
],
    name: essQWHayBK3MTMeta?.name ?? undefined,
    meta: essQWHayBK3MTMeta || {},
    alias: essQWHayBK3MTMeta?.alias || [],
    redirect: essQWHayBK3MTMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/ess.vue").then(m => m.default || m)
  },
  {
    path: fuelcellx8bR0XYcrWMeta?.path ?? "company/fuelcell",
    children: [
  {
    path: InstallInfoVQyRY0T96dMeta?.path ?? "InstallInfo",
    children: [
  {
    name: indexpKjAQXnCPxMeta?.name ?? "mobile-user-id-views-equipInfo-company-fuelcell-InstallInfo",
    path: indexpKjAQXnCPxMeta?.path ?? "",
    meta: indexpKjAQXnCPxMeta || {},
    alias: indexpKjAQXnCPxMeta?.alias || [],
    redirect: indexpKjAQXnCPxMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/fuelcell/InstallInfo/index.vue").then(m => m.default || m)
  }
],
    name: InstallInfoVQyRY0T96dMeta?.name ?? undefined,
    meta: InstallInfoVQyRY0T96dMeta || {},
    alias: InstallInfoVQyRY0T96dMeta?.alias || [],
    redirect: InstallInfoVQyRY0T96dMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/fuelcell/InstallInfo.vue").then(m => m.default || m)
  },
  {
    name: InstruInfoClcYRAHiEfMeta?.name ?? "mobile-user-id-views-equipInfo-company-fuelcell-InstruInfo",
    path: InstruInfoClcYRAHiEfMeta?.path ?? "InstruInfo",
    children: [
  {
    name: chartgW86VjSYzkMeta?.name ?? "mobile-user-id-views-equipInfo-company-fuelcell-InstruInfo-chart",
    path: chartgW86VjSYzkMeta?.path ?? "chart",
    meta: chartgW86VjSYzkMeta || {},
    alias: chartgW86VjSYzkMeta?.alias || [],
    redirect: chartgW86VjSYzkMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/fuelcell/InstruInfo/chart.vue").then(m => m.default || m)
  },
  {
    name: nowlj02KGNvm7Meta?.name ?? "mobile-user-id-views-equipInfo-company-fuelcell-InstruInfo-now",
    path: nowlj02KGNvm7Meta?.path ?? "now",
    meta: nowlj02KGNvm7Meta || {},
    alias: nowlj02KGNvm7Meta?.alias || [],
    redirect: nowlj02KGNvm7Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/fuelcell/InstruInfo/now.vue").then(m => m.default || m)
  }
],
    meta: InstruInfoClcYRAHiEfMeta || {},
    alias: InstruInfoClcYRAHiEfMeta?.alias || [],
    redirect: InstruInfoClcYRAHiEfMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/fuelcell/InstruInfo.vue").then(m => m.default || m)
  },
  {
    name: indexulPS1auxSNMeta?.name ?? "mobile-user-id-views-equipInfo-company-fuelcell",
    path: indexulPS1auxSNMeta?.path ?? "",
    meta: indexulPS1auxSNMeta || {},
    alias: indexulPS1auxSNMeta?.alias || [],
    redirect: indexulPS1auxSNMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/fuelcell/index.vue").then(m => m.default || m)
  }
],
    name: fuelcellx8bR0XYcrWMeta?.name ?? undefined,
    meta: fuelcellx8bR0XYcrWMeta || {},
    alias: fuelcellx8bR0XYcrWMeta?.alias || [],
    redirect: fuelcellx8bR0XYcrWMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/fuelcell.vue").then(m => m.default || m)
  },
  {
    name: geo0xQq7CjsLaMeta?.name ?? "mobile-user-id-views-equipInfo-company-geo",
    path: geo0xQq7CjsLaMeta?.path ?? "company/geo",
    children: [
  {
    name: InstallInfo0sIhYgMkT3Meta?.name ?? "mobile-user-id-views-equipInfo-company-geo-InstallInfo",
    path: InstallInfo0sIhYgMkT3Meta?.path ?? "InstallInfo",
    children: [
  {
    name: installnZccD5xpNiMeta?.name ?? "mobile-user-id-views-equipInfo-company-geo-InstallInfo-install",
    path: installnZccD5xpNiMeta?.path ?? "install",
    meta: installnZccD5xpNiMeta || {},
    alias: installnZccD5xpNiMeta?.alias || [],
    redirect: installnZccD5xpNiMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/geo/InstallInfo/install.vue").then(m => m.default || m)
  },
  {
    name: managerlbklbkL7vvMeta?.name ?? "mobile-user-id-views-equipInfo-company-geo-InstallInfo-manager",
    path: managerlbklbkL7vvMeta?.path ?? "manager",
    meta: managerlbklbkL7vvMeta || {},
    alias: managerlbklbkL7vvMeta?.alias || [],
    redirect: managerlbklbkL7vvMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/geo/InstallInfo/manager.vue").then(m => m.default || m)
  }
],
    meta: InstallInfo0sIhYgMkT3Meta || {},
    alias: InstallInfo0sIhYgMkT3Meta?.alias || [],
    redirect: InstallInfo0sIhYgMkT3Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/geo/InstallInfo.vue").then(m => m.default || m)
  },
  {
    name: InstruInfoqfTRUEHpvWMeta?.name ?? "mobile-user-id-views-equipInfo-company-geo-InstruInfo",
    path: InstruInfoqfTRUEHpvWMeta?.path ?? "InstruInfo",
    children: [
  {
    name: chart2GWeLYWArwMeta?.name ?? "mobile-user-id-views-equipInfo-company-geo-InstruInfo-chart",
    path: chart2GWeLYWArwMeta?.path ?? "chart",
    meta: chart2GWeLYWArwMeta || {},
    alias: chart2GWeLYWArwMeta?.alias || [],
    redirect: chart2GWeLYWArwMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/geo/InstruInfo/chart.vue").then(m => m.default || m)
  },
  {
    name: now2l80GYafIMMeta?.name ?? "mobile-user-id-views-equipInfo-company-geo-InstruInfo-now",
    path: now2l80GYafIMMeta?.path ?? "now",
    meta: now2l80GYafIMMeta || {},
    alias: now2l80GYafIMMeta?.alias || [],
    redirect: now2l80GYafIMMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/geo/InstruInfo/now.vue").then(m => m.default || m)
  }
],
    meta: InstruInfoqfTRUEHpvWMeta || {},
    alias: InstruInfoqfTRUEHpvWMeta?.alias || [],
    redirect: InstruInfoqfTRUEHpvWMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/geo/InstruInfo.vue").then(m => m.default || m)
  },
  {
    name: SpecInfoJiLp0eM2JDMeta?.name ?? "mobile-user-id-views-equipInfo-company-geo-SpecInfo",
    path: SpecInfoJiLp0eM2JDMeta?.path ?? "SpecInfo",
    children: [
  {
    name: rtuIjtpbBaQvpMeta?.name ?? "mobile-user-id-views-equipInfo-company-geo-SpecInfo-rtu",
    path: rtuIjtpbBaQvpMeta?.path ?? "rtu",
    meta: rtuIjtpbBaQvpMeta || {},
    alias: rtuIjtpbBaQvpMeta?.alias || [],
    redirect: rtuIjtpbBaQvpMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/geo/SpecInfo/rtu.vue").then(m => m.default || m)
  },
  {
    name: specFjsKIvqBBjMeta?.name ?? "mobile-user-id-views-equipInfo-company-geo-SpecInfo-spec",
    path: specFjsKIvqBBjMeta?.path ?? "spec",
    meta: specFjsKIvqBBjMeta || {},
    alias: specFjsKIvqBBjMeta?.alias || [],
    redirect: specFjsKIvqBBjMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/geo/SpecInfo/spec.vue").then(m => m.default || m)
  }
],
    meta: SpecInfoJiLp0eM2JDMeta || {},
    alias: SpecInfoJiLp0eM2JDMeta?.alias || [],
    redirect: SpecInfoJiLp0eM2JDMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/geo/SpecInfo.vue").then(m => m.default || m)
  }
],
    meta: geo0xQq7CjsLaMeta || {},
    alias: geo0xQq7CjsLaMeta?.alias || [],
    redirect: geo0xQq7CjsLaMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/geo.vue").then(m => m.default || m)
  },
  {
    name: pvqDKLTBEfFPMeta?.name ?? "mobile-user-id-views-equipInfo-company-pv",
    path: pvqDKLTBEfFPMeta?.path ?? "company/pv",
    children: [
  {
    name: InstallInfo42ifbpfhFhMeta?.name ?? "mobile-user-id-views-equipInfo-company-pv-InstallInfo",
    path: InstallInfo42ifbpfhFhMeta?.path ?? "InstallInfo",
    children: [
  {
    name: installZKM8jK2tmJMeta?.name ?? "mobile-user-id-views-equipInfo-company-pv-InstallInfo-install",
    path: installZKM8jK2tmJMeta?.path ?? "install",
    meta: installZKM8jK2tmJMeta || {},
    alias: installZKM8jK2tmJMeta?.alias || [],
    redirect: installZKM8jK2tmJMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/pv/InstallInfo/install.vue").then(m => m.default || m)
  },
  {
    name: managerlzEnxTJdqIMeta?.name ?? "mobile-user-id-views-equipInfo-company-pv-InstallInfo-manager",
    path: managerlzEnxTJdqIMeta?.path ?? "manager",
    meta: managerlzEnxTJdqIMeta || {},
    alias: managerlzEnxTJdqIMeta?.alias || [],
    redirect: managerlzEnxTJdqIMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/pv/InstallInfo/manager.vue").then(m => m.default || m)
  }
],
    meta: InstallInfo42ifbpfhFhMeta || {},
    alias: InstallInfo42ifbpfhFhMeta?.alias || [],
    redirect: InstallInfo42ifbpfhFhMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/pv/InstallInfo.vue").then(m => m.default || m)
  },
  {
    name: InstruInfo5NwaJPxOzCMeta?.name ?? "mobile-user-id-views-equipInfo-company-pv-InstruInfo",
    path: InstruInfo5NwaJPxOzCMeta?.path ?? "InstruInfo",
    children: [
  {
    name: chartswhDqEUsoTMeta?.name ?? "mobile-user-id-views-equipInfo-company-pv-InstruInfo-chart",
    path: chartswhDqEUsoTMeta?.path ?? "chart",
    meta: chartswhDqEUsoTMeta || {},
    alias: chartswhDqEUsoTMeta?.alias || [],
    redirect: chartswhDqEUsoTMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/pv/InstruInfo/chart.vue").then(m => m.default || m)
  },
  {
    name: nowdynzmltYElMeta?.name ?? "mobile-user-id-views-equipInfo-company-pv-InstruInfo-now",
    path: nowdynzmltYElMeta?.path ?? "now",
    meta: nowdynzmltYElMeta || {},
    alias: nowdynzmltYElMeta?.alias || [],
    redirect: nowdynzmltYElMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/pv/InstruInfo/now.vue").then(m => m.default || m)
  }
],
    meta: InstruInfo5NwaJPxOzCMeta || {},
    alias: InstruInfo5NwaJPxOzCMeta?.alias || [],
    redirect: InstruInfo5NwaJPxOzCMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/pv/InstruInfo.vue").then(m => m.default || m)
  },
  {
    name: SpecInfoSM1qTJwziCMeta?.name ?? "mobile-user-id-views-equipInfo-company-pv-SpecInfo",
    path: SpecInfoSM1qTJwziCMeta?.path ?? "SpecInfo",
    children: [
  {
    name: rtuG7DfEU2jpuMeta?.name ?? "mobile-user-id-views-equipInfo-company-pv-SpecInfo-rtu",
    path: rtuG7DfEU2jpuMeta?.path ?? "rtu",
    meta: rtuG7DfEU2jpuMeta || {},
    alias: rtuG7DfEU2jpuMeta?.alias || [],
    redirect: rtuG7DfEU2jpuMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/pv/SpecInfo/rtu.vue").then(m => m.default || m)
  },
  {
    name: specTgmDe9lvnAMeta?.name ?? "mobile-user-id-views-equipInfo-company-pv-SpecInfo-spec",
    path: specTgmDe9lvnAMeta?.path ?? "spec",
    meta: specTgmDe9lvnAMeta || {},
    alias: specTgmDe9lvnAMeta?.alias || [],
    redirect: specTgmDe9lvnAMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/pv/SpecInfo/spec.vue").then(m => m.default || m)
  }
],
    meta: SpecInfoSM1qTJwziCMeta || {},
    alias: SpecInfoSM1qTJwziCMeta?.alias || [],
    redirect: SpecInfoSM1qTJwziCMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/pv/SpecInfo.vue").then(m => m.default || m)
  }
],
    meta: pvqDKLTBEfFPMeta || {},
    alias: pvqDKLTBEfFPMeta?.alias || [],
    redirect: pvqDKLTBEfFPMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/pv.vue").then(m => m.default || m)
  },
  {
    name: solarheat9ZC2Pjja3fMeta?.name ?? "mobile-user-id-views-equipInfo-company-solarheat",
    path: solarheat9ZC2Pjja3fMeta?.path ?? "company/solarheat",
    children: [
  {
    name: InstallInfoVoN9gKkqkqMeta?.name ?? "mobile-user-id-views-equipInfo-company-solarheat-InstallInfo",
    path: InstallInfoVoN9gKkqkqMeta?.path ?? "InstallInfo",
    children: [
  {
    name: installrogZbCDwsfMeta?.name ?? "mobile-user-id-views-equipInfo-company-solarheat-InstallInfo-install",
    path: installrogZbCDwsfMeta?.path ?? "install",
    meta: installrogZbCDwsfMeta || {},
    alias: installrogZbCDwsfMeta?.alias || [],
    redirect: installrogZbCDwsfMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/solarheat/InstallInfo/install.vue").then(m => m.default || m)
  },
  {
    name: managerpyuWEmswtLMeta?.name ?? "mobile-user-id-views-equipInfo-company-solarheat-InstallInfo-manager",
    path: managerpyuWEmswtLMeta?.path ?? "manager",
    meta: managerpyuWEmswtLMeta || {},
    alias: managerpyuWEmswtLMeta?.alias || [],
    redirect: managerpyuWEmswtLMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/solarheat/InstallInfo/manager.vue").then(m => m.default || m)
  }
],
    meta: InstallInfoVoN9gKkqkqMeta || {},
    alias: InstallInfoVoN9gKkqkqMeta?.alias || [],
    redirect: InstallInfoVoN9gKkqkqMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/solarheat/InstallInfo.vue").then(m => m.default || m)
  },
  {
    name: InstruInfobJ0e6HWwyqMeta?.name ?? "mobile-user-id-views-equipInfo-company-solarheat-InstruInfo",
    path: InstruInfobJ0e6HWwyqMeta?.path ?? "InstruInfo",
    children: [
  {
    name: chartAUz20IfNROMeta?.name ?? "mobile-user-id-views-equipInfo-company-solarheat-InstruInfo-chart",
    path: chartAUz20IfNROMeta?.path ?? "chart",
    meta: chartAUz20IfNROMeta || {},
    alias: chartAUz20IfNROMeta?.alias || [],
    redirect: chartAUz20IfNROMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/solarheat/InstruInfo/chart.vue").then(m => m.default || m)
  },
  {
    name: nowOoXWdBAd7uMeta?.name ?? "mobile-user-id-views-equipInfo-company-solarheat-InstruInfo-now",
    path: nowOoXWdBAd7uMeta?.path ?? "now",
    meta: nowOoXWdBAd7uMeta || {},
    alias: nowOoXWdBAd7uMeta?.alias || [],
    redirect: nowOoXWdBAd7uMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/solarheat/InstruInfo/now.vue").then(m => m.default || m)
  }
],
    meta: InstruInfobJ0e6HWwyqMeta || {},
    alias: InstruInfobJ0e6HWwyqMeta?.alias || [],
    redirect: InstruInfobJ0e6HWwyqMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/solarheat/InstruInfo.vue").then(m => m.default || m)
  },
  {
    name: SpecInfoAa3kYpRI2tMeta?.name ?? "mobile-user-id-views-equipInfo-company-solarheat-SpecInfo",
    path: SpecInfoAa3kYpRI2tMeta?.path ?? "SpecInfo",
    children: [
  {
    name: rtugzc66NVf4VMeta?.name ?? "mobile-user-id-views-equipInfo-company-solarheat-SpecInfo-rtu",
    path: rtugzc66NVf4VMeta?.path ?? "rtu",
    meta: rtugzc66NVf4VMeta || {},
    alias: rtugzc66NVf4VMeta?.alias || [],
    redirect: rtugzc66NVf4VMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/solarheat/SpecInfo/rtu.vue").then(m => m.default || m)
  },
  {
    name: specOt0Id4oJGGMeta?.name ?? "mobile-user-id-views-equipInfo-company-solarheat-SpecInfo-spec",
    path: specOt0Id4oJGGMeta?.path ?? "spec",
    meta: specOt0Id4oJGGMeta || {},
    alias: specOt0Id4oJGGMeta?.alias || [],
    redirect: specOt0Id4oJGGMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/solarheat/SpecInfo/spec.vue").then(m => m.default || m)
  }
],
    meta: SpecInfoAa3kYpRI2tMeta || {},
    alias: SpecInfoAa3kYpRI2tMeta?.alias || [],
    redirect: SpecInfoAa3kYpRI2tMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/solarheat/SpecInfo.vue").then(m => m.default || m)
  }
],
    meta: solarheat9ZC2Pjja3fMeta || {},
    alias: solarheat9ZC2Pjja3fMeta?.alias || [],
    redirect: solarheat9ZC2Pjja3fMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/solarheat.vue").then(m => m.default || m)
  },
  {
    path: wf6kmGMGnh7bMeta?.path ?? "company/wf",
    children: [
  {
    path: InstallInfoTup2RAyUzZMeta?.path ?? "InstallInfo",
    children: [
  {
    name: indexcIkcWgxhdaMeta?.name ?? "mobile-user-id-views-equipInfo-company-wf-InstallInfo",
    path: indexcIkcWgxhdaMeta?.path ?? "",
    meta: indexcIkcWgxhdaMeta || {},
    alias: indexcIkcWgxhdaMeta?.alias || [],
    redirect: indexcIkcWgxhdaMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/wf/InstallInfo/index.vue").then(m => m.default || m)
  }
],
    name: InstallInfoTup2RAyUzZMeta?.name ?? undefined,
    meta: InstallInfoTup2RAyUzZMeta || {},
    alias: InstallInfoTup2RAyUzZMeta?.alias || [],
    redirect: InstallInfoTup2RAyUzZMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/wf/InstallInfo.vue").then(m => m.default || m)
  },
  {
    name: InstruInfoECHuJBSufIMeta?.name ?? "mobile-user-id-views-equipInfo-company-wf-InstruInfo",
    path: InstruInfoECHuJBSufIMeta?.path ?? "InstruInfo",
    children: [
  {
    name: chartDsrEvcmo0CMeta?.name ?? "mobile-user-id-views-equipInfo-company-wf-InstruInfo-chart",
    path: chartDsrEvcmo0CMeta?.path ?? "chart",
    meta: chartDsrEvcmo0CMeta || {},
    alias: chartDsrEvcmo0CMeta?.alias || [],
    redirect: chartDsrEvcmo0CMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/wf/InstruInfo/chart.vue").then(m => m.default || m)
  },
  {
    name: nowkLqMaw3rrBMeta?.name ?? "mobile-user-id-views-equipInfo-company-wf-InstruInfo-now",
    path: nowkLqMaw3rrBMeta?.path ?? "now",
    meta: nowkLqMaw3rrBMeta || {},
    alias: nowkLqMaw3rrBMeta?.alias || [],
    redirect: nowkLqMaw3rrBMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/wf/InstruInfo/now.vue").then(m => m.default || m)
  }
],
    meta: InstruInfoECHuJBSufIMeta || {},
    alias: InstruInfoECHuJBSufIMeta?.alias || [],
    redirect: InstruInfoECHuJBSufIMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/wf/InstruInfo.vue").then(m => m.default || m)
  },
  {
    name: indexaBHivRNNSTMeta?.name ?? "mobile-user-id-views-equipInfo-company-wf",
    path: indexaBHivRNNSTMeta?.path ?? "",
    meta: indexaBHivRNNSTMeta || {},
    alias: indexaBHivRNNSTMeta?.alias || [],
    redirect: indexaBHivRNNSTMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/wf/index.vue").then(m => m.default || m)
  }
],
    name: wf6kmGMGnh7bMeta?.name ?? undefined,
    meta: wf6kmGMGnh7bMeta || {},
    alias: wf6kmGMGnh7bMeta?.alias || [],
    redirect: wf6kmGMGnh7bMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/wf.vue").then(m => m.default || m)
  },
  {
    path: essMUkT8UcbgMMeta?.path ?? "owner/ess",
    children: [
  {
    path: InstallInfoF12lNaQ79JMeta?.path ?? "InstallInfo",
    children: [
  {
    name: indexvrn6b2JyJ9Meta?.name ?? "mobile-user-id-views-equipInfo-owner-ess-InstallInfo",
    path: indexvrn6b2JyJ9Meta?.path ?? "",
    meta: indexvrn6b2JyJ9Meta || {},
    alias: indexvrn6b2JyJ9Meta?.alias || [],
    redirect: indexvrn6b2JyJ9Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/ess/InstallInfo/index.vue").then(m => m.default || m)
  }
],
    name: InstallInfoF12lNaQ79JMeta?.name ?? undefined,
    meta: InstallInfoF12lNaQ79JMeta || {},
    alias: InstallInfoF12lNaQ79JMeta?.alias || [],
    redirect: InstallInfoF12lNaQ79JMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/ess/InstallInfo.vue").then(m => m.default || m)
  },
  {
    name: InstruInfoZ4ktRYnEFlMeta?.name ?? "mobile-user-id-views-equipInfo-owner-ess-InstruInfo",
    path: InstruInfoZ4ktRYnEFlMeta?.path ?? "InstruInfo",
    children: [
  {
    name: chartSirgPEr2OaMeta?.name ?? "mobile-user-id-views-equipInfo-owner-ess-InstruInfo-chart",
    path: chartSirgPEr2OaMeta?.path ?? "chart",
    meta: chartSirgPEr2OaMeta || {},
    alias: chartSirgPEr2OaMeta?.alias || [],
    redirect: chartSirgPEr2OaMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/ess/InstruInfo/chart.vue").then(m => m.default || m)
  },
  {
    name: nowZ3QGMUNXugMeta?.name ?? "mobile-user-id-views-equipInfo-owner-ess-InstruInfo-now",
    path: nowZ3QGMUNXugMeta?.path ?? "now",
    meta: nowZ3QGMUNXugMeta || {},
    alias: nowZ3QGMUNXugMeta?.alias || [],
    redirect: nowZ3QGMUNXugMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/ess/InstruInfo/now.vue").then(m => m.default || m)
  }
],
    meta: InstruInfoZ4ktRYnEFlMeta || {},
    alias: InstruInfoZ4ktRYnEFlMeta?.alias || [],
    redirect: InstruInfoZ4ktRYnEFlMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/ess/InstruInfo.vue").then(m => m.default || m)
  },
  {
    name: indexaflpIikXICMeta?.name ?? "mobile-user-id-views-equipInfo-owner-ess",
    path: indexaflpIikXICMeta?.path ?? "",
    meta: indexaflpIikXICMeta || {},
    alias: indexaflpIikXICMeta?.alias || [],
    redirect: indexaflpIikXICMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/ess/index.vue").then(m => m.default || m)
  }
],
    name: essMUkT8UcbgMMeta?.name ?? undefined,
    meta: essMUkT8UcbgMMeta || {},
    alias: essMUkT8UcbgMMeta?.alias || [],
    redirect: essMUkT8UcbgMMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/ess.vue").then(m => m.default || m)
  },
  {
    path: fuelcell2mGFLqpKeMMeta?.path ?? "owner/fuelcell",
    children: [
  {
    path: InstallInfo9Zz5Hg3p28Meta?.path ?? "InstallInfo",
    children: [
  {
    name: indexNfMzLryHftMeta?.name ?? "mobile-user-id-views-equipInfo-owner-fuelcell-InstallInfo",
    path: indexNfMzLryHftMeta?.path ?? "",
    meta: indexNfMzLryHftMeta || {},
    alias: indexNfMzLryHftMeta?.alias || [],
    redirect: indexNfMzLryHftMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/fuelcell/InstallInfo/index.vue").then(m => m.default || m)
  }
],
    name: InstallInfo9Zz5Hg3p28Meta?.name ?? undefined,
    meta: InstallInfo9Zz5Hg3p28Meta || {},
    alias: InstallInfo9Zz5Hg3p28Meta?.alias || [],
    redirect: InstallInfo9Zz5Hg3p28Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/fuelcell/InstallInfo.vue").then(m => m.default || m)
  },
  {
    name: InstruInfo3IkpJrL79hMeta?.name ?? "mobile-user-id-views-equipInfo-owner-fuelcell-InstruInfo",
    path: InstruInfo3IkpJrL79hMeta?.path ?? "InstruInfo",
    children: [
  {
    name: chartb7B0L59thrMeta?.name ?? "mobile-user-id-views-equipInfo-owner-fuelcell-InstruInfo-chart",
    path: chartb7B0L59thrMeta?.path ?? "chart",
    meta: chartb7B0L59thrMeta || {},
    alias: chartb7B0L59thrMeta?.alias || [],
    redirect: chartb7B0L59thrMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/fuelcell/InstruInfo/chart.vue").then(m => m.default || m)
  },
  {
    name: nown4raUzlZRhMeta?.name ?? "mobile-user-id-views-equipInfo-owner-fuelcell-InstruInfo-now",
    path: nown4raUzlZRhMeta?.path ?? "now",
    meta: nown4raUzlZRhMeta || {},
    alias: nown4raUzlZRhMeta?.alias || [],
    redirect: nown4raUzlZRhMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/fuelcell/InstruInfo/now.vue").then(m => m.default || m)
  }
],
    meta: InstruInfo3IkpJrL79hMeta || {},
    alias: InstruInfo3IkpJrL79hMeta?.alias || [],
    redirect: InstruInfo3IkpJrL79hMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/fuelcell/InstruInfo.vue").then(m => m.default || m)
  },
  {
    name: indexRNXYD6muYuMeta?.name ?? "mobile-user-id-views-equipInfo-owner-fuelcell",
    path: indexRNXYD6muYuMeta?.path ?? "",
    meta: indexRNXYD6muYuMeta || {},
    alias: indexRNXYD6muYuMeta?.alias || [],
    redirect: indexRNXYD6muYuMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/fuelcell/index.vue").then(m => m.default || m)
  }
],
    name: fuelcell2mGFLqpKeMMeta?.name ?? undefined,
    meta: fuelcell2mGFLqpKeMMeta || {},
    alias: fuelcell2mGFLqpKeMMeta?.alias || [],
    redirect: fuelcell2mGFLqpKeMMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/fuelcell.vue").then(m => m.default || m)
  },
  {
    name: geoACVLKdl9j8Meta?.name ?? "mobile-user-id-views-equipInfo-owner-geo",
    path: geoACVLKdl9j8Meta?.path ?? "owner/geo",
    children: [
  {
    name: InstallInfoQQq9rqLFPjMeta?.name ?? "mobile-user-id-views-equipInfo-owner-geo-InstallInfo",
    path: InstallInfoQQq9rqLFPjMeta?.path ?? "InstallInfo",
    children: [
  {
    name: install1u7PnJQnFDMeta?.name ?? "mobile-user-id-views-equipInfo-owner-geo-InstallInfo-install",
    path: install1u7PnJQnFDMeta?.path ?? "install",
    meta: install1u7PnJQnFDMeta || {},
    alias: install1u7PnJQnFDMeta?.alias || [],
    redirect: install1u7PnJQnFDMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/geo/InstallInfo/install.vue").then(m => m.default || m)
  },
  {
    name: manager4GeiguZlZsMeta?.name ?? "mobile-user-id-views-equipInfo-owner-geo-InstallInfo-manager",
    path: manager4GeiguZlZsMeta?.path ?? "manager",
    meta: manager4GeiguZlZsMeta || {},
    alias: manager4GeiguZlZsMeta?.alias || [],
    redirect: manager4GeiguZlZsMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/geo/InstallInfo/manager.vue").then(m => m.default || m)
  }
],
    meta: InstallInfoQQq9rqLFPjMeta || {},
    alias: InstallInfoQQq9rqLFPjMeta?.alias || [],
    redirect: InstallInfoQQq9rqLFPjMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/geo/InstallInfo.vue").then(m => m.default || m)
  },
  {
    name: InstruInfoynS5fcVoCnMeta?.name ?? "mobile-user-id-views-equipInfo-owner-geo-InstruInfo",
    path: InstruInfoynS5fcVoCnMeta?.path ?? "InstruInfo",
    children: [
  {
    name: chartpClEYdicHEMeta?.name ?? "mobile-user-id-views-equipInfo-owner-geo-InstruInfo-chart",
    path: chartpClEYdicHEMeta?.path ?? "chart",
    meta: chartpClEYdicHEMeta || {},
    alias: chartpClEYdicHEMeta?.alias || [],
    redirect: chartpClEYdicHEMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/geo/InstruInfo/chart.vue").then(m => m.default || m)
  },
  {
    name: nowQUMev6Oj93Meta?.name ?? "mobile-user-id-views-equipInfo-owner-geo-InstruInfo-now",
    path: nowQUMev6Oj93Meta?.path ?? "now",
    meta: nowQUMev6Oj93Meta || {},
    alias: nowQUMev6Oj93Meta?.alias || [],
    redirect: nowQUMev6Oj93Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/geo/InstruInfo/now.vue").then(m => m.default || m)
  }
],
    meta: InstruInfoynS5fcVoCnMeta || {},
    alias: InstruInfoynS5fcVoCnMeta?.alias || [],
    redirect: InstruInfoynS5fcVoCnMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/geo/InstruInfo.vue").then(m => m.default || m)
  },
  {
    name: index1u2k6NqXpIMeta?.name ?? "mobile-user-id-views-equipInfo-owner-geo-SpecInfo",
    path: index1u2k6NqXpIMeta?.path ?? "SpecInfo",
    meta: index1u2k6NqXpIMeta || {},
    alias: index1u2k6NqXpIMeta?.alias || [],
    redirect: index1u2k6NqXpIMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/geo/SpecInfo/index.vue").then(m => m.default || m)
  },
  {
    name: rtuBkB18acIEqMeta?.name ?? "mobile-user-id-views-equipInfo-owner-geo-SpecInfo-rtu",
    path: rtuBkB18acIEqMeta?.path ?? "SpecInfo/rtu",
    meta: rtuBkB18acIEqMeta || {},
    alias: rtuBkB18acIEqMeta?.alias || [],
    redirect: rtuBkB18acIEqMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/geo/SpecInfo/rtu.vue").then(m => m.default || m)
  },
  {
    name: specaW7rxTO8vhMeta?.name ?? "mobile-user-id-views-equipInfo-owner-geo-SpecInfo-spec",
    path: specaW7rxTO8vhMeta?.path ?? "SpecInfo/spec",
    meta: specaW7rxTO8vhMeta || {},
    alias: specaW7rxTO8vhMeta?.alias || [],
    redirect: specaW7rxTO8vhMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/geo/SpecInfo/spec.vue").then(m => m.default || m)
  }
],
    meta: geoACVLKdl9j8Meta || {},
    alias: geoACVLKdl9j8Meta?.alias || [],
    redirect: geoACVLKdl9j8Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/geo.vue").then(m => m.default || m)
  },
  {
    name: pvXq8cF0IdzXMeta?.name ?? "mobile-user-id-views-equipInfo-owner-pv",
    path: pvXq8cF0IdzXMeta?.path ?? "owner/pv",
    children: [
  {
    name: InstallInfoO3E87qNrxsMeta?.name ?? "mobile-user-id-views-equipInfo-owner-pv-InstallInfo",
    path: InstallInfoO3E87qNrxsMeta?.path ?? "InstallInfo",
    children: [
  {
    name: installXAsmaSH0m5Meta?.name ?? "mobile-user-id-views-equipInfo-owner-pv-InstallInfo-install",
    path: installXAsmaSH0m5Meta?.path ?? "install",
    meta: installXAsmaSH0m5Meta || {},
    alias: installXAsmaSH0m5Meta?.alias || [],
    redirect: installXAsmaSH0m5Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/pv/InstallInfo/install.vue").then(m => m.default || m)
  },
  {
    name: manager29pwgYDiRcMeta?.name ?? "mobile-user-id-views-equipInfo-owner-pv-InstallInfo-manager",
    path: manager29pwgYDiRcMeta?.path ?? "manager",
    meta: manager29pwgYDiRcMeta || {},
    alias: manager29pwgYDiRcMeta?.alias || [],
    redirect: manager29pwgYDiRcMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/pv/InstallInfo/manager.vue").then(m => m.default || m)
  }
],
    meta: InstallInfoO3E87qNrxsMeta || {},
    alias: InstallInfoO3E87qNrxsMeta?.alias || [],
    redirect: InstallInfoO3E87qNrxsMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/pv/InstallInfo.vue").then(m => m.default || m)
  },
  {
    name: InstruInfo2HGUOsJKBoMeta?.name ?? "mobile-user-id-views-equipInfo-owner-pv-InstruInfo",
    path: InstruInfo2HGUOsJKBoMeta?.path ?? "InstruInfo",
    children: [
  {
    name: chartqn8N4nQAHAMeta?.name ?? "mobile-user-id-views-equipInfo-owner-pv-InstruInfo-chart",
    path: chartqn8N4nQAHAMeta?.path ?? "chart",
    meta: chartqn8N4nQAHAMeta || {},
    alias: chartqn8N4nQAHAMeta?.alias || [],
    redirect: chartqn8N4nQAHAMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/pv/InstruInfo/chart.vue").then(m => m.default || m)
  },
  {
    name: now8rYEKgt9KvMeta?.name ?? "mobile-user-id-views-equipInfo-owner-pv-InstruInfo-now",
    path: now8rYEKgt9KvMeta?.path ?? "now",
    meta: now8rYEKgt9KvMeta || {},
    alias: now8rYEKgt9KvMeta?.alias || [],
    redirect: now8rYEKgt9KvMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/pv/InstruInfo/now.vue").then(m => m.default || m)
  }
],
    meta: InstruInfo2HGUOsJKBoMeta || {},
    alias: InstruInfo2HGUOsJKBoMeta?.alias || [],
    redirect: InstruInfo2HGUOsJKBoMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/pv/InstruInfo.vue").then(m => m.default || m)
  },
  {
    name: indexjWiIRMCSiEMeta?.name ?? "mobile-user-id-views-equipInfo-owner-pv-SpecInfo",
    path: indexjWiIRMCSiEMeta?.path ?? "SpecInfo",
    meta: indexjWiIRMCSiEMeta || {},
    alias: indexjWiIRMCSiEMeta?.alias || [],
    redirect: indexjWiIRMCSiEMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/pv/SpecInfo/index.vue").then(m => m.default || m)
  },
  {
    name: rtuXt4TQxsKd8Meta?.name ?? "mobile-user-id-views-equipInfo-owner-pv-SpecInfo-rtu",
    path: rtuXt4TQxsKd8Meta?.path ?? "SpecInfo/rtu",
    meta: rtuXt4TQxsKd8Meta || {},
    alias: rtuXt4TQxsKd8Meta?.alias || [],
    redirect: rtuXt4TQxsKd8Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/pv/SpecInfo/rtu.vue").then(m => m.default || m)
  },
  {
    name: specatMk57ehHCMeta?.name ?? "mobile-user-id-views-equipInfo-owner-pv-SpecInfo-spec",
    path: specatMk57ehHCMeta?.path ?? "SpecInfo/spec",
    meta: specatMk57ehHCMeta || {},
    alias: specatMk57ehHCMeta?.alias || [],
    redirect: specatMk57ehHCMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/pv/SpecInfo/spec.vue").then(m => m.default || m)
  }
],
    meta: pvXq8cF0IdzXMeta || {},
    alias: pvXq8cF0IdzXMeta?.alias || [],
    redirect: pvXq8cF0IdzXMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/pv.vue").then(m => m.default || m)
  },
  {
    name: solarheatsvc5xawMYeMeta?.name ?? "mobile-user-id-views-equipInfo-owner-solarheat",
    path: solarheatsvc5xawMYeMeta?.path ?? "owner/solarheat",
    children: [
  {
    name: InstallInfoAJqUWVuZjrMeta?.name ?? "mobile-user-id-views-equipInfo-owner-solarheat-InstallInfo",
    path: InstallInfoAJqUWVuZjrMeta?.path ?? "InstallInfo",
    children: [
  {
    name: installGDGaXhjxKlMeta?.name ?? "mobile-user-id-views-equipInfo-owner-solarheat-InstallInfo-install",
    path: installGDGaXhjxKlMeta?.path ?? "install",
    meta: installGDGaXhjxKlMeta || {},
    alias: installGDGaXhjxKlMeta?.alias || [],
    redirect: installGDGaXhjxKlMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/solarheat/InstallInfo/install.vue").then(m => m.default || m)
  },
  {
    name: managerK95WQd1JzEMeta?.name ?? "mobile-user-id-views-equipInfo-owner-solarheat-InstallInfo-manager",
    path: managerK95WQd1JzEMeta?.path ?? "manager",
    meta: managerK95WQd1JzEMeta || {},
    alias: managerK95WQd1JzEMeta?.alias || [],
    redirect: managerK95WQd1JzEMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/solarheat/InstallInfo/manager.vue").then(m => m.default || m)
  }
],
    meta: InstallInfoAJqUWVuZjrMeta || {},
    alias: InstallInfoAJqUWVuZjrMeta?.alias || [],
    redirect: InstallInfoAJqUWVuZjrMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/solarheat/InstallInfo.vue").then(m => m.default || m)
  },
  {
    path: InstruInfoS8Lpr4IaxzMeta?.path ?? "InstruInfo",
    children: [
  {
    name: chartVHxh0kHC9TMeta?.name ?? "mobile-user-id-views-equipInfo-owner-solarheat-InstruInfo-chart",
    path: chartVHxh0kHC9TMeta?.path ?? "chart",
    meta: chartVHxh0kHC9TMeta || {},
    alias: chartVHxh0kHC9TMeta?.alias || [],
    redirect: chartVHxh0kHC9TMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/solarheat/InstruInfo/chart.vue").then(m => m.default || m)
  },
  {
    name: indexVaNSDjdpkyMeta?.name ?? "mobile-user-id-views-equipInfo-owner-solarheat-InstruInfo",
    path: indexVaNSDjdpkyMeta?.path ?? "",
    meta: indexVaNSDjdpkyMeta || {},
    alias: indexVaNSDjdpkyMeta?.alias || [],
    redirect: indexVaNSDjdpkyMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/solarheat/InstruInfo/index.vue").then(m => m.default || m)
  },
  {
    name: now7zZaxTVzK0Meta?.name ?? "mobile-user-id-views-equipInfo-owner-solarheat-InstruInfo-now",
    path: now7zZaxTVzK0Meta?.path ?? "now",
    meta: now7zZaxTVzK0Meta || {},
    alias: now7zZaxTVzK0Meta?.alias || [],
    redirect: now7zZaxTVzK0Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/solarheat/InstruInfo/now.vue").then(m => m.default || m)
  }
],
    name: InstruInfoS8Lpr4IaxzMeta?.name ?? undefined,
    meta: InstruInfoS8Lpr4IaxzMeta || {},
    alias: InstruInfoS8Lpr4IaxzMeta?.alias || [],
    redirect: InstruInfoS8Lpr4IaxzMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/solarheat/InstruInfo.vue").then(m => m.default || m)
  },
  {
    name: indexfhhBWKoKWtMeta?.name ?? "mobile-user-id-views-equipInfo-owner-solarheat-SpecInfo",
    path: indexfhhBWKoKWtMeta?.path ?? "SpecInfo",
    meta: indexfhhBWKoKWtMeta || {},
    alias: indexfhhBWKoKWtMeta?.alias || [],
    redirect: indexfhhBWKoKWtMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/solarheat/SpecInfo/index.vue").then(m => m.default || m)
  },
  {
    name: rtuQ9ctz6P1nCMeta?.name ?? "mobile-user-id-views-equipInfo-owner-solarheat-SpecInfo-rtu",
    path: rtuQ9ctz6P1nCMeta?.path ?? "SpecInfo/rtu",
    meta: rtuQ9ctz6P1nCMeta || {},
    alias: rtuQ9ctz6P1nCMeta?.alias || [],
    redirect: rtuQ9ctz6P1nCMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/solarheat/SpecInfo/rtu.vue").then(m => m.default || m)
  },
  {
    name: specJPatVHHEAEMeta?.name ?? "mobile-user-id-views-equipInfo-owner-solarheat-SpecInfo-spec",
    path: specJPatVHHEAEMeta?.path ?? "SpecInfo/spec",
    meta: specJPatVHHEAEMeta || {},
    alias: specJPatVHHEAEMeta?.alias || [],
    redirect: specJPatVHHEAEMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/solarheat/SpecInfo/spec.vue").then(m => m.default || m)
  }
],
    meta: solarheatsvc5xawMYeMeta || {},
    alias: solarheatsvc5xawMYeMeta?.alias || [],
    redirect: solarheatsvc5xawMYeMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/solarheat.vue").then(m => m.default || m)
  },
  {
    path: wftUfD7kTlkTMeta?.path ?? "owner/wf",
    children: [
  {
    path: InstallInfoLlRnvqh3m4Meta?.path ?? "InstallInfo",
    children: [
  {
    name: indexfqE5OcGNJpMeta?.name ?? "mobile-user-id-views-equipInfo-owner-wf-InstallInfo",
    path: indexfqE5OcGNJpMeta?.path ?? "",
    meta: indexfqE5OcGNJpMeta || {},
    alias: indexfqE5OcGNJpMeta?.alias || [],
    redirect: indexfqE5OcGNJpMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/wf/InstallInfo/index.vue").then(m => m.default || m)
  }
],
    name: InstallInfoLlRnvqh3m4Meta?.name ?? undefined,
    meta: InstallInfoLlRnvqh3m4Meta || {},
    alias: InstallInfoLlRnvqh3m4Meta?.alias || [],
    redirect: InstallInfoLlRnvqh3m4Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/wf/InstallInfo.vue").then(m => m.default || m)
  },
  {
    name: InstruInfos2PWv3AVIfMeta?.name ?? "mobile-user-id-views-equipInfo-owner-wf-InstruInfo",
    path: InstruInfos2PWv3AVIfMeta?.path ?? "InstruInfo",
    children: [
  {
    name: chartC1ko7vz2DAMeta?.name ?? "mobile-user-id-views-equipInfo-owner-wf-InstruInfo-chart",
    path: chartC1ko7vz2DAMeta?.path ?? "chart",
    meta: chartC1ko7vz2DAMeta || {},
    alias: chartC1ko7vz2DAMeta?.alias || [],
    redirect: chartC1ko7vz2DAMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/wf/InstruInfo/chart.vue").then(m => m.default || m)
  },
  {
    name: nowWfqNyBAxSzMeta?.name ?? "mobile-user-id-views-equipInfo-owner-wf-InstruInfo-now",
    path: nowWfqNyBAxSzMeta?.path ?? "now",
    meta: nowWfqNyBAxSzMeta || {},
    alias: nowWfqNyBAxSzMeta?.alias || [],
    redirect: nowWfqNyBAxSzMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/wf/InstruInfo/now.vue").then(m => m.default || m)
  }
],
    meta: InstruInfos2PWv3AVIfMeta || {},
    alias: InstruInfos2PWv3AVIfMeta?.alias || [],
    redirect: InstruInfos2PWv3AVIfMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/wf/InstruInfo.vue").then(m => m.default || m)
  },
  {
    name: indexSf8IbHBRkrMeta?.name ?? "mobile-user-id-views-equipInfo-owner-wf",
    path: indexSf8IbHBRkrMeta?.path ?? "",
    meta: indexSf8IbHBRkrMeta || {},
    alias: indexSf8IbHBRkrMeta?.alias || [],
    redirect: indexSf8IbHBRkrMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/wf/index.vue").then(m => m.default || m)
  }
],
    name: wftUfD7kTlkTMeta?.name ?? undefined,
    meta: wftUfD7kTlkTMeta || {},
    alias: wftUfD7kTlkTMeta?.alias || [],
    redirect: wftUfD7kTlkTMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/wf.vue").then(m => m.default || m)
  }
],
    meta: equipInfop6unFGIDNNMeta || {},
    alias: equipInfop6unFGIDNNMeta?.alias || [],
    redirect: equipInfop6unFGIDNNMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo.vue").then(m => m.default || m)
  },
  {
    name: eventZYjB9y1OEmMeta?.name ?? "mobile-user-id-views-event",
    path: eventZYjB9y1OEmMeta?.path ?? "event",
    children: [
  {
    name: ALLu7dO1uM5v5Meta?.name ?? "mobile-user-id-views-event-ALL",
    path: ALLu7dO1uM5v5Meta?.path ?? "ALL",
    meta: ALLu7dO1uM5v5Meta || {},
    alias: ALLu7dO1uM5v5Meta?.alias || [],
    redirect: ALLu7dO1uM5v5Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/event/ALL.vue").then(m => m.default || m)
  },
  {
    name: FailovermqO2SRw4dyMeta?.name ?? "mobile-user-id-views-event-Failover",
    path: FailovermqO2SRw4dyMeta?.path ?? "Failover",
    meta: FailovermqO2SRw4dyMeta || {},
    alias: FailovermqO2SRw4dyMeta?.alias || [],
    redirect: FailovermqO2SRw4dyMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/event/Failover.vue").then(m => m.default || m)
  },
  {
    name: FailureVzEaMndvqNMeta?.name ?? "mobile-user-id-views-event-Failure",
    path: FailureVzEaMndvqNMeta?.path ?? "Failure",
    meta: FailureVzEaMndvqNMeta || {},
    alias: FailureVzEaMndvqNMeta?.alias || [],
    redirect: FailureVzEaMndvqNMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/event/Failure.vue").then(m => m.default || m)
  },
  {
    name: allqeXI7UQ8ORMeta?.name ?? "mobile-user-id-views-event-all",
    path: allqeXI7UQ8ORMeta?.path ?? "all",
    meta: allqeXI7UQ8ORMeta || {},
    alias: allqeXI7UQ8ORMeta?.alias || [],
    redirect: allqeXI7UQ8ORMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/event/all.vue").then(m => m.default || m)
  },
  {
    name: eventHistoryxsRxLQJqz1Meta?.name ?? "mobile-user-id-views-event-eventHistory",
    path: eventHistoryxsRxLQJqz1Meta?.path ?? "eventHistory",
    meta: eventHistoryxsRxLQJqz1Meta || {},
    alias: eventHistoryxsRxLQJqz1Meta?.alias || [],
    redirect: eventHistoryxsRxLQJqz1Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/event/eventHistory.vue").then(m => m.default || m)
  },
  {
    name: rtuEventuXS5bYUlGHMeta?.name ?? "mobile-user-id-views-event-rtuEvent",
    path: rtuEventuXS5bYUlGHMeta?.path ?? "rtuEvent",
    meta: rtuEventuXS5bYUlGHMeta || {},
    alias: rtuEventuXS5bYUlGHMeta?.alias || [],
    redirect: rtuEventuXS5bYUlGHMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/event/rtuEvent.vue").then(m => m.default || m)
  }
],
    meta: eventZYjB9y1OEmMeta || {},
    alias: eventZYjB9y1OEmMeta?.alias || [],
    redirect: eventZYjB9y1OEmMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/event.vue").then(m => m.default || m)
  },
  {
    name: monitoringfJCRblZggHMeta?.name ?? "mobile-user-id-views-monitoring",
    path: monitoringfJCRblZggHMeta?.path ?? "monitoring",
    children: [
  {
    name: ess0K6WGsTYKZMeta?.name ?? "mobile-user-id-views-monitoring-ess",
    path: ess0K6WGsTYKZMeta?.path ?? "ess",
    meta: ess0K6WGsTYKZMeta || {},
    alias: ess0K6WGsTYKZMeta?.alias || [],
    redirect: ess0K6WGsTYKZMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/monitoring/ess.vue").then(m => m.default || m)
  },
  {
    name: fuelcelloOHDWlYY6gMeta?.name ?? "mobile-user-id-views-monitoring-fuelcell",
    path: fuelcelloOHDWlYY6gMeta?.path ?? "fuelcell",
    meta: fuelcelloOHDWlYY6gMeta || {},
    alias: fuelcelloOHDWlYY6gMeta?.alias || [],
    redirect: fuelcelloOHDWlYY6gMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/monitoring/fuelcell.vue").then(m => m.default || m)
  },
  {
    name: geoejr1bKYQH7Meta?.name ?? "mobile-user-id-views-monitoring-geo",
    path: geoejr1bKYQH7Meta?.path ?? "geo",
    meta: geoejr1bKYQH7Meta || {},
    alias: geoejr1bKYQH7Meta?.alias || [],
    redirect: geoejr1bKYQH7Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/monitoring/geo.vue").then(m => m.default || m)
  },
  {
    name: pvTzCnAIS4I0Meta?.name ?? "mobile-user-id-views-monitoring-pv",
    path: pvTzCnAIS4I0Meta?.path ?? "pv",
    meta: pvTzCnAIS4I0Meta || {},
    alias: pvTzCnAIS4I0Meta?.alias || [],
    redirect: pvTzCnAIS4I0Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/monitoring/pv.vue").then(m => m.default || m)
  },
  {
    name: solarheatXrW8ptSfE4Meta?.name ?? "mobile-user-id-views-monitoring-solarheat",
    path: solarheatXrW8ptSfE4Meta?.path ?? "solarheat",
    meta: solarheatXrW8ptSfE4Meta || {},
    alias: solarheatXrW8ptSfE4Meta?.alias || [],
    redirect: solarheatXrW8ptSfE4Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/monitoring/solarheat.vue").then(m => m.default || m)
  },
  {
    name: wfdkk38g8aSjMeta?.name ?? "mobile-user-id-views-monitoring-wf",
    path: wfdkk38g8aSjMeta?.path ?? "wf",
    meta: wfdkk38g8aSjMeta || {},
    alias: wfdkk38g8aSjMeta?.alias || [],
    redirect: wfdkk38g8aSjMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/monitoring/wf.vue").then(m => m.default || m)
  }
],
    meta: monitoringfJCRblZggHMeta || {},
    alias: monitoringfJCRblZggHMeta?.alias || [],
    redirect: monitoringfJCRblZggHMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/monitoring.vue").then(m => m.default || m)
  },
  {
    name: monitoring_testoGA2T3GNvYMeta?.name ?? "mobile-user-id-views-monitoring_test",
    path: monitoring_testoGA2T3GNvYMeta?.path ?? "monitoring_test",
    meta: monitoring_testoGA2T3GNvYMeta || {},
    alias: monitoring_testoGA2T3GNvYMeta?.alias || [],
    redirect: monitoring_testoGA2T3GNvYMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/monitoring_test.vue").then(m => m.default || m)
  },
  {
    name: moredoa7Key6HsMeta?.name ?? "mobile-user-id-views-more",
    path: moredoa7Key6HsMeta?.path ?? "more",
    children: [
  {
    name: InstallInfo5xG53toQbSMeta?.name ?? "mobile-user-id-views-more-InstallInfo",
    path: InstallInfo5xG53toQbSMeta?.path ?? "InstallInfo",
    meta: InstallInfo5xG53toQbSMeta || {},
    alias: InstallInfo5xG53toQbSMeta?.alias || [],
    redirect: InstallInfo5xG53toQbSMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/more/InstallInfo.vue").then(m => m.default || m)
  },
  {
    name: contractsvXwHDmgr4wMeta?.name ?? "mobile-user-id-views-more-contracts",
    path: contractsvXwHDmgr4wMeta?.path ?? "contracts",
    meta: contractsvXwHDmgr4wMeta || {},
    alias: contractsvXwHDmgr4wMeta?.alias || [],
    redirect: contractsvXwHDmgr4wMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/more/contracts.vue").then(m => m.default || m)
  },
  {
    name: indicatorsSPVo85TqfzMeta?.name ?? "mobile-user-id-views-more-indicators",
    path: indicatorsSPVo85TqfzMeta?.path ?? "indicators",
    children: [
  {
    name: geo2nSiiTUXbtMeta?.name ?? "mobile-user-id-views-more-indicators-geo",
    path: geo2nSiiTUXbtMeta?.path ?? "geo",
    meta: geo2nSiiTUXbtMeta || {},
    alias: geo2nSiiTUXbtMeta?.alias || [],
    redirect: geo2nSiiTUXbtMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/more/indicators/geo.vue").then(m => m.default || m)
  },
  {
    name: pvQT23VTmyMEMeta?.name ?? "mobile-user-id-views-more-indicators-pv",
    path: pvQT23VTmyMEMeta?.path ?? "pv",
    meta: pvQT23VTmyMEMeta || {},
    alias: pvQT23VTmyMEMeta?.alias || [],
    redirect: pvQT23VTmyMEMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/more/indicators/pv.vue").then(m => m.default || m)
  },
  {
    name: solarheatAX58rgC79DMeta?.name ?? "mobile-user-id-views-more-indicators-solarheat",
    path: solarheatAX58rgC79DMeta?.path ?? "solarheat",
    meta: solarheatAX58rgC79DMeta || {},
    alias: solarheatAX58rgC79DMeta?.alias || [],
    redirect: solarheatAX58rgC79DMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/more/indicators/solarheat.vue").then(m => m.default || m)
  }
],
    meta: indicatorsSPVo85TqfzMeta || {},
    alias: indicatorsSPVo85TqfzMeta?.alias || [],
    redirect: indicatorsSPVo85TqfzMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/more/indicators.vue").then(m => m.default || m)
  },
  {
    name: menumtR0gclQYSMeta?.name ?? "mobile-user-id-views-more-menu",
    path: menumtR0gclQYSMeta?.path ?? "menu",
    meta: menumtR0gclQYSMeta || {},
    alias: menumtR0gclQYSMeta?.alias || [],
    redirect: menumtR0gclQYSMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/more/menu.vue").then(m => m.default || m)
  },
  {
    name: _91noticeid_93neDtEf4A78Meta?.name ?? "mobile-user-id-views-more-menuToNotice-noticeid",
    path: _91noticeid_93neDtEf4A78Meta?.path ?? "menuToNotice/:noticeid",
    meta: _91noticeid_93neDtEf4A78Meta || {},
    alias: _91noticeid_93neDtEf4A78Meta?.alias || [],
    redirect: _91noticeid_93neDtEf4A78Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/more/menuToNotice/[noticeid].vue").then(m => m.default || m)
  },
  {
    name: ownerInfoyFG3X710fPMeta?.name ?? "mobile-user-id-views-more-ownerInfo",
    path: ownerInfoyFG3X710fPMeta?.path ?? "ownerInfo",
    meta: ownerInfoyFG3X710fPMeta || {},
    alias: ownerInfoyFG3X710fPMeta?.alias || [],
    redirect: ownerInfoyFG3X710fPMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/more/ownerInfo.vue").then(m => m.default || m)
  },
  {
    name: predictedPowerEiERIVUP5GMeta?.name ?? "mobile-user-id-views-more-predictedPower",
    path: predictedPowerEiERIVUP5GMeta?.path ?? "predictedPower",
    meta: predictedPowerEiERIVUP5GMeta || {},
    alias: predictedPowerEiERIVUP5GMeta?.alias || [],
    redirect: predictedPowerEiERIVUP5GMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/more/predictedPower.vue").then(m => m.default || m)
  },
  {
    name: siteInfoZc1XpsD3DmMeta?.name ?? "mobile-user-id-views-more-siteInfo",
    path: siteInfoZc1XpsD3DmMeta?.path ?? "siteInfo",
    meta: siteInfoZc1XpsD3DmMeta || {},
    alias: siteInfoZc1XpsD3DmMeta?.alias || [],
    redirect: siteInfoZc1XpsD3DmMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/more/siteInfo.vue").then(m => m.default || m)
  }
],
    meta: moredoa7Key6HsMeta || {},
    alias: moredoa7Key6HsMeta?.alias || [],
    redirect: moredoa7Key6HsMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/more.vue").then(m => m.default || m)
  },
  {
    name: powerGenerationVxuRMDPJvpMeta?.name ?? "mobile-user-id-views-powerGeneration",
    path: powerGenerationVxuRMDPJvpMeta?.path ?? "powerGeneration",
    children: [
  {
    name: essZTjpqvVx2MMeta?.name ?? "mobile-user-id-views-powerGeneration-ess",
    path: essZTjpqvVx2MMeta?.path ?? "ess",
    meta: essZTjpqvVx2MMeta || {},
    alias: essZTjpqvVx2MMeta?.alias || [],
    redirect: essZTjpqvVx2MMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/powerGeneration/ess.vue").then(m => m.default || m)
  },
  {
    name: fuelcelliyX0DZ26xxMeta?.name ?? "mobile-user-id-views-powerGeneration-fuelcell",
    path: fuelcelliyX0DZ26xxMeta?.path ?? "fuelcell",
    meta: fuelcelliyX0DZ26xxMeta || {},
    alias: fuelcelliyX0DZ26xxMeta?.alias || [],
    redirect: fuelcelliyX0DZ26xxMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/powerGeneration/fuelcell.vue").then(m => m.default || m)
  },
  {
    name: geol9M1EpTfzgMeta?.name ?? "mobile-user-id-views-powerGeneration-geo",
    path: geol9M1EpTfzgMeta?.path ?? "geo",
    meta: geol9M1EpTfzgMeta || {},
    alias: geol9M1EpTfzgMeta?.alias || [],
    redirect: geol9M1EpTfzgMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/powerGeneration/geo.vue").then(m => m.default || m)
  },
  {
    name: pv4ps1S1K6mkMeta?.name ?? "mobile-user-id-views-powerGeneration-pv",
    path: pv4ps1S1K6mkMeta?.path ?? "pv",
    meta: pv4ps1S1K6mkMeta || {},
    alias: pv4ps1S1K6mkMeta?.alias || [],
    redirect: pv4ps1S1K6mkMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/powerGeneration/pv.vue").then(m => m.default || m)
  },
  {
    name: solarheat3xgUUARzfKMeta?.name ?? "mobile-user-id-views-powerGeneration-solarheat",
    path: solarheat3xgUUARzfKMeta?.path ?? "solarheat",
    meta: solarheat3xgUUARzfKMeta || {},
    alias: solarheat3xgUUARzfKMeta?.alias || [],
    redirect: solarheat3xgUUARzfKMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/powerGeneration/solarheat.vue").then(m => m.default || m)
  },
  {
    name: wfC0Mnzg2dnQMeta?.name ?? "mobile-user-id-views-powerGeneration-wf",
    path: wfC0Mnzg2dnQMeta?.path ?? "wf",
    meta: wfC0Mnzg2dnQMeta || {},
    alias: wfC0Mnzg2dnQMeta?.alias || [],
    redirect: wfC0Mnzg2dnQMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/powerGeneration/wf.vue").then(m => m.default || m)
  }
],
    meta: powerGenerationVxuRMDPJvpMeta || {},
    alias: powerGenerationVxuRMDPJvpMeta?.alias || [],
    redirect: powerGenerationVxuRMDPJvpMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/powerGeneration.vue").then(m => m.default || m)
  },
  {
    name: reportzogMv1NICkMeta?.name ?? "mobile-user-id-views-report",
    path: reportzogMv1NICkMeta?.path ?? "report",
    children: [
  {
    name: geomP23KGLbYQMeta?.name ?? "mobile-user-id-views-report-geo",
    path: geomP23KGLbYQMeta?.path ?? "geo",
    meta: geomP23KGLbYQMeta || {},
    alias: geomP23KGLbYQMeta?.alias || [],
    redirect: geomP23KGLbYQMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/report/geo.vue").then(m => m.default || m)
  },
  {
    name: pvJ28ijdDTmKMeta?.name ?? "mobile-user-id-views-report-pv",
    path: pvJ28ijdDTmKMeta?.path ?? "pv",
    meta: pvJ28ijdDTmKMeta || {},
    alias: pvJ28ijdDTmKMeta?.alias || [],
    redirect: pvJ28ijdDTmKMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/report/pv.vue").then(m => m.default || m)
  },
  {
    name: solarheatRPNGrvuEkAMeta?.name ?? "mobile-user-id-views-report-solarheat",
    path: solarheatRPNGrvuEkAMeta?.path ?? "solarheat",
    meta: solarheatRPNGrvuEkAMeta || {},
    alias: solarheatRPNGrvuEkAMeta?.alias || [],
    redirect: solarheatRPNGrvuEkAMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/report/solarheat.vue").then(m => m.default || m)
  }
],
    meta: reportzogMv1NICkMeta || {},
    alias: reportzogMv1NICkMeta?.alias || [],
    redirect: reportzogMv1NICkMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/report.vue").then(m => m.default || m)
  },
  {
    name: reportPDFR9VThMOrXfMeta?.name ?? "mobile-user-id-views-reportPDF",
    path: reportPDFR9VThMOrXfMeta?.path ?? "reportPDF",
    children: [
  {
    name: pvN0AV3rSZ9wMeta?.name ?? "mobile-user-id-views-reportPDF-pv",
    path: pvN0AV3rSZ9wMeta?.path ?? "pv",
    meta: pvN0AV3rSZ9wMeta || {},
    alias: pvN0AV3rSZ9wMeta?.alias || [],
    redirect: pvN0AV3rSZ9wMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/reportPDF/pv.vue").then(m => m.default || m)
  }
],
    meta: reportPDFR9VThMOrXfMeta || {},
    alias: reportPDFR9VThMOrXfMeta?.alias || [],
    redirect: reportPDFR9VThMOrXfMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/reportPDF.vue").then(m => m.default || m)
  },
  {
    name: statuszQjnNSqO4CMeta?.name ?? "mobile-user-id-views-status",
    path: statuszQjnNSqO4CMeta?.path ?? "status",
    meta: statuszQjnNSqO4CMeta || {},
    alias: statuszQjnNSqO4CMeta?.alias || [],
    redirect: statuszQjnNSqO4CMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/status.vue").then(m => m.default || m)
  }
],
    meta: viewszZfu2bhX8UMeta || {},
    alias: viewszZfu2bhX8UMeta?.alias || [],
    redirect: viewszZfu2bhX8UMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id]/views.vue").then(m => m.default || m)
  }
],
    name: _91id_930CYvOJWwJoMeta?.name ?? undefined,
    meta: _91id_930CYvOJWwJoMeta || {},
    alias: _91id_930CYvOJWwJoMeta?.alias || [],
    redirect: _91id_930CYvOJWwJoMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/[id].vue").then(m => m.default || m)
  },
  {
    name: finduserhBjxOet1EtMeta?.name ?? "mobile-user-finduser",
    path: finduserhBjxOet1EtMeta?.path ?? "finduser",
    children: [
  {
    name: doneFindidNnVkseXuqtMeta?.name ?? "mobile-user-finduser-doneFindid",
    path: doneFindidNnVkseXuqtMeta?.path ?? "doneFindid",
    meta: doneFindidNnVkseXuqtMeta || {},
    alias: doneFindidNnVkseXuqtMeta?.alias || [],
    redirect: doneFindidNnVkseXuqtMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/finduser/doneFindid.vue").then(m => m.default || m)
  },
  {
    name: doneFindpassxMSYYgDZGpMeta?.name ?? "mobile-user-finduser-doneFindpass",
    path: doneFindpassxMSYYgDZGpMeta?.path ?? "doneFindpass",
    meta: doneFindpassxMSYYgDZGpMeta || {},
    alias: doneFindpassxMSYYgDZGpMeta?.alias || [],
    redirect: doneFindpassxMSYYgDZGpMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/finduser/doneFindpass.vue").then(m => m.default || m)
  },
  {
    name: findido1LYxyggmVMeta?.name ?? "mobile-user-finduser-findid",
    path: findido1LYxyggmVMeta?.path ?? "findid",
    meta: findido1LYxyggmVMeta || {},
    alias: findido1LYxyggmVMeta?.alias || [],
    redirect: findido1LYxyggmVMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/finduser/findid.vue").then(m => m.default || m)
  },
  {
    name: findpass2nLiUc4Hu2Meta?.name ?? "mobile-user-finduser-findpass",
    path: findpass2nLiUc4Hu2Meta?.path ?? "findpass",
    meta: findpass2nLiUc4Hu2Meta || {},
    alias: findpass2nLiUc4Hu2Meta?.alias || [],
    redirect: findpass2nLiUc4Hu2Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/finduser/findpass.vue").then(m => m.default || m)
  }
],
    meta: finduserhBjxOet1EtMeta || {},
    alias: finduserhBjxOet1EtMeta?.alias || [],
    redirect: finduserhBjxOet1EtMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/finduser.vue").then(m => m.default || m)
  },
  {
    name: introJ3StANugcBMeta?.name ?? "mobile-user-intro",
    path: introJ3StANugcBMeta?.path ?? "intro",
    meta: introJ3StANugcBMeta || {},
    alias: introJ3StANugcBMeta?.alias || [],
    redirect: introJ3StANugcBMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/intro.vue").then(m => m.default || m)
  },
  {
    name: loginTfF3kkeMmhMeta?.name ?? "mobile-user-login",
    path: loginTfF3kkeMmhMeta?.path ?? "login",
    meta: loginTfF3kkeMmhMeta || {},
    alias: loginTfF3kkeMmhMeta?.alias || [],
    redirect: loginTfF3kkeMmhMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/login.vue").then(m => m.default || m)
  },
  {
    name: logoutpztEXOFJn5Meta?.name ?? "mobile-user-logout",
    path: logoutpztEXOFJn5Meta?.path ?? "logout",
    meta: logoutpztEXOFJn5Meta || {},
    alias: logoutpztEXOFJn5Meta?.alias || [],
    redirect: logoutpztEXOFJn5Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/logout.vue").then(m => m.default || m)
  },
  {
    name: signinHAY08cfYvTMeta?.name ?? "mobile-user-signin",
    path: signinHAY08cfYvTMeta?.path ?? "signin",
    children: [
  {
    name: personTerms3r9yzuxHatMeta?.name ?? "mobile-user-signin-personTerms",
    path: personTerms3r9yzuxHatMeta?.path ?? "personTerms",
    meta: personTerms3r9yzuxHatMeta || {},
    alias: personTerms3r9yzuxHatMeta?.alias || [],
    redirect: personTerms3r9yzuxHatMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/signin/personTerms.vue").then(m => m.default || m)
  },
  {
    name: termZIt646mqMNMeta?.name ?? "mobile-user-signin-term",
    path: termZIt646mqMNMeta?.path ?? "term",
    meta: termZIt646mqMNMeta || {},
    alias: termZIt646mqMNMeta?.alias || [],
    redirect: termZIt646mqMNMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/signin/term.vue").then(m => m.default || m)
  },
  {
    name: useTermsYaTd44uiMNMeta?.name ?? "mobile-user-signin-useTerms",
    path: useTermsYaTd44uiMNMeta?.path ?? "useTerms",
    meta: useTermsYaTd44uiMNMeta || {},
    alias: useTermsYaTd44uiMNMeta?.alias || [],
    redirect: useTermsYaTd44uiMNMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/signin/useTerms.vue").then(m => m.default || m)
  }
],
    meta: signinHAY08cfYvTMeta || {},
    alias: signinHAY08cfYvTMeta?.alias || [],
    redirect: signinHAY08cfYvTMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user/signin.vue").then(m => m.default || m)
  }
],
    meta: user4XOLFTNwokMeta || {},
    alias: user4XOLFTNwokMeta?.alias || [],
    redirect: user4XOLFTNwokMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile/user.vue").then(m => m.default || m)
  }
],
    meta: mobile74LE9JJGs8Meta || {},
    alias: mobile74LE9JJGs8Meta?.alias || [],
    redirect: mobile74LE9JJGs8Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/mobile.vue").then(m => m.default || m)
  },
  {
    name: swagger_45uiUCIRVgJzccMeta?.name ?? "swagger-ui",
    path: swagger_45uiUCIRVgJzccMeta?.path ?? "/swagger-ui",
    meta: swagger_45uiUCIRVgJzccMeta || {},
    alias: swagger_45uiUCIRVgJzccMeta?.alias || [],
    redirect: swagger_45uiUCIRVgJzccMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/swagger-ui.vue").then(m => m.default || m)
  },
  {
    name: loginEK1JofeAwpMeta?.name ?? "test-login",
    path: loginEK1JofeAwpMeta?.path ?? "/test/login",
    meta: loginEK1JofeAwpMeta || {},
    alias: loginEK1JofeAwpMeta?.alias || [],
    redirect: loginEK1JofeAwpMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/test/login.vue").then(m => m.default || m)
  },
  {
    name: mainGHbG9m7iZSMeta?.name ?? "test-main",
    path: mainGHbG9m7iZSMeta?.path ?? "/test/main",
    meta: mainGHbG9m7iZSMeta || {},
    alias: mainGHbG9m7iZSMeta?.alias || [],
    redirect: mainGHbG9m7iZSMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/test/main.vue").then(m => m.default || m)
  },
  {
    name: tocqXcM9mTbhxMeta?.name ?? "toc",
    path: tocqXcM9mTbhxMeta?.path ?? "/toc",
    children: [
  {
    name: analysispyx5FCq05AMeta?.name ?? "toc-analysis",
    path: analysispyx5FCq05AMeta?.path ?? "analysis",
    children: [
  {
    name: compare_45ownerE2rO3qTO0MMeta?.name ?? "toc-analysis-compare-owner",
    path: compare_45ownerE2rO3qTO0MMeta?.path ?? "compare-owner",
    meta: compare_45ownerE2rO3qTO0MMeta || {},
    alias: compare_45ownerE2rO3qTO0MMeta?.alias || [],
    redirect: compare_45ownerE2rO3qTO0MMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/analysis/compare-owner.vue").then(m => m.default || m)
  },
  {
    name: compare_45regionUe0GVX5Q5qMeta?.name ?? "toc-analysis-compare-region",
    path: compare_45regionUe0GVX5Q5qMeta?.path ?? "compare-region",
    meta: compare_45regionUe0GVX5Q5qMeta || {},
    alias: compare_45regionUe0GVX5Q5qMeta?.alias || [],
    redirect: compare_45regionUe0GVX5Q5qMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/analysis/compare-region.vue").then(m => m.default || m)
  },
  {
    name: geoUzP8nGW6tRMeta?.name ?? "toc-analysis-component-geo",
    path: geoUzP8nGW6tRMeta?.path ?? "component/geo",
    meta: geoUzP8nGW6tRMeta || {},
    alias: geoUzP8nGW6tRMeta?.alias || [],
    redirect: geoUzP8nGW6tRMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/analysis/component/geo.vue").then(m => m.default || m)
  },
  {
    name: ownerSearchModalftWhLBhOqiMeta?.name ?? "toc-analysis-component-ownerSearchModal",
    path: ownerSearchModalftWhLBhOqiMeta?.path ?? "component/ownerSearchModal",
    meta: ownerSearchModalftWhLBhOqiMeta || {},
    alias: ownerSearchModalftWhLBhOqiMeta?.alias || [],
    redirect: ownerSearchModalftWhLBhOqiMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/analysis/component/ownerSearchModal.vue").then(m => m.default || m)
  },
  {
    name: pvtidVfGG70eMeta?.name ?? "toc-analysis-component-pv",
    path: pvtidVfGG70eMeta?.path ?? "component/pv",
    meta: pvtidVfGG70eMeta || {},
    alias: pvtidVfGG70eMeta?.alias || [],
    redirect: pvtidVfGG70eMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/analysis/component/pv.vue").then(m => m.default || m)
  },
  {
    name: solarheatU6IkJisJuDMeta?.name ?? "toc-analysis-component-solarheat",
    path: solarheatU6IkJisJuDMeta?.path ?? "component/solarheat",
    meta: solarheatU6IkJisJuDMeta || {},
    alias: solarheatU6IkJisJuDMeta?.alias || [],
    redirect: solarheatU6IkJisJuDMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/analysis/component/solarheat.vue").then(m => m.default || m)
  },
  {
    name: ownerC4qED7z5e1Meta?.name ?? "toc-analysis-owner",
    path: ownerC4qED7z5e1Meta?.path ?? "owner",
    meta: ownerC4qED7z5e1Meta || {},
    alias: ownerC4qED7z5e1Meta?.alias || [],
    redirect: ownerC4qED7z5e1Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/analysis/owner.vue").then(m => m.default || m)
  }
],
    meta: analysispyx5FCq05AMeta || {},
    alias: analysispyx5FCq05AMeta?.alias || [],
    redirect: analysispyx5FCq05AMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/analysis.vue").then(m => m.default || m)
  },
  {
    name: Energy_45ListTJ5oReIGnYMeta?.name ?? "toc-component-Dashboard-Energy-List",
    path: Energy_45ListTJ5oReIGnYMeta?.path ?? "component/Dashboard/Energy-List",
    meta: Energy_45ListTJ5oReIGnYMeta || {},
    alias: Energy_45ListTJ5oReIGnYMeta?.alias || [],
    redirect: Energy_45ListTJ5oReIGnYMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/component/Dashboard/Energy-List.vue").then(m => m.default || m)
  },
  {
    name: card_45baseS3wYldrMFzMeta?.name ?? "toc-component-Dashboard-card-base",
    path: card_45baseS3wYldrMFzMeta?.path ?? "component/Dashboard/card-base",
    meta: card_45baseS3wYldrMFzMeta || {},
    alias: card_45baseS3wYldrMFzMeta?.alias || [],
    redirect: card_45baseS3wYldrMFzMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/component/Dashboard/card-base.vue").then(m => m.default || m)
  },
  {
    name: energy_45cardaUApJJTye8Meta?.name ?? "toc-component-Dashboard-energy-card",
    path: energy_45cardaUApJJTye8Meta?.path ?? "component/Dashboard/energy-card",
    meta: energy_45cardaUApJJTye8Meta || {},
    alias: energy_45cardaUApJJTye8Meta?.alias || [],
    redirect: energy_45cardaUApJJTye8Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/component/Dashboard/energy-card.vue").then(m => m.default || m)
  },
  {
    name: energy_45card2ZAmZwTmR4KMeta?.name ?? "toc-component-Dashboard-energy-card2",
    path: energy_45card2ZAmZwTmR4KMeta?.path ?? "component/Dashboard/energy-card2",
    meta: energy_45card2ZAmZwTmR4KMeta || {},
    alias: energy_45card2ZAmZwTmR4KMeta?.alias || [],
    redirect: energy_45card2ZAmZwTmR4KMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/component/Dashboard/energy-card2.vue").then(m => m.default || m)
  },
  {
    name: info_45cardHtp38x1spFMeta?.name ?? "toc-component-Dashboard-info-card",
    path: info_45cardHtp38x1spFMeta?.path ?? "component/Dashboard/info-card",
    meta: info_45cardHtp38x1spFMeta || {},
    alias: info_45cardHtp38x1spFMeta?.alias || [],
    redirect: info_45cardHtp38x1spFMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/component/Dashboard/info-card.vue").then(m => m.default || m)
  },
  {
    name: link_45rate_45card1npbTYAXJtMeta?.name ?? "toc-component-Dashboard-link-rate-card",
    path: link_45rate_45card1npbTYAXJtMeta?.path ?? "component/Dashboard/link-rate-card",
    meta: link_45rate_45card1npbTYAXJtMeta || {},
    alias: link_45rate_45card1npbTYAXJtMeta?.alias || [],
    redirect: link_45rate_45card1npbTYAXJtMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/component/Dashboard/link-rate-card.vue").then(m => m.default || m)
  },
  {
    name: FileUploadnYtfPvAKmLMeta?.name ?? "toc-component-FileUpload",
    path: FileUploadnYtfPvAKmLMeta?.path ?? "component/FileUpload",
    meta: FileUploadnYtfPvAKmLMeta || {},
    alias: FileUploadnYtfPvAKmLMeta?.alias || [],
    redirect: FileUploadnYtfPvAKmLMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/component/FileUpload.vue").then(m => m.default || m)
  },
  {
    name: indexFLWSA9jgKnMeta?.name ?? "toc-component-InstallInfo",
    path: indexFLWSA9jgKnMeta?.path ?? "component/InstallInfo",
    meta: indexFLWSA9jgKnMeta || {},
    alias: indexFLWSA9jgKnMeta?.alias || [],
    redirect: indexFLWSA9jgKnMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/component/InstallInfo/index.vue").then(m => m.default || m)
  },
  {
    name: pvQ2eyayCf1zMeta?.name ?? "toc-component-InstallInfo-pv",
    path: pvQ2eyayCf1zMeta?.path ?? "component/InstallInfo/pv",
    meta: pvQ2eyayCf1zMeta || {},
    alias: pvQ2eyayCf1zMeta?.alias || [],
    redirect: pvQ2eyayCf1zMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/component/InstallInfo/pv.vue").then(m => m.default || m)
  },
  {
    name: solarheatPevRGrbwboMeta?.name ?? "toc-component-InstallInfo-solarheat",
    path: solarheatPevRGrbwboMeta?.path ?? "component/InstallInfo/solarheat",
    meta: solarheatPevRGrbwboMeta || {},
    alias: solarheatPevRGrbwboMeta?.alias || [],
    redirect: solarheatPevRGrbwboMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/component/InstallInfo/solarheat.vue").then(m => m.default || m)
  },
  {
    name: pvxsxNYUfgsBMeta?.name ?? "toc-component-InstruInfo-pv",
    path: pvxsxNYUfgsBMeta?.path ?? "component/InstruInfo/pv",
    meta: pvxsxNYUfgsBMeta || {},
    alias: pvxsxNYUfgsBMeta?.alias || [],
    redirect: pvxsxNYUfgsBMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/component/InstruInfo/pv.vue").then(m => m.default || m)
  },
  {
    name: indexkBLy8Z3aAyMeta?.name ?? "toc-component-ManagerInfo",
    path: indexkBLy8Z3aAyMeta?.path ?? "component/ManagerInfo",
    meta: indexkBLy8Z3aAyMeta || {},
    alias: indexkBLy8Z3aAyMeta?.alias || [],
    redirect: indexkBLy8Z3aAyMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/component/ManagerInfo/index.vue").then(m => m.default || m)
  },
  {
    name: pvVBuWwjmrzdMeta?.name ?? "toc-component-ManagerInfo-pv",
    path: pvVBuWwjmrzdMeta?.path ?? "component/ManagerInfo/pv",
    meta: pvVBuWwjmrzdMeta || {},
    alias: pvVBuWwjmrzdMeta?.alias || [],
    redirect: pvVBuWwjmrzdMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/component/ManagerInfo/pv.vue").then(m => m.default || m)
  },
  {
    name: solarheat74xR5uqxr5Meta?.name ?? "toc-component-ManagerInfo-solarheat",
    path: solarheat74xR5uqxr5Meta?.path ?? "component/ManagerInfo/solarheat",
    meta: solarheat74xR5uqxr5Meta || {},
    alias: solarheat74xR5uqxr5Meta?.alias || [],
    redirect: solarheat74xR5uqxr5Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/component/ManagerInfo/solarheat.vue").then(m => m.default || m)
  },
  {
    name: pvWIrh97Ho8mMeta?.name ?? "toc-component-OwnerInfo-ManagerInfo-pv",
    path: pvWIrh97Ho8mMeta?.path ?? "component/OwnerInfo/ManagerInfo/pv",
    meta: pvWIrh97Ho8mMeta || {},
    alias: pvWIrh97Ho8mMeta?.alias || [],
    redirect: pvWIrh97Ho8mMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/component/OwnerInfo/ManagerInfo/pv.vue").then(m => m.default || m)
  },
  {
    name: solarheatFx9PfYY4YjMeta?.name ?? "toc-component-OwnerInfo-ManagerInfo-solarheat",
    path: solarheatFx9PfYY4YjMeta?.path ?? "component/OwnerInfo/ManagerInfo/solarheat",
    meta: solarheatFx9PfYY4YjMeta || {},
    alias: solarheatFx9PfYY4YjMeta?.alias || [],
    redirect: solarheatFx9PfYY4YjMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/component/OwnerInfo/ManagerInfo/solarheat.vue").then(m => m.default || m)
  },
  {
    name: indexgkVwl5mqO8Meta?.name ?? "toc-component-OwnerInfo",
    path: indexgkVwl5mqO8Meta?.path ?? "component/OwnerInfo",
    meta: indexgkVwl5mqO8Meta || {},
    alias: indexgkVwl5mqO8Meta?.alias || [],
    redirect: indexgkVwl5mqO8Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/component/OwnerInfo/index.vue").then(m => m.default || m)
  },
  {
    name: pvugamY0UH3pMeta?.name ?? "toc-component-OwnerInfo-pv",
    path: pvugamY0UH3pMeta?.path ?? "component/OwnerInfo/pv",
    meta: pvugamY0UH3pMeta || {},
    alias: pvugamY0UH3pMeta?.alias || [],
    redirect: pvugamY0UH3pMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/component/OwnerInfo/pv.vue").then(m => m.default || m)
  },
  {
    name: solarEzI9YuHyVBMeta?.name ?? "toc-component-OwnerInfo-solar",
    path: solarEzI9YuHyVBMeta?.path ?? "component/OwnerInfo/solar",
    meta: solarEzI9YuHyVBMeta || {},
    alias: solarEzI9YuHyVBMeta?.alias || [],
    redirect: solarEzI9YuHyVBMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/component/OwnerInfo/solar.vue").then(m => m.default || m)
  },
  {
    name: indexq1ok5taoGAMeta?.name ?? "toc-component-RtuInfo",
    path: indexq1ok5taoGAMeta?.path ?? "component/RtuInfo",
    meta: indexq1ok5taoGAMeta || {},
    alias: indexq1ok5taoGAMeta?.alias || [],
    redirect: indexq1ok5taoGAMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/component/RtuInfo/index.vue").then(m => m.default || m)
  },
  {
    name: common_45code_45dropdowntAWZMr3vbvMeta?.name ?? "toc-component-SearchBox-common-code-dropdown",
    path: common_45code_45dropdowntAWZMr3vbvMeta?.path ?? "component/SearchBox/common-code-dropdown",
    meta: common_45code_45dropdowntAWZMr3vbvMeta || {},
    alias: common_45code_45dropdowntAWZMr3vbvMeta?.alias || [],
    redirect: common_45code_45dropdowntAWZMr3vbvMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/component/SearchBox/common-code-dropdown.vue").then(m => m.default || m)
  },
  {
    name: date_45set_45reportokBlWx4z03Meta?.name ?? "toc-component-SearchBox-date-set-report",
    path: date_45set_45reportokBlWx4z03Meta?.path ?? "component/SearchBox/date-set-report",
    meta: date_45set_45reportokBlWx4z03Meta || {},
    alias: date_45set_45reportokBlWx4z03Meta?.alias || [],
    redirect: date_45set_45reportokBlWx4z03Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/component/SearchBox/date-set-report.vue").then(m => m.default || m)
  },
  {
    name: date_45seta4tEWmhFlLMeta?.name ?? "toc-component-SearchBox-date-set",
    path: date_45seta4tEWmhFlLMeta?.path ?? "component/SearchBox/date-set",
    meta: date_45seta4tEWmhFlLMeta || {},
    alias: date_45seta4tEWmhFlLMeta?.alias || [],
    redirect: date_45seta4tEWmhFlLMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/component/SearchBox/date-set.vue").then(m => m.default || m)
  },
  {
    name: region_45set5jrlp9WUGAMeta?.name ?? "toc-component-SearchBox-region-set",
    path: region_45set5jrlp9WUGAMeta?.path ?? "component/SearchBox/region-set",
    meta: region_45set5jrlp9WUGAMeta || {},
    alias: region_45set5jrlp9WUGAMeta?.alias || [],
    redirect: region_45set5jrlp9WUGAMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/component/SearchBox/region-set.vue").then(m => m.default || m)
  },
  {
    name: geoXRhNsFrZVdMeta?.name ?? "toc-component-SpecInfo-geo",
    path: geoXRhNsFrZVdMeta?.path ?? "component/SpecInfo/geo",
    meta: geoXRhNsFrZVdMeta || {},
    alias: geoXRhNsFrZVdMeta?.alias || [],
    redirect: geoXRhNsFrZVdMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/component/SpecInfo/geo.vue").then(m => m.default || m)
  },
  {
    name: pvqcn5AnwY7uMeta?.name ?? "toc-component-SpecInfo-pv",
    path: pvqcn5AnwY7uMeta?.path ?? "component/SpecInfo/pv",
    meta: pvqcn5AnwY7uMeta || {},
    alias: pvqcn5AnwY7uMeta?.alias || [],
    redirect: pvqcn5AnwY7uMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/component/SpecInfo/pv.vue").then(m => m.default || m)
  },
  {
    name: solarheatXPIyCcpwbuMeta?.name ?? "toc-component-SpecInfo-solarheat",
    path: solarheatXPIyCcpwbuMeta?.path ?? "component/SpecInfo/solarheat",
    meta: solarheatXPIyCcpwbuMeta || {},
    alias: solarheatXPIyCcpwbuMeta?.alias || [],
    redirect: solarheatXPIyCcpwbuMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/component/SpecInfo/solarheat.vue").then(m => m.default || m)
  },
  {
    name: Table_45Boxn81wZsmUp5Meta?.name ?? "toc-component-Table-Box",
    path: Table_45Boxn81wZsmUp5Meta?.path ?? "component/Table-Box",
    meta: Table_45Boxn81wZsmUp5Meta || {},
    alias: Table_45Boxn81wZsmUp5Meta?.alias || [],
    redirect: Table_45Boxn81wZsmUp5Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/component/Table-Box.vue").then(m => m.default || m)
  },
  {
    name: api_45search_45boxxbdu49rYhIMeta?.name ?? "toc-component-api-search-box",
    path: api_45search_45boxxbdu49rYhIMeta?.path ?? "component/api-search-box",
    meta: api_45search_45boxxbdu49rYhIMeta || {},
    alias: api_45search_45boxxbdu49rYhIMeta?.alias || [],
    redirect: api_45search_45boxxbdu49rYhIMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/component/api-search-box.vue").then(m => m.default || m)
  },
  {
    name: data_45tableXtrIPji56qMeta?.name ?? "toc-component-data-table",
    path: data_45tableXtrIPji56qMeta?.path ?? "component/data-table",
    meta: data_45tableXtrIPji56qMeta || {},
    alias: data_45tableXtrIPji56qMeta?.alias || [],
    redirect: data_45tableXtrIPji56qMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/component/data-table.vue").then(m => m.default || m)
  },
  {
    name: event_45search_45gridQuZchCPwH3Meta?.name ?? "toc-component-event-search-grid",
    path: event_45search_45gridQuZchCPwH3Meta?.path ?? "component/event-search-grid",
    meta: event_45search_45gridQuZchCPwH3Meta || {},
    alias: event_45search_45gridQuZchCPwH3Meta?.alias || [],
    redirect: event_45search_45gridQuZchCPwH3Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/component/event-search-grid.vue").then(m => m.default || m)
  },
  {
    name: info_45box27XKGS77zaMeta?.name ?? "toc-component-info-box",
    path: info_45box27XKGS77zaMeta?.path ?? "component/info-box",
    meta: info_45box27XKGS77zaMeta || {},
    alias: info_45box27XKGS77zaMeta?.alias || [],
    redirect: info_45box27XKGS77zaMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/component/info-box.vue").then(m => m.default || m)
  },
  {
    name: region_45selectGT6EUeATq8Meta?.name ?? "toc-component-region-select",
    path: region_45selectGT6EUeATq8Meta?.path ?? "component/region-select",
    meta: region_45selectGT6EUeATq8Meta || {},
    alias: region_45selectGT6EUeATq8Meta?.alias || [],
    redirect: region_45selectGT6EUeATq8Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/component/region-select.vue").then(m => m.default || m)
  },
  {
    name: search_45boxKUl2NqVYzDMeta?.name ?? "toc-component-search-box",
    path: search_45boxKUl2NqVYzDMeta?.path ?? "component/search-box",
    meta: search_45boxKUl2NqVYzDMeta || {},
    alias: search_45boxKUl2NqVYzDMeta?.alias || [],
    redirect: search_45boxKUl2NqVYzDMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/component/search-box.vue").then(m => m.default || m)
  },
  {
    name: table_45paging4y5eBgT6xjMeta?.name ?? "toc-component-table-paging",
    path: table_45paging4y5eBgT6xjMeta?.path ?? "component/table-paging",
    meta: table_45paging4y5eBgT6xjMeta || {},
    alias: table_45paging4y5eBgT6xjMeta?.alias || [],
    redirect: table_45paging4y5eBgT6xjMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/component/table-paging.vue").then(m => m.default || m)
  },
  {
    name: toc_45templatebqWBrTriN3Meta?.name ?? "toc-component-template-toc-template",
    path: toc_45templatebqWBrTriN3Meta?.path ?? "component/template/toc-template",
    meta: toc_45templatebqWBrTriN3Meta || {},
    alias: toc_45templatebqWBrTriN3Meta?.alias || [],
    redirect: toc_45templatebqWBrTriN3Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/component/template/toc-template.vue").then(m => m.default || m)
  },
  {
    name: test_45gridJM40URK4dZMeta?.name ?? "toc-component-test-grid",
    path: test_45gridJM40URK4dZMeta?.path ?? "component/test-grid",
    meta: test_45gridJM40URK4dZMeta || {},
    alias: test_45gridJM40URK4dZMeta?.alias || [],
    redirect: test_45gridJM40URK4dZMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/component/test-grid.vue").then(m => m.default || m)
  },
  {
    name: customerNc6HK5MkuqMeta?.name ?? "toc-customer",
    path: customerNc6HK5MkuqMeta?.path ?? "customer",
    children: [
  {
    name: _91id_93kPjFdKgRk6Meta?.name ?? "toc-customer-faq-id",
    path: _91id_93kPjFdKgRk6Meta?.path ?? "faq/:id",
    meta: _91id_93kPjFdKgRk6Meta || {},
    alias: _91id_93kPjFdKgRk6Meta?.alias || [],
    redirect: _91id_93kPjFdKgRk6Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/customer/faq/[id].vue").then(m => m.default || m)
  },
  {
    name: index3gXpltYiTQMeta?.name ?? "toc-customer-faq",
    path: index3gXpltYiTQMeta?.path ?? "faq",
    meta: index3gXpltYiTQMeta || {},
    alias: index3gXpltYiTQMeta?.alias || [],
    redirect: index3gXpltYiTQMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/customer/faq/index.vue").then(m => m.default || m)
  },
  {
    name: faq_companycgQ4iWjSuUMeta?.name ?? "toc-customer-faq_company",
    path: faq_companycgQ4iWjSuUMeta?.path ?? "faq_company",
    meta: faq_companycgQ4iWjSuUMeta || {},
    alias: faq_companycgQ4iWjSuUMeta?.alias || [],
    redirect: faq_companycgQ4iWjSuUMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/customer/faq_company.vue").then(m => m.default || m)
  },
  {
    name: login_45termsuSRaNE4xm2Meta?.name ?? "toc-customer-login-terms",
    path: login_45termsuSRaNE4xm2Meta?.path ?? "login-terms",
    meta: login_45termsuSRaNE4xm2Meta || {},
    alias: login_45termsuSRaNE4xm2Meta?.alias || [],
    redirect: login_45termsuSRaNE4xm2Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/customer/login-terms.vue").then(m => m.default || m)
  },
  {
    path: notice0sqjukChiVMeta?.path ?? "notice",
    children: [
  {
    name: _91id_93ZgbbN0MoxHMeta?.name ?? "toc-customer-notice-id",
    path: _91id_93ZgbbN0MoxHMeta?.path ?? ":id",
    meta: _91id_93ZgbbN0MoxHMeta || {},
    alias: _91id_93ZgbbN0MoxHMeta?.alias || [],
    redirect: _91id_93ZgbbN0MoxHMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/customer/notice/[id].vue").then(m => m.default || m)
  },
  {
    name: index9sPWysx83IMeta?.name ?? "toc-customer-notice",
    path: index9sPWysx83IMeta?.path ?? "",
    meta: index9sPWysx83IMeta || {},
    alias: index9sPWysx83IMeta?.alias || [],
    redirect: index9sPWysx83IMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/customer/notice/index.vue").then(m => m.default || m)
  }
],
    name: notice0sqjukChiVMeta?.name ?? undefined,
    meta: notice0sqjukChiVMeta || {},
    alias: notice0sqjukChiVMeta?.alias || [],
    redirect: notice0sqjukChiVMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/customer/notice.vue").then(m => m.default || m)
  },
  {
    path: qnav6o9jNTD3PMeta?.path ?? "qna",
    children: [
  {
    name: _91id_93P4XUIHeVTzMeta?.name ?? "toc-customer-qna-id",
    path: _91id_93P4XUIHeVTzMeta?.path ?? ":id",
    meta: _91id_93P4XUIHeVTzMeta || {},
    alias: _91id_93P4XUIHeVTzMeta?.alias || [],
    redirect: _91id_93P4XUIHeVTzMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/customer/qna/[id].vue").then(m => m.default || m)
  },
  {
    name: add2KWukzaSYvMeta?.name ?? "toc-customer-qna-add",
    path: add2KWukzaSYvMeta?.path ?? "add",
    meta: add2KWukzaSYvMeta || {},
    alias: add2KWukzaSYvMeta?.alias || [],
    redirect: add2KWukzaSYvMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/customer/qna/add.vue").then(m => m.default || m)
  },
  {
    name: indexD85eXSk3qMMeta?.name ?? "toc-customer-qna",
    path: indexD85eXSk3qMMeta?.path ?? "",
    meta: indexD85eXSk3qMMeta || {},
    alias: indexD85eXSk3qMMeta?.alias || [],
    redirect: indexD85eXSk3qMMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/customer/qna/index.vue").then(m => m.default || m)
  }
],
    name: qnav6o9jNTD3PMeta?.name ?? undefined,
    meta: qnav6o9jNTD3PMeta || {},
    alias: qnav6o9jNTD3PMeta?.alias || [],
    redirect: qnav6o9jNTD3PMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/customer/qna.vue").then(m => m.default || m)
  },
  {
    path: referenceuCTHV0lX7VMeta?.path ?? "reference",
    children: [
  {
    name: _91id_93eNsl2DDX75Meta?.name ?? "toc-customer-reference-id",
    path: _91id_93eNsl2DDX75Meta?.path ?? ":id",
    meta: _91id_93eNsl2DDX75Meta || {},
    alias: _91id_93eNsl2DDX75Meta?.alias || [],
    redirect: _91id_93eNsl2DDX75Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/customer/reference/[id].vue").then(m => m.default || m)
  },
  {
    name: indexQ1Mn8mSYSZMeta?.name ?? "toc-customer-reference",
    path: indexQ1Mn8mSYSZMeta?.path ?? "",
    meta: indexQ1Mn8mSYSZMeta || {},
    alias: indexQ1Mn8mSYSZMeta?.alias || [],
    redirect: indexQ1Mn8mSYSZMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/customer/reference/index.vue").then(m => m.default || m)
  }
],
    name: referenceuCTHV0lX7VMeta?.name ?? undefined,
    meta: referenceuCTHV0lX7VMeta || {},
    alias: referenceuCTHV0lX7VMeta?.alias || [],
    redirect: referenceuCTHV0lX7VMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/customer/reference.vue").then(m => m.default || m)
  },
  {
    name: terms1ApUsgJHnH9Meta?.name ?? "toc-customer-terms-list-terms1",
    path: terms1ApUsgJHnH9Meta?.path ?? "terms-list/terms1",
    meta: terms1ApUsgJHnH9Meta || {},
    alias: terms1ApUsgJHnH9Meta?.alias || [],
    redirect: terms1ApUsgJHnH9Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/customer/terms-list/terms1.vue").then(m => m.default || m)
  },
  {
    name: terms2hFkWDTjevFMeta?.name ?? "toc-customer-terms-list-terms2",
    path: terms2hFkWDTjevFMeta?.path ?? "terms-list/terms2",
    meta: terms2hFkWDTjevFMeta || {},
    alias: terms2hFkWDTjevFMeta?.alias || [],
    redirect: terms2hFkWDTjevFMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/customer/terms-list/terms2.vue").then(m => m.default || m)
  },
  {
    name: termsmpk4t5ULc5Meta?.name ?? "toc-customer-terms",
    path: termsmpk4t5ULc5Meta?.path ?? "terms",
    meta: termsmpk4t5ULc5Meta || {},
    alias: termsmpk4t5ULc5Meta?.alias || [],
    redirect: termsmpk4t5ULc5Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/customer/terms.vue").then(m => m.default || m)
  }
],
    meta: customerNc6HK5MkuqMeta || {},
    alias: customerNc6HK5MkuqMeta?.alias || [],
    redirect: customerNc6HK5MkuqMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/customer.vue").then(m => m.default || m)
  },
  {
    name: equippd98XlPz2nMeta?.name ?? "toc-equip",
    path: equippd98XlPz2nMeta?.path ?? "equip",
    children: [
  {
    name: equip_45detailU46tacGSaVMeta?.name ?? "toc-equip-pathinfo-equip-detail",
    path: equip_45detailU46tacGSaVMeta?.path ?? ":pathinfo/equip-detail",
    children: [
  {
    name: _91cid_931wcvWIk7MzMeta?.name ?? "toc-equip-pathinfo-equip-detail-cid",
    path: _91cid_931wcvWIk7MzMeta?.path ?? ":cid",
    children: [
  {
    name: geo_45prod_45card4YqIpmG6cwMeta?.name ?? "toc-equip-pathinfo-equip-detail-cid-component-geo-prod-card",
    path: geo_45prod_45card4YqIpmG6cwMeta?.path ?? "component/geo-prod-card",
    meta: geo_45prod_45card4YqIpmG6cwMeta || {},
    alias: geo_45prod_45card4YqIpmG6cwMeta?.alias || [],
    redirect: geo_45prod_45card4YqIpmG6cwMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/component/geo-prod-card.vue").then(m => m.default || m)
  },
  {
    name: geo_45prod_45chart7FCnY1dKWzMeta?.name ?? "toc-equip-pathinfo-equip-detail-cid-component-geo-prod-chart",
    path: geo_45prod_45chart7FCnY1dKWzMeta?.path ?? "component/geo-prod-chart",
    meta: geo_45prod_45chart7FCnY1dKWzMeta || {},
    alias: geo_45prod_45chart7FCnY1dKWzMeta?.alias || [],
    redirect: geo_45prod_45chart7FCnY1dKWzMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/component/geo-prod-chart.vue").then(m => m.default || m)
  },
  {
    name: geo_45usage_45card67cJdWY87sMeta?.name ?? "toc-equip-pathinfo-equip-detail-cid-component-geo-usage-card",
    path: geo_45usage_45card67cJdWY87sMeta?.path ?? "component/geo-usage-card",
    meta: geo_45usage_45card67cJdWY87sMeta || {},
    alias: geo_45usage_45card67cJdWY87sMeta?.alias || [],
    redirect: geo_45usage_45card67cJdWY87sMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/component/geo-usage-card.vue").then(m => m.default || m)
  },
  {
    name: geo_45usage_45chartMdGELiITFzMeta?.name ?? "toc-equip-pathinfo-equip-detail-cid-component-geo-usage-chart",
    path: geo_45usage_45chartMdGELiITFzMeta?.path ?? "component/geo-usage-chart",
    meta: geo_45usage_45chartMdGELiITFzMeta || {},
    alias: geo_45usage_45chartMdGELiITFzMeta?.alias || [],
    redirect: geo_45usage_45chartMdGELiITFzMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/component/geo-usage-chart.vue").then(m => m.default || m)
  },
  {
    name: raw_45data_45chartZpK0HEtEjiMeta?.name ?? "toc-equip-pathinfo-equip-detail-cid-component-raw-data-chart",
    path: raw_45data_45chartZpK0HEtEjiMeta?.path ?? "component/raw-data-chart",
    meta: raw_45data_45chartZpK0HEtEjiMeta || {},
    alias: raw_45data_45chartZpK0HEtEjiMeta?.alias || [],
    redirect: raw_45data_45chartZpK0HEtEjiMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/component/raw-data-chart.vue").then(m => m.default || m)
  },
  {
    name: raw_45data_45info3pdhy5CaSYMeta?.name ?? "toc-equip-pathinfo-equip-detail-cid-component-raw-data-info",
    path: raw_45data_45info3pdhy5CaSYMeta?.path ?? "component/raw-data-info",
    meta: raw_45data_45info3pdhy5CaSYMeta || {},
    alias: raw_45data_45info3pdhy5CaSYMeta?.alias || [],
    redirect: raw_45data_45info3pdhy5CaSYMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/component/raw-data-info.vue").then(m => m.default || m)
  },
  {
    name: solarheat_45prod_45cardQXDRbEqMmLMeta?.name ?? "toc-equip-pathinfo-equip-detail-cid-component-solarheat-prod-card",
    path: solarheat_45prod_45cardQXDRbEqMmLMeta?.path ?? "component/solarheat-prod-card",
    meta: solarheat_45prod_45cardQXDRbEqMmLMeta || {},
    alias: solarheat_45prod_45cardQXDRbEqMmLMeta?.alias || [],
    redirect: solarheat_45prod_45cardQXDRbEqMmLMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/component/solarheat-prod-card.vue").then(m => m.default || m)
  },
  {
    name: solarheat_45prod_45chartfhcEuZKbziMeta?.name ?? "toc-equip-pathinfo-equip-detail-cid-component-solarheat-prod-chart",
    path: solarheat_45prod_45chartfhcEuZKbziMeta?.path ?? "component/solarheat-prod-chart",
    meta: solarheat_45prod_45chartfhcEuZKbziMeta || {},
    alias: solarheat_45prod_45chartfhcEuZKbziMeta?.alias || [],
    redirect: solarheat_45prod_45chartfhcEuZKbziMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/component/solarheat-prod-chart.vue").then(m => m.default || m)
  },
  {
    name: solarheat_45usage_45cardRpIjR1hU4nMeta?.name ?? "toc-equip-pathinfo-equip-detail-cid-component-solarheat-usage-card",
    path: solarheat_45usage_45cardRpIjR1hU4nMeta?.path ?? "component/solarheat-usage-card",
    meta: solarheat_45usage_45cardRpIjR1hU4nMeta || {},
    alias: solarheat_45usage_45cardRpIjR1hU4nMeta?.alias || [],
    redirect: solarheat_45usage_45cardRpIjR1hU4nMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/component/solarheat-usage-card.vue").then(m => m.default || m)
  },
  {
    name: solarheat_45usage_45chartlEdqBWsNk0Meta?.name ?? "toc-equip-pathinfo-equip-detail-cid-component-solarheat-usage-chart",
    path: solarheat_45usage_45chartlEdqBWsNk0Meta?.path ?? "component/solarheat-usage-chart",
    meta: solarheat_45usage_45chartlEdqBWsNk0Meta || {},
    alias: solarheat_45usage_45chartlEdqBWsNk0Meta?.alias || [],
    redirect: solarheat_45usage_45chartlEdqBWsNk0Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/component/solarheat-usage-chart.vue").then(m => m.default || m)
  },
  {
    name: geo_45prodc310CUXyBsMeta?.name ?? "toc-equip-pathinfo-equip-detail-cid-geo-prod",
    path: geo_45prodc310CUXyBsMeta?.path ?? "geo-prod",
    meta: geo_45prodc310CUXyBsMeta || {},
    alias: geo_45prodc310CUXyBsMeta?.alias || [],
    redirect: geo_45prodc310CUXyBsMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/geo-prod.vue").then(m => m.default || m)
  },
  {
    name: geo_45usagefYTrQlbnZFMeta?.name ?? "toc-equip-pathinfo-equip-detail-cid-geo-usage",
    path: geo_45usagefYTrQlbnZFMeta?.path ?? "geo-usage",
    meta: geo_45usagefYTrQlbnZFMeta || {},
    alias: geo_45usagefYTrQlbnZFMeta?.alias || [],
    redirect: geo_45usagefYTrQlbnZFMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/geo-usage.vue").then(m => m.default || m)
  },
  {
    name: geot7UQHilOfJMeta?.name ?? "toc-equip-pathinfo-equip-detail-cid-geo",
    path: geot7UQHilOfJMeta?.path ?? "geo",
    meta: geot7UQHilOfJMeta || {},
    alias: geot7UQHilOfJMeta?.alias || [],
    redirect: geot7UQHilOfJMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/geo.vue").then(m => m.default || m)
  },
  {
    name: pvS85OmOqewVMeta?.name ?? "toc-equip-pathinfo-equip-detail-cid-pv",
    path: pvS85OmOqewVMeta?.path ?? "pv",
    meta: pvS85OmOqewVMeta || {},
    alias: pvS85OmOqewVMeta?.alias || [],
    redirect: pvS85OmOqewVMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/pv.vue").then(m => m.default || m)
  },
  {
    name: solarheatxQCn0EyljuMeta?.name ?? "toc-equip-pathinfo-equip-detail-cid-solarheat",
    path: solarheatxQCn0EyljuMeta?.path ?? "solarheat",
    meta: solarheatxQCn0EyljuMeta || {},
    alias: solarheatxQCn0EyljuMeta?.alias || [],
    redirect: solarheatxQCn0EyljuMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/solarheat.vue").then(m => m.default || m)
  }
],
    meta: _91cid_931wcvWIk7MzMeta || {},
    alias: _91cid_931wcvWIk7MzMeta?.alias || [],
    redirect: _91cid_931wcvWIk7MzMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail/[cid].vue").then(m => m.default || m)
  }
],
    meta: equip_45detailU46tacGSaVMeta || {},
    alias: equip_45detailU46tacGSaVMeta?.alias || [],
    redirect: equip_45detailU46tacGSaVMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail.vue").then(m => m.default || m)
  },
  {
    name: equip_45listdOIQTyxcQSMeta?.name ?? "toc-equip-pathinfo-equip-list",
    path: equip_45listdOIQTyxcQSMeta?.path ?? ":pathinfo/equip-list",
    meta: equip_45listdOIQTyxcQSMeta || {},
    alias: equip_45listdOIQTyxcQSMeta?.alias || [],
    redirect: equip_45listdOIQTyxcQSMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-list.vue").then(m => m.default || m)
  },
  {
    name: equip_45mapzFlfxpX6aDMeta?.name ?? "toc-equip-pathinfo-equip-map",
    path: equip_45mapzFlfxpX6aDMeta?.path ?? ":pathinfo/equip-map",
    meta: equip_45mapzFlfxpX6aDMeta || {},
    alias: equip_45mapzFlfxpX6aDMeta?.alias || [],
    redirect: equip_45mapzFlfxpX6aDMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-map.vue").then(m => m.default || m)
  },
  {
    name: measureJULiUdqVLPMeta?.name ?? "toc-equip-pathinfo-measure",
    path: measureJULiUdqVLPMeta?.path ?? ":pathinfo/measure",
    meta: measureJULiUdqVLPMeta || {},
    alias: measureJULiUdqVLPMeta?.alias || [],
    redirect: measureJULiUdqVLPMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/measure.vue").then(m => m.default || m)
  },
  {
    name: monitoring_45mapdCM4jr0TP6Meta?.name ?? "toc-equip-pathinfo-monitoring-map",
    path: monitoring_45mapdCM4jr0TP6Meta?.path ?? ":pathinfo/monitoring-map",
    meta: monitoring_45mapdCM4jr0TP6Meta || {},
    alias: monitoring_45mapdCM4jr0TP6Meta?.alias || [],
    redirect: monitoring_45mapdCM4jr0TP6Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/monitoring-map.vue").then(m => m.default || m)
  },
  {
    path: power_45generateT8uX0cX3XIMeta?.path ?? ":pathinfo/power-generate",
    children: [
  {
    name: indexg22aay85lNMeta?.name ?? "toc-equip-pathinfo-power-generate",
    path: indexg22aay85lNMeta?.path ?? "",
    meta: indexg22aay85lNMeta || {},
    alias: indexg22aay85lNMeta?.alias || [],
    redirect: indexg22aay85lNMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/power-generate/index.vue").then(m => m.default || m)
  }
],
    name: power_45generateT8uX0cX3XIMeta?.name ?? undefined,
    meta: power_45generateT8uX0cX3XIMeta || {},
    alias: power_45generateT8uX0cX3XIMeta?.alias || [],
    redirect: power_45generateT8uX0cX3XIMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/power-generate.vue").then(m => m.default || m)
  },
  {
    name: rtucC9OgG89B9Meta?.name ?? "toc-equip-pathinfo-rtu",
    path: rtucC9OgG89B9Meta?.path ?? ":pathinfo/rtu",
    meta: rtucC9OgG89B9Meta || {},
    alias: rtucC9OgG89B9Meta?.alias || [],
    redirect: rtucC9OgG89B9Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/rtu.vue").then(m => m.default || m)
  },
  {
    path: siteNneTtkKg0gMeta?.path ?? ":pathinfo/site",
    children: [
  {
    name: geoGSAEVGMM22Meta?.name ?? "toc-equip-pathinfo-site-newsitename-geo",
    path: geoGSAEVGMM22Meta?.path ?? ":newsitename/geo",
    meta: geoGSAEVGMM22Meta || {},
    alias: geoGSAEVGMM22Meta?.alias || [],
    redirect: geoGSAEVGMM22Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/site/[newsitename]/geo.vue").then(m => m.default || m)
  },
  {
    name: pv1MP5yclD3OMeta?.name ?? "toc-equip-pathinfo-site-newsitename-pv",
    path: pv1MP5yclD3OMeta?.path ?? ":newsitename/pv",
    meta: pv1MP5yclD3OMeta || {},
    alias: pv1MP5yclD3OMeta?.alias || [],
    redirect: pv1MP5yclD3OMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/site/[newsitename]/pv.vue").then(m => m.default || m)
  },
  {
    name: solarheataKnfYSWkMLMeta?.name ?? "toc-equip-pathinfo-site-newsitename-solarheat",
    path: solarheataKnfYSWkMLMeta?.path ?? ":newsitename/solarheat",
    meta: solarheataKnfYSWkMLMeta || {},
    alias: solarheataKnfYSWkMLMeta?.alias || [],
    redirect: solarheataKnfYSWkMLMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/site/[newsitename]/solarheat.vue").then(m => m.default || m)
  },
  {
    name: _91sitename_93egawk5KImzMeta?.name ?? "toc-equip-pathinfo-site-sitename",
    path: _91sitename_93egawk5KImzMeta?.path ?? ":sitename",
    children: [
  {
    name: geoZVRwjNKxgTMeta?.name ?? "toc-equip-pathinfo-site-sitename-geo",
    path: geoZVRwjNKxgTMeta?.path ?? "geo",
    meta: geoZVRwjNKxgTMeta || {},
    alias: geoZVRwjNKxgTMeta?.alias || [],
    redirect: geoZVRwjNKxgTMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/site/[sitename]/geo.vue").then(m => m.default || m)
  },
  {
    name: pvKID1FHAQUkMeta?.name ?? "toc-equip-pathinfo-site-sitename-pv",
    path: pvKID1FHAQUkMeta?.path ?? "pv",
    meta: pvKID1FHAQUkMeta || {},
    alias: pvKID1FHAQUkMeta?.alias || [],
    redirect: pvKID1FHAQUkMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/site/[sitename]/pv.vue").then(m => m.default || m)
  },
  {
    name: solarheatJPK0XzFlURMeta?.name ?? "toc-equip-pathinfo-site-sitename-solarheat",
    path: solarheatJPK0XzFlURMeta?.path ?? "solarheat",
    meta: solarheatJPK0XzFlURMeta || {},
    alias: solarheatJPK0XzFlURMeta?.alias || [],
    redirect: solarheatJPK0XzFlURMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/site/[sitename]/solarheat.vue").then(m => m.default || m)
  }
],
    meta: _91sitename_93egawk5KImzMeta || {},
    alias: _91sitename_93egawk5KImzMeta?.alias || [],
    redirect: _91sitename_93egawk5KImzMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/site/[sitename].vue").then(m => m.default || m)
  },
  {
    name: indexr4IphczNqqMeta?.name ?? "toc-equip-pathinfo-site",
    path: indexr4IphczNqqMeta?.path ?? "",
    meta: indexr4IphczNqqMeta || {},
    alias: indexr4IphczNqqMeta?.alias || [],
    redirect: indexr4IphczNqqMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/site/index.vue").then(m => m.default || m)
  },
  {
    name: _91newsitename_9329dALcljhRMeta?.name ?? "toc-equip-pathinfo-site-test-newsitename",
    path: _91newsitename_9329dALcljhRMeta?.path ?? "test/:newsitename",
    meta: _91newsitename_9329dALcljhRMeta || {},
    alias: _91newsitename_9329dALcljhRMeta?.alias || [],
    redirect: _91newsitename_9329dALcljhRMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/site/test/[newsitename].vue").then(m => m.default || m)
  }
],
    name: siteNneTtkKg0gMeta?.name ?? undefined,
    meta: siteNneTtkKg0gMeta || {},
    alias: siteNneTtkKg0gMeta?.alias || [],
    redirect: siteNneTtkKg0gMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/site.vue").then(m => m.default || m)
  }
],
    meta: equippd98XlPz2nMeta || {},
    alias: equippd98XlPz2nMeta?.alias || [],
    redirect: equippd98XlPz2nMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/equip.vue").then(m => m.default || m)
  },
  {
    name: equipinforpoqbWtsM2Meta?.name ?? "toc-equipinfo",
    path: equipinforpoqbWtsM2Meta?.path ?? "equipinfo",
    meta: equipinforpoqbWtsM2Meta || {},
    alias: equipinforpoqbWtsM2Meta?.alias || [],
    redirect: equipinforpoqbWtsM2Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/equipinfo.vue").then(m => m.default || m)
  },
  {
    name: errorv9W1yVzr9aMeta?.name ?? "toc-error",
    path: errorv9W1yVzr9aMeta?.path ?? "error",
    meta: errorv9W1yVzr9aMeta || {},
    alias: errorv9W1yVzr9aMeta?.alias || [],
    redirect: errorv9W1yVzr9aMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/error.vue").then(m => m.default || m)
  },
  {
    path: eventwyDbiyZGddMeta?.path ?? "event",
    children: [
  {
    name: event_45listfNEWQsKuKiMeta?.name ?? "toc-event-event-list",
    path: event_45listfNEWQsKuKiMeta?.path ?? "event-list",
    children: [
  {
    name: _91eventId_93RubYbOah3sMeta?.name ?? "toc-event-event-list-eventId",
    path: _91eventId_93RubYbOah3sMeta?.path ?? ":eventId",
    meta: _91eventId_93RubYbOah3sMeta || {},
    alias: _91eventId_93RubYbOah3sMeta?.alias || [],
    redirect: _91eventId_93RubYbOah3sMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/event/event-list/[eventId].vue").then(m => m.default || m)
  }
],
    meta: event_45listfNEWQsKuKiMeta || {},
    alias: event_45listfNEWQsKuKiMeta?.alias || [],
    redirect: event_45listfNEWQsKuKiMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/event/event-list.vue").then(m => m.default || m)
  },
  {
    name: indexiigBbyUuDtMeta?.name ?? "toc-event",
    path: indexiigBbyUuDtMeta?.path ?? "",
    meta: indexiigBbyUuDtMeta || {},
    alias: indexiigBbyUuDtMeta?.alias || [],
    redirect: indexiigBbyUuDtMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/event/index.vue").then(m => m.default || m)
  }
],
    name: eventwyDbiyZGddMeta?.name ?? undefined,
    meta: eventwyDbiyZGddMeta || {},
    alias: eventwyDbiyZGddMeta?.alias || [],
    redirect: eventwyDbiyZGddMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/event.vue").then(m => m.default || m)
  },
  {
    name: installTVxg0KIgevMeta?.name ?? "toc-install",
    path: installTVxg0KIgevMeta?.path ?? "install",
    meta: installTVxg0KIgevMeta || {},
    alias: installTVxg0KIgevMeta?.alias || [],
    redirect: installTVxg0KIgevMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/install.vue").then(m => m.default || m)
  },
  {
    name: inventorygS72155vDkMeta?.name ?? "toc-inventory",
    path: inventorygS72155vDkMeta?.path ?? "inventory",
    children: [
  {
    name: add3aOdhTINfKMeta?.name ?? "toc-inventory-equip-company-add",
    path: add3aOdhTINfKMeta?.path ?? "equip-company/add",
    meta: add3aOdhTINfKMeta || {},
    alias: add3aOdhTINfKMeta?.alias || [],
    redirect: add3aOdhTINfKMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/inventory/equip-company/add.vue").then(m => m.default || m)
  },
  {
    name: productFBGhtG2FHfMeta?.name ?? "toc-inventory-equip-company-product",
    path: productFBGhtG2FHfMeta?.path ?? "equip-company/product",
    meta: productFBGhtG2FHfMeta || {},
    alias: productFBGhtG2FHfMeta?.alias || [],
    redirect: productFBGhtG2FHfMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/inventory/equip-company/product.vue").then(m => m.default || m)
  }
],
    meta: inventorygS72155vDkMeta || {},
    alias: inventorygS72155vDkMeta?.alias || [],
    redirect: inventorygS72155vDkMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/inventory.vue").then(m => m.default || m)
  },
  {
    name: maintainencem6pgxd3n8GMeta?.name ?? "toc-maintainence",
    path: maintainencem6pgxd3n8GMeta?.path ?? "maintainence",
    meta: maintainencem6pgxd3n8GMeta || {},
    alias: maintainencem6pgxd3n8GMeta?.alias || [],
    redirect: maintainencem6pgxd3n8GMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/maintainence.vue").then(m => m.default || m)
  },
  {
    path: companyaBd1Eis0PHMeta?.path ?? "management/company",
    children: [
  {
    name: addmuUS6i4ILXMeta?.name ?? "toc-management-company-add",
    path: addmuUS6i4ILXMeta?.path ?? "add",
    meta: addmuUS6i4ILXMeta || {},
    alias: addmuUS6i4ILXMeta?.alias || [],
    redirect: addmuUS6i4ILXMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/management/company/add.vue").then(m => m.default || m)
  },
  {
    name: indexHKhjDS0QVXMeta?.name ?? "toc-management-company",
    path: indexHKhjDS0QVXMeta?.path ?? "",
    meta: indexHKhjDS0QVXMeta || {},
    alias: indexHKhjDS0QVXMeta?.alias || [],
    redirect: indexHKhjDS0QVXMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/management/company/index.vue").then(m => m.default || m)
  }
],
    name: companyaBd1Eis0PHMeta?.name ?? undefined,
    meta: companyaBd1Eis0PHMeta || {},
    alias: companyaBd1Eis0PHMeta?.alias || [],
    redirect: companyaBd1Eis0PHMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/management/company.vue").then(m => m.default || m)
  },
  {
    name: equip_45model9HCBmkEqH5Meta?.name ?? "toc-management-equip-model",
    path: equip_45model9HCBmkEqH5Meta?.path ?? "management/equip-model",
    meta: equip_45model9HCBmkEqH5Meta || {},
    alias: equip_45model9HCBmkEqH5Meta?.alias || [],
    redirect: equip_45model9HCBmkEqH5Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/management/equip-model.vue").then(m => m.default || m)
  },
  {
    path: equipT64gXBbfs3Meta?.path ?? "management/equip",
    children: [
  {
    name: addlUu343V9bfMeta?.name ?? "toc-management-equip-add",
    path: addlUu343V9bfMeta?.path ?? "add",
    children: [
  {
    name: find_45ownerB66O6myJGcMeta?.name ?? "toc-management-equip-add-find-owner",
    path: find_45ownerB66O6myJGcMeta?.path ?? "find-owner",
    meta: find_45ownerB66O6myJGcMeta || {},
    alias: find_45ownerB66O6myJGcMeta?.alias || [],
    redirect: find_45ownerB66O6myJGcMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/management/equip/add/find-owner.vue").then(m => m.default || m)
  },
  {
    name: geo_45specqLBj9YpOD1Meta?.name ?? "toc-management-equip-add-geo-spec",
    path: geo_45specqLBj9YpOD1Meta?.path ?? "geo-spec",
    meta: geo_45specqLBj9YpOD1Meta || {},
    alias: geo_45specqLBj9YpOD1Meta?.alias || [],
    redirect: geo_45specqLBj9YpOD1Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/management/equip/add/geo-spec.vue").then(m => m.default || m)
  },
  {
    name: pv_45specd0PakAiuopMeta?.name ?? "toc-management-equip-add-pv-spec",
    path: pv_45specd0PakAiuopMeta?.path ?? "pv-spec",
    meta: pv_45specd0PakAiuopMeta || {},
    alias: pv_45specd0PakAiuopMeta?.alias || [],
    redirect: pv_45specd0PakAiuopMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/management/equip/add/pv-spec.vue").then(m => m.default || m)
  },
  {
    name: solarheat_45specmUSpsiMNLrMeta?.name ?? "toc-management-equip-add-solarheat-spec",
    path: solarheat_45specmUSpsiMNLrMeta?.path ?? "solarheat-spec",
    meta: solarheat_45specmUSpsiMNLrMeta || {},
    alias: solarheat_45specmUSpsiMNLrMeta?.alias || [],
    redirect: solarheat_45specmUSpsiMNLrMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/management/equip/add/solarheat-spec.vue").then(m => m.default || m)
  }
],
    meta: addlUu343V9bfMeta || {},
    alias: addlUu343V9bfMeta?.alias || [],
    redirect: addlUu343V9bfMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/management/equip/add.vue").then(m => m.default || m)
  },
  {
    name: index7z7LdLxvheMeta?.name ?? "toc-management-equip",
    path: index7z7LdLxvheMeta?.path ?? "",
    meta: index7z7LdLxvheMeta || {},
    alias: index7z7LdLxvheMeta?.alias || [],
    redirect: index7z7LdLxvheMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/management/equip/index.vue").then(m => m.default || m)
  }
],
    name: equipT64gXBbfs3Meta?.name ?? undefined,
    meta: equipT64gXBbfs3Meta || {},
    alias: equipT64gXBbfs3Meta?.alias || [],
    redirect: equipT64gXBbfs3Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/management/equip.vue").then(m => m.default || m)
  },
  {
    name: management48vTg2MZF9Meta?.name ?? "toc-management-management",
    path: management48vTg2MZF9Meta?.path ?? "management/management",
    meta: management48vTg2MZF9Meta || {},
    alias: management48vTg2MZF9Meta?.alias || [],
    redirect: management48vTg2MZF9Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/management/management.vue").then(m => m.default || m)
  },
  {
    name: indexAX67MnzGHeMeta?.name ?? "toc-management-manager",
    path: indexAX67MnzGHeMeta?.path ?? "management/manager",
    meta: indexAX67MnzGHeMeta || {},
    alias: indexAX67MnzGHeMeta?.alias || [],
    redirect: indexAX67MnzGHeMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/management/manager/index.vue").then(m => m.default || m)
  },
  {
    name: noticeBL5YNxdAJiMeta?.name ?? "toc-management-notice",
    path: noticeBL5YNxdAJiMeta?.path ?? "management/notice",
    meta: noticeBL5YNxdAJiMeta || {},
    alias: noticeBL5YNxdAJiMeta?.alias || [],
    redirect: noticeBL5YNxdAJiMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/management/notice.vue").then(m => m.default || m)
  },
  {
    path: ownergIJjGbP7LfMeta?.path ?? "management/owner",
    children: [
  {
    name: addyUVHsYFNXtMeta?.name ?? "toc-management-owner-add",
    path: addyUVHsYFNXtMeta?.path ?? "add",
    meta: addyUVHsYFNXtMeta || {},
    alias: addyUVHsYFNXtMeta?.alias || [],
    redirect: addyUVHsYFNXtMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/management/owner/add.vue").then(m => m.default || m)
  },
  {
    name: indexrHQOf6ixlvMeta?.name ?? "toc-management-owner",
    path: indexrHQOf6ixlvMeta?.path ?? "",
    meta: indexrHQOf6ixlvMeta || {},
    alias: indexrHQOf6ixlvMeta?.alias || [],
    redirect: indexrHQOf6ixlvMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/management/owner/index.vue").then(m => m.default || m)
  }
],
    name: ownergIJjGbP7LfMeta?.name ?? undefined,
    meta: ownergIJjGbP7LfMeta || {},
    alias: ownergIJjGbP7LfMeta?.alias || [],
    redirect: ownergIJjGbP7LfMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/management/owner.vue").then(m => m.default || m)
  },
  {
    path: permitEGHCNrC6ujMeta?.path ?? "management/permit",
    children: [
  {
    name: index8gtNMxVykXMeta?.name ?? "toc-management-permit",
    path: index8gtNMxVykXMeta?.path ?? "",
    meta: index8gtNMxVykXMeta || {},
    alias: index8gtNMxVykXMeta?.alias || [],
    redirect: index8gtNMxVykXMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/management/permit/index.vue").then(m => m.default || m)
  }
],
    name: permitEGHCNrC6ujMeta?.name ?? undefined,
    meta: permitEGHCNrC6ujMeta || {},
    alias: permitEGHCNrC6ujMeta?.alias || [],
    redirect: permitEGHCNrC6ujMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/management/permit.vue").then(m => m.default || m)
  },
  {
    name: memberPiriU4Vhe2Meta?.name ?? "toc-member",
    path: memberPiriU4Vhe2Meta?.path ?? "member",
    children: [
  {
    name: operatorSV0XXSnny5Meta?.name ?? "toc-member-operator",
    path: operatorSV0XXSnny5Meta?.path ?? "operator",
    meta: operatorSV0XXSnny5Meta || {},
    alias: operatorSV0XXSnny5Meta?.alias || [],
    redirect: operatorSV0XXSnny5Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/member/operator.vue").then(m => m.default || m)
  },
  {
    name: _91id_934XGCRKg7feMeta?.name ?? "toc-member-owner-member-id",
    path: _91id_934XGCRKg7feMeta?.path ?? "owner-member/:id",
    children: [
  {
    name: geo1ReY4lYPcHMeta?.name ?? "toc-member-owner-member-id-geo",
    path: geo1ReY4lYPcHMeta?.path ?? "geo",
    meta: geo1ReY4lYPcHMeta || {},
    alias: geo1ReY4lYPcHMeta?.alias || [],
    redirect: geo1ReY4lYPcHMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/member/owner-member/[id]/geo.vue").then(m => m.default || m)
  },
  {
    name: pvjY4CSHdbrOMeta?.name ?? "toc-member-owner-member-id-pv",
    path: pvjY4CSHdbrOMeta?.path ?? "pv",
    meta: pvjY4CSHdbrOMeta || {},
    alias: pvjY4CSHdbrOMeta?.alias || [],
    redirect: pvjY4CSHdbrOMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/member/owner-member/[id]/pv.vue").then(m => m.default || m)
  },
  {
    name: solarheat4MCrGrAJEDMeta?.name ?? "toc-member-owner-member-id-solarheat",
    path: solarheat4MCrGrAJEDMeta?.path ?? "solarheat",
    meta: solarheat4MCrGrAJEDMeta || {},
    alias: solarheat4MCrGrAJEDMeta?.alias || [],
    redirect: solarheat4MCrGrAJEDMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/member/owner-member/[id]/solarheat.vue").then(m => m.default || m)
  }
],
    meta: _91id_934XGCRKg7feMeta || {},
    alias: _91id_934XGCRKg7feMeta?.alias || [],
    redirect: _91id_934XGCRKg7feMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/member/owner-member/[id].vue").then(m => m.default || m)
  },
  {
    name: owner_45regist8rkn7DjQ5MMeta?.name ?? "toc-member-owner-member-owner-regist",
    path: owner_45regist8rkn7DjQ5MMeta?.path ?? "owner-member/owner-regist",
    meta: owner_45regist8rkn7DjQ5MMeta || {},
    alias: owner_45regist8rkn7DjQ5MMeta?.alias || [],
    redirect: owner_45regist8rkn7DjQ5MMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/member/owner-member/owner-regist.vue").then(m => m.default || m)
  },
  {
    name: owner_45searchT9dYU2XbCdMeta?.name ?? "toc-member-owner-member-owner-search",
    path: owner_45searchT9dYU2XbCdMeta?.path ?? "owner-member/owner-search",
    meta: owner_45searchT9dYU2XbCdMeta || {},
    alias: owner_45searchT9dYU2XbCdMeta?.alias || [],
    redirect: owner_45searchT9dYU2XbCdMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/member/owner-member/owner-search.vue").then(m => m.default || m)
  },
  {
    name: ownera8QF0KAWVgMeta?.name ?? "toc-member-owner",
    path: ownera8QF0KAWVgMeta?.path ?? "owner",
    meta: ownera8QF0KAWVgMeta || {},
    alias: ownera8QF0KAWVgMeta?.alias || [],
    redirect: ownera8QF0KAWVgMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/member/owner.vue").then(m => m.default || m)
  },
  {
    name: systemn1gikzi5tsMeta?.name ?? "toc-member-system",
    path: systemn1gikzi5tsMeta?.path ?? "system",
    meta: systemn1gikzi5tsMeta || {},
    alias: systemn1gikzi5tsMeta?.alias || [],
    redirect: systemn1gikzi5tsMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/member/system.vue").then(m => m.default || m)
  }
],
    meta: memberPiriU4Vhe2Meta || {},
    alias: memberPiriU4Vhe2Meta?.alias || [],
    redirect: memberPiriU4Vhe2Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/member.vue").then(m => m.default || m)
  },
  {
    name: messageELFUtpr7ZaMeta?.name ?? "toc-message",
    path: messageELFUtpr7ZaMeta?.path ?? "message",
    children: [
  {
    name: alarm_45sending9gsP8lMG9MMeta?.name ?? "toc-message-alarm-sending",
    path: alarm_45sending9gsP8lMG9MMeta?.path ?? "alarm-sending",
    meta: alarm_45sending9gsP8lMG9MMeta || {},
    alias: alarm_45sending9gsP8lMG9MMeta?.alias || [],
    redirect: alarm_45sending9gsP8lMG9MMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/message/alarm-sending.vue").then(m => m.default || m)
  },
  {
    name: email_45sendingTcWv243yQwMeta?.name ?? "toc-message-email-sending",
    path: email_45sendingTcWv243yQwMeta?.path ?? "email-sending",
    meta: email_45sendingTcWv243yQwMeta || {},
    alias: email_45sendingTcWv243yQwMeta?.alias || [],
    redirect: email_45sendingTcWv243yQwMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/message/email-sending.vue").then(m => m.default || m)
  }
],
    meta: messageELFUtpr7ZaMeta || {},
    alias: messageELFUtpr7ZaMeta?.alias || [],
    redirect: messageELFUtpr7ZaMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/message.vue").then(m => m.default || m)
  },
  {
    name: monitoringRvOj8YlrpiMeta?.name ?? "toc-monitoring",
    path: monitoringRvOj8YlrpiMeta?.path ?? "monitoring",
    children: [
  {
    name: comprehensive_45slide7DHq2Iw6ogMeta?.name ?? "toc-monitoring-comprehensive-slide",
    path: comprehensive_45slide7DHq2Iw6ogMeta?.path ?? "comprehensive-slide",
    meta: comprehensive_45slide7DHq2Iw6ogMeta || {},
    alias: comprehensive_45slide7DHq2Iw6ogMeta?.alias || [],
    redirect: comprehensive_45slide7DHq2Iw6ogMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/monitoring/comprehensive-slide.vue").then(m => m.default || m)
  },
  {
    name: dashboard8qU9va8nWUMeta?.name ?? "toc-monitoring-dashboard",
    path: dashboard8qU9va8nWUMeta?.path ?? "dashboard",
    meta: dashboard8qU9va8nWUMeta || {},
    alias: dashboard8qU9va8nWUMeta?.alias || [],
    redirect: dashboard8qU9va8nWUMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/monitoring/dashboard.vue").then(m => m.default || m)
  },
  {
    name: equip_dashboardeCCKAhtnEUMeta?.name ?? "toc-monitoring-equip_dashboard",
    path: equip_dashboardeCCKAhtnEUMeta?.path ?? "equip_dashboard",
    meta: equip_dashboardeCCKAhtnEUMeta || {},
    alias: equip_dashboardeCCKAhtnEUMeta?.alias || [],
    redirect: equip_dashboardeCCKAhtnEUMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/monitoring/equip_dashboard.vue").then(m => m.default || m)
  },
  {
    name: newDashboardwbt1mhmm99Meta?.name ?? "toc-monitoring-newDashboard",
    path: newDashboardwbt1mhmm99Meta?.path ?? "newDashboard",
    meta: newDashboardwbt1mhmm99Meta || {},
    alias: newDashboardwbt1mhmm99Meta?.alias || [],
    redirect: newDashboardwbt1mhmm99Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/monitoring/newDashboard.vue").then(m => m.default || m)
  },
  {
    name: prod_45slideVm54n1zpzpMeta?.name ?? "toc-monitoring-prod-slide",
    path: prod_45slideVm54n1zpzpMeta?.path ?? "prod-slide",
    meta: prod_45slideVm54n1zpzpMeta || {},
    alias: prod_45slideVm54n1zpzpMeta?.alias || [],
    redirect: prod_45slideVm54n1zpzpMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/monitoring/prod-slide.vue").then(m => m.default || m)
  },
  {
    name: temp_newDashboardESC7nbcR7GMeta?.name ?? "toc-monitoring-temp_newDashboard",
    path: temp_newDashboardESC7nbcR7GMeta?.path ?? "temp_newDashboard",
    meta: temp_newDashboardESC7nbcR7GMeta || {},
    alias: temp_newDashboardESC7nbcR7GMeta?.alias || [],
    redirect: temp_newDashboardESC7nbcR7GMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/monitoring/temp_newDashboard.vue").then(m => m.default || m)
  }
],
    meta: monitoringRvOj8YlrpiMeta || {},
    alias: monitoringRvOj8YlrpiMeta?.alias || [],
    redirect: monitoringRvOj8YlrpiMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/monitoring.vue").then(m => m.default || m)
  },
  {
    name: not_45foundc4WeloivM0Meta?.name ?? "toc-not-found",
    path: not_45foundc4WeloivM0Meta?.path ?? "not-found",
    meta: not_45foundc4WeloivM0Meta || {},
    alias: not_45foundc4WeloivM0Meta?.alias || [],
    redirect: not_45foundc4WeloivM0Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/not-found.vue").then(m => m.default || m)
  },
  {
    name: operate5TVEL1hqeFMeta?.name ?? "toc-operate",
    path: operate5TVEL1hqeFMeta?.path ?? "operate",
    meta: operate5TVEL1hqeFMeta || {},
    alias: operate5TVEL1hqeFMeta?.alias || [],
    redirect: operate5TVEL1hqeFMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/operate.vue").then(m => m.default || m)
  },
  {
    name: reportSKTl32MCPMMeta?.name ?? "toc-report",
    path: reportSKTl32MCPMMeta?.path ?? "report",
    children: [
  {
    name: analyze_45efficientZKXUx9BSITMeta?.name ?? "toc-report-analyze-efficient",
    path: analyze_45efficientZKXUx9BSITMeta?.path ?? "analyze-efficient",
    children: [
  {
    name: ownersQtn4fGzx9cMeta?.name ?? "toc-report-analyze-efficient-owners",
    path: ownersQtn4fGzx9cMeta?.path ?? "owners",
    meta: ownersQtn4fGzx9cMeta || {},
    alias: ownersQtn4fGzx9cMeta?.alias || [],
    redirect: ownersQtn4fGzx9cMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/report/analyze-efficient/owners.vue").then(m => m.default || m)
  },
  {
    name: prjkindsRf9wHscSYNMeta?.name ?? "toc-report-analyze-efficient-prjkinds",
    path: prjkindsRf9wHscSYNMeta?.path ?? "prjkinds",
    meta: prjkindsRf9wHscSYNMeta || {},
    alias: prjkindsRf9wHscSYNMeta?.alias || [],
    redirect: prjkindsRf9wHscSYNMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/report/analyze-efficient/prjkinds.vue").then(m => m.default || m)
  },
  {
    name: regions9jioqnJfoQMeta?.name ?? "toc-report-analyze-efficient-regions",
    path: regions9jioqnJfoQMeta?.path ?? "regions",
    meta: regions9jioqnJfoQMeta || {},
    alias: regions9jioqnJfoQMeta?.alias || [],
    redirect: regions9jioqnJfoQMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/report/analyze-efficient/regions.vue").then(m => m.default || m)
  }
],
    meta: analyze_45efficientZKXUx9BSITMeta || {},
    alias: analyze_45efficientZKXUx9BSITMeta?.alias || [],
    redirect: analyze_45efficientZKXUx9BSITMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/report/analyze-efficient.vue").then(m => m.default || m)
  },
  {
    name: analyze_45productGlYk0iH5TfMeta?.name ?? "toc-report-analyze-product",
    path: analyze_45productGlYk0iH5TfMeta?.path ?? "analyze-product",
    children: [
  {
    name: ownersdMqylg684UMeta?.name ?? "toc-report-analyze-product-owners",
    path: ownersdMqylg684UMeta?.path ?? "owners",
    meta: ownersdMqylg684UMeta || {},
    alias: ownersdMqylg684UMeta?.alias || [],
    redirect: ownersdMqylg684UMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/report/analyze-product/owners.vue").then(m => m.default || m)
  },
  {
    name: prjkindszqkRhsHf5MMeta?.name ?? "toc-report-analyze-product-prjkinds",
    path: prjkindszqkRhsHf5MMeta?.path ?? "prjkinds",
    meta: prjkindszqkRhsHf5MMeta || {},
    alias: prjkindszqkRhsHf5MMeta?.alias || [],
    redirect: prjkindszqkRhsHf5MMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/report/analyze-product/prjkinds.vue").then(m => m.default || m)
  },
  {
    name: regions38Z1HgfGtHMeta?.name ?? "toc-report-analyze-product-regions",
    path: regions38Z1HgfGtHMeta?.path ?? "regions",
    meta: regions38Z1HgfGtHMeta || {},
    alias: regions38Z1HgfGtHMeta?.alias || [],
    redirect: regions38Z1HgfGtHMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/report/analyze-product/regions.vue").then(m => m.default || m)
  }
],
    meta: analyze_45productGlYk0iH5TfMeta || {},
    alias: analyze_45productGlYk0iH5TfMeta?.alias || [],
    redirect: analyze_45productGlYk0iH5TfMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/report/analyze-product.vue").then(m => m.default || m)
  },
  {
    name: env_45factorncrQQJccaNMeta?.name ?? "toc-report-env-factor",
    path: env_45factorncrQQJccaNMeta?.path ?? "env-factor",
    meta: env_45factorncrQQJccaNMeta || {},
    alias: env_45factorncrQQJccaNMeta?.alias || [],
    redirect: env_45factorncrQQJccaNMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/report/env-factor.vue").then(m => m.default || m)
  },
  {
    name: eventeBcdWV6M2GMeta?.name ?? "toc-report-event",
    path: eventeBcdWV6M2GMeta?.path ?? "event",
    meta: eventeBcdWV6M2GMeta || {},
    alias: eventeBcdWV6M2GMeta?.alias || [],
    redirect: eventeBcdWV6M2GMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/report/event.vue").then(m => m.default || m)
  },
  {
    name: predictfXjiFldz5rMeta?.name ?? "toc-report-predict",
    path: predictfXjiFldz5rMeta?.path ?? "predict",
    meta: predictfXjiFldz5rMeta || {},
    alias: predictfXjiFldz5rMeta?.alias || [],
    redirect: predictfXjiFldz5rMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/report/predict.vue").then(m => m.default || m)
  },
  {
    name: productGmwYtv4XDkMeta?.name ?? "toc-report-product",
    path: productGmwYtv4XDkMeta?.path ?? "product",
    meta: productGmwYtv4XDkMeta || {},
    alias: productGmwYtv4XDkMeta?.alias || [],
    redirect: productGmwYtv4XDkMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/report/product.vue").then(m => m.default || m)
  }
],
    meta: reportSKTl32MCPMMeta || {},
    alias: reportSKTl32MCPMMeta?.alias || [],
    redirect: reportSKTl32MCPMMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/report.vue").then(m => m.default || m)
  },
  {
    name: service_45infoWqCXN6Xn9fMeta?.name ?? "toc-service-info",
    path: service_45infoWqCXN6Xn9fMeta?.path ?? "service-info",
    children: [
  {
    name: app_45versionBYPM0LLN1NMeta?.name ?? "toc-service-info-app-version",
    path: app_45versionBYPM0LLN1NMeta?.path ?? "app-version",
    meta: app_45versionBYPM0LLN1NMeta || {},
    alias: app_45versionBYPM0LLN1NMeta?.alias || [],
    redirect: app_45versionBYPM0LLN1NMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/service-info/app-version.vue").then(m => m.default || m)
  }
],
    meta: service_45infoWqCXN6Xn9fMeta || {},
    alias: service_45infoWqCXN6Xn9fMeta?.alias || [],
    redirect: service_45infoWqCXN6Xn9fMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/service-info.vue").then(m => m.default || m)
  },
  {
    name: testqlfduMbTkrMeta?.name ?? "toc-test",
    path: testqlfduMbTkrMeta?.path ?? "test",
    meta: testqlfduMbTkrMeta || {},
    alias: testqlfduMbTkrMeta?.alias || [],
    redirect: testqlfduMbTkrMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/test.vue").then(m => m.default || m)
  },
  {
    name: user_45serviceCPeDBNDzffMeta?.name ?? "toc-user-service",
    path: user_45serviceCPeDBNDzffMeta?.path ?? "user-service",
    children: [
  {
    name: manager_45infoCRL7zn8AK0Meta?.name ?? "toc-user-service-manager-info",
    path: manager_45infoCRL7zn8AK0Meta?.path ?? "manager-info",
    meta: manager_45infoCRL7zn8AK0Meta || {},
    alias: manager_45infoCRL7zn8AK0Meta?.alias || [],
    redirect: manager_45infoCRL7zn8AK0Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/user-service/manager-info.vue").then(m => m.default || m)
  },
  {
    name: confirm_45modalz4ZtBBtY2yMeta?.name ?? "toc-user-service-modal-confirm-modal",
    path: confirm_45modalz4ZtBBtY2yMeta?.path ?? "modal/confirm-modal",
    meta: confirm_45modalz4ZtBBtY2yMeta || {},
    alias: confirm_45modalz4ZtBBtY2yMeta?.alias || [],
    redirect: confirm_45modalz4ZtBBtY2yMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/user-service/modal/confirm-modal.vue").then(m => m.default || m)
  },
  {
    name: manager_45add_45modalraYDYFBRGvMeta?.name ?? "toc-user-service-modal-manager-add-modal",
    path: manager_45add_45modalraYDYFBRGvMeta?.path ?? "modal/manager-add-modal",
    meta: manager_45add_45modalraYDYFBRGvMeta || {},
    alias: manager_45add_45modalraYDYFBRGvMeta?.alias || [],
    redirect: manager_45add_45modalraYDYFBRGvMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/user-service/modal/manager-add-modal.vue").then(m => m.default || m)
  },
  {
    name: manager_45modify_45modalP8PlVSRNTpMeta?.name ?? "toc-user-service-modal-manager-modify-modal",
    path: manager_45modify_45modalP8PlVSRNTpMeta?.path ?? "modal/manager-modify-modal",
    meta: manager_45modify_45modalP8PlVSRNTpMeta || {},
    alias: manager_45modify_45modalP8PlVSRNTpMeta?.alias || [],
    redirect: manager_45modify_45modalP8PlVSRNTpMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/user-service/modal/manager-modify-modal.vue").then(m => m.default || m)
  },
  {
    name: set_45manager_45all_45modalgB0d8nie55Meta?.name ?? "toc-user-service-modal-set-manager-all-modal",
    path: set_45manager_45all_45modalgB0d8nie55Meta?.path ?? "modal/set-manager-all-modal",
    meta: set_45manager_45all_45modalgB0d8nie55Meta || {},
    alias: set_45manager_45all_45modalgB0d8nie55Meta?.alias || [],
    redirect: set_45manager_45all_45modalgB0d8nie55Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/user-service/modal/set-manager-all-modal.vue").then(m => m.default || m)
  },
  {
    name: set_45manager_45select_45modalEE9qs7x821Meta?.name ?? "toc-user-service-modal-set-manager-select-modal",
    path: set_45manager_45select_45modalEE9qs7x821Meta?.path ?? "modal/set-manager-select-modal",
    meta: set_45manager_45select_45modalEE9qs7x821Meta || {},
    alias: set_45manager_45select_45modalEE9qs7x821Meta?.alias || [],
    redirect: set_45manager_45select_45modalEE9qs7x821Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/user-service/modal/set-manager-select-modal.vue").then(m => m.default || m)
  },
  {
    name: set_45managerSyXQ8eDt2CMeta?.name ?? "toc-user-service-set-manager",
    path: set_45managerSyXQ8eDt2CMeta?.path ?? "set-manager",
    meta: set_45managerSyXQ8eDt2CMeta || {},
    alias: set_45managerSyXQ8eDt2CMeta?.alias || [],
    redirect: set_45managerSyXQ8eDt2CMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/user-service/set-manager.vue").then(m => m.default || m)
  },
  {
    name: user_45infood7Jm3dji4Meta?.name ?? "toc-user-service-user-info",
    path: user_45infood7Jm3dji4Meta?.path ?? "user-info",
    meta: user_45infood7Jm3dji4Meta || {},
    alias: user_45infood7Jm3dji4Meta?.alias || [],
    redirect: user_45infood7Jm3dji4Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/user-service/user-info.vue").then(m => m.default || m)
  },
  {
    name: user_45settingK3jtBCW6SIMeta?.name ?? "toc-user-service-user-setting",
    path: user_45settingK3jtBCW6SIMeta?.path ?? "user-setting",
    meta: user_45settingK3jtBCW6SIMeta || {},
    alias: user_45settingK3jtBCW6SIMeta?.alias || [],
    redirect: user_45settingK3jtBCW6SIMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/user-service/user-setting.vue").then(m => m.default || m)
  }
],
    meta: user_45serviceCPeDBNDzffMeta || {},
    alias: user_45serviceCPeDBNDzffMeta?.alias || [],
    redirect: user_45serviceCPeDBNDzffMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/user-service.vue").then(m => m.default || m)
  },
  {
    name: find_45idMr42Dm1TNPMeta?.name ?? "toc-user-find-id",
    path: find_45idMr42Dm1TNPMeta?.path ?? "user/find-id",
    meta: find_45idMr42Dm1TNPMeta || {},
    alias: find_45idMr42Dm1TNPMeta?.alias || [],
    redirect: find_45idMr42Dm1TNPMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/user/find-id.vue").then(m => m.default || m)
  },
  {
    name: indexvrT6WCkp2oMeta?.name ?? "toc-user",
    path: indexvrT6WCkp2oMeta?.path ?? "user",
    meta: indexvrT6WCkp2oMeta || {},
    alias: indexvrT6WCkp2oMeta?.alias || [],
    redirect: indexvrT6WCkp2oMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/user/index.vue").then(m => m.default || m)
  },
  {
    name: loginCieuFY7gJ0Meta?.name ?? "toc-user-login",
    path: loginCieuFY7gJ0Meta?.path ?? "user/login",
    meta: loginCieuFY7gJ0Meta || {},
    alias: loginCieuFY7gJ0Meta?.alias || [],
    redirect: loginCieuFY7gJ0Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc/user/login.vue").then(m => m.default || m)
  }
],
    meta: tocqXcM9mTbhxMeta || {},
    alias: tocqXcM9mTbhxMeta?.alias || [],
    redirect: tocqXcM9mTbhxMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems-dev/kie-rems-mweb/src/client/pages/toc.vue").then(m => m.default || m)
  }
]